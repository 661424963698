import React from 'react';
import PropTypes from 'prop-types';
import {Helmet} from 'react-helmet-async';

const HelmetWrapper = ({component: Component, title, description}) =>
  <>
    <Helmet prioritizeSeoTags>
      <link
        href={window.location.href}
        rel='Canonical'/>
      <title>{title}</title>
      <meta
        content={description}
        name='description'/>
      <meta
        content={title}
        name='og:title'/>
      <meta
        content={description}
        name='og:description'/>
    </Helmet>
    <Component/>
  </>;

export default HelmetWrapper;

HelmetWrapper.propTypes = {
  component: PropTypes.any.isRequired,
  description: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
};

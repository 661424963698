import React, {useEffect, useRef, useState} from 'react';
import {useReactMediaRecorder} from 'react-media-recorder';
import {useNavigate} from 'react-router';
import {useDispatch, useSelector} from 'react-redux';
import {toast} from 'react-toastify';
import ROUTES from '../../../../routes';
import content from './content.json';
import {
  updateChildSelectedActivityStatus,
  completeChildSelectedActivity,
  uploadChildRecording
} from '../slice/templetonSlice';
import {useLocation} from 'react-router-dom';
import Player from '@vimeo/player';
import {getVideoId, prettyPrintJson} from '../../../common/utils';
import useErrorProvider from '../../../common/useErrorProvider';
import logger from 'loglevel';
import {useRollbar} from '@rollbar/react';

const currentActivityProgressSelector = state => state.templetonStudy.currentActivityProgress;
const currentChildSelector = state => state.study.currentChild;

const INACTIVITY_COUNTDOWN_IN_MS = 30000;
const FORCED_EXIT_COUNTDOWN_IN_MS = 60000;
const INACTIVITY_WARNING_DURATION_IN_MS = FORCED_EXIT_COUNTDOWN_IN_MS;
const BYTES_IN_MEGABYTE = 1000000;

const createVimeoPlayer = initialUrl => new Player('vimeo-player', {
  autopip: false,
  id: getVideoId(initialUrl),
  interactive_markers: false,
  pip: false,
  quality: '240p',
  transcript: false,
  width: 640
});

export const Activity = () => {
  const playerRef = useRef(null);

  // third party library hooks
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const rollbar = useRollbar();

  // timers
  const inactivityWarningTimerRef = useRef(null);
  const forcedExitTimerRef = useRef(null);

  const warningToastId = useRef(null);

  const [continueButtonDisabled, setContinueButtonDisabled] = useState(true);
  const activityProgress = useSelector(currentActivityProgressSelector);
  const child = useSelector(currentChildSelector);
  const [errorMsg, setErrorMsg] = useState('');
  const playerDisplay = errorMsg ? 'none' : 'block';
  const {apiErrorMsg, clearApiError, handleApiError} = useErrorProvider();

  useEffect(() => {
    if (apiErrorMsg) {
      toast.error(apiErrorMsg);
      clearApiError();
    }
  }, [apiErrorMsg]);

  const [currentVideo, setCurrentVideo] = useState(location.state.nextSegmentPage);
  const isLastVideo = currentVideo === content.videos.length - 1;
  const [pageStartAt, setPageStartAt] = useState('');

  const clearAllTimers = () => {
    clearTimeout(forcedExitTimerRef.current);
    clearTimeout(inactivityWarningTimerRef.current);
    logger.debug('Cleared all active timers');
  };

  const dismissInactivityToast = () => {
    if (warningToastId.current) {
      toast.dismiss(warningToastId.current);
      logger.debug('Dismissed the inactivity warning toast message');
    }
  };

  const handleStartRecording = () => {
    logger.info('Video / audio recording has started');
  };

  const handleStopRecording = (blobUrl, blob) => {
    logger.info('Video / audio recording has stopped');
    logger.debug('The recording blob size is', blob.size / BYTES_IN_MEGABYTE, 'MB');
    const uploadStartedAt = Date.now();
    const videoFile = new File([blob], 'video.mp4', {type: 'video/mp4'});
    const formData = new FormData();
    formData.append('recording', videoFile);
    formData.append('child_id', child.id);

    if (blob.size > 0) {
      logger.info('Uploading the recording to the cloud');
      const toastId = toast.loading('Uploading recording...', {position: toast.POSITION.BOTTOM_RIGHT});

      dispatch(uploadChildRecording({
        activityProgressId: activityProgress.id,
        formData: formData
      }))
        .then(action => {
          if (uploadChildRecording.fulfilled.match(action)) {
            const uploadCompletedAt = Date.now();
            const timeTaken = uploadCompletedAt - uploadStartedAt;
            logger.info('Uploaded', blob.size / BYTES_IN_MEGABYTE, 'MB to the cloud in', timeTaken / 1000, 'seconds');
            toast.update(toastId, {render: 'Recording uploaded!', type: 'success', isLoading: false, autoClose: 500});
          }
          else {
            toast.update(toastId, {render: 'Upload failed!', type: 'error', isLoading: false, autoClose: 500});
            handleApiError({action, message: 'Failed to upload puppet show recording.'});
          }
        }).catch(error => {
          toast.update(toastId, {render: 'Upload failed!', type: 'error', isLoading: false, autoClose: 500});
          handleApiError({error, message: 'Error when uploading a Templeton puppet show recording.'});
        });
    }
    else {
      rollbar.error('Recording completed with a blob size of 0');
      logger.error('Recording completed with a blob size of 0');
    }
  };

  const {status, startRecording, stopRecording, pauseRecording, resumeRecording} = useReactMediaRecorder({
    video: true,
    askPermissionOnMount: true,
    blobPropertyBag: {type: 'video/mp4'},
    onStart: handleStartRecording,
    onStop: handleStopRecording
  });

  const handleContinue = () => {
    dismissInactivityToast();
    clearAllTimers();
    stopRecording();

    const activityPageDetails = {
      activity_array_idx: currentVideo,
      page: currentVideo,
      question: '',
      answer: '',
      expected_answer: '',
      page_started_at: pageStartAt,
      page_completed_at: Date()
    };

    const tempProgressArr = activityProgress.progress;

    const data = {
      childId: child.id,
      activityProgressId: activityProgress.id,
      progress: [...tempProgressArr, activityPageDetails]
    };

    dispatch(updateChildSelectedActivityStatus(data))
      .then(action => {
        if (updateChildSelectedActivityStatus.fulfilled.match(action)) {
          logger.info('Uploaded completed activity to the cloud');

          if (isLastVideo) {
            logger.info('Uploading completed activity to the cloud');
            dispatch(completeChildSelectedActivity({
              childId: child.id, activityProgressId: activityProgress.id, completedAt: Date()
            })).then(action2 => {
              if (completeChildSelectedActivity.fulfilled.match(action2)) {
                logger.info('Uploaded completed activity to the cloud');
                navigate(ROUTES.TEMPLETON_STUDY_PUPPETSHOW_CONGRATULATIONS);
              }
              else {
                handleApiError({action: action2, message: 'Unable to complete puppet show activity.'});
              }
            }).catch(error => {
              handleApiError({error, message: 'Error when completing the Templeton puppet show activity.'});
            });
          }
          else {
            setCurrentVideo(currentVideo + 1);
          }
        }
        else {
          handleApiError({action, message: 'Unable to update child activity.'});
        }
      }).catch(error => {
        handleApiError({error, message: 'Error when updating child activities for the Templeton puppet show.'});
      });
  };

  const handleForcedExit = () => {
    handleContinue();
    navigate(ROUTES.ROOT);
  };

  const startForcedExitTimer = () => {
    logger.debug('Starting the forced exit countdown of', FORCED_EXIT_COUNTDOWN_IN_MS / 1000, 'seconds');
    forcedExitTimerRef.current = setTimeout(handleForcedExit, FORCED_EXIT_COUNTDOWN_IN_MS);
  };

  const handleInactivityWarningTimerElapsed = () => {
    warningToastId.current = toast.warning('Are you still watching the Puppet show? Please click continue to move on.',
      {autoClose: INACTIVITY_WARNING_DURATION_IN_MS});
    startForcedExitTimer();
  };

  const handleVideoEnded = () => {
    setContinueButtonDisabled(false);
    logger.debug('Starting the inactivity warning countdown of', INACTIVITY_COUNTDOWN_IN_MS / 1000, 'seconds');
    inactivityWarningTimerRef.current = setTimeout(handleInactivityWarningTimerElapsed, INACTIVITY_COUNTDOWN_IN_MS);
  };

  const addPlayerEventHandlers = () => {
    playerRef.current.on('play', () => {
      logger.debug('Video "play" event handler fired');

      if (status !== 'recording') {
        startRecording();
      }
    });

    playerRef.current.on('ended', () => {
      logger.debug('Video "ended" event handler fired');
      handleVideoEnded();
    });

    logger.debug('Assigned event handlers to the video player instance');
  };

  const removePlayerEventHandlers = () => {
    playerRef.current.off('ended');
    logger.debug('Removed event handlers from the video player instance');
  };

  useEffect(() => {
    logger.debug('Component is mounted, performing initialization tasks');

    if (!playerRef.current) {
      playerRef.current = createVimeoPlayer(content.videos[currentVideo].video_url);
      logger.debug('Created a new video player instance');

      addPlayerEventHandlers();
    }

    return () => {
      logger.debug('Component is unmounting, performing cleanup tasks. If you are seeing this message immediately ' +
          'after mounting the component, this means you are running in React Strict Mode. This is expected behaviour.');

      dismissInactivityToast();
      clearAllTimers();

      if (playerRef.current) {
        removePlayerEventHandlers();
      }

      playerRef.current = null;
      logger.debug('Discarded the video player instance');
    };
  }, []);

  const handleExit = () => {
    navigate(ROUTES.TEMPLETON_STUDY_ACTIVITIES);
  };

  const handleToggleRecording = () => {
    if (status === 'recording') {
      resumeRecording();
      toast.success('Recording resumed.', {autoClose: 1000});
    }
    else {
      pauseRecording();
      toast.success('Recording paused.', {autoClose: 1000});
    }
  };

  const handleNextVideo = () => {
    playerRef.current.loadVideo(getVideoId(content.videos[currentVideo].video_url)).then(() => {
      playerRef.current.play()
        .then(() => {
          setPageStartAt(Date());
          startRecording();
        })
        .catch(error => {
          toast.warn('Unable to automatically play the video. Please play it manually.', {autoClose: 10000});
          logger.error('Unable to automatically play the video:', error.message);
        });
    }).catch(error => {
      setErrorMsg('This video could not be loaded either because it is restricted or does not exist.');
      logger.error(error.message, 'Unable to load the video at', content.videos[currentVideo].video_url,
        content.videos[currentVideo]);
      rollbar.error(error, `Unable to load the video at: ${content.videos[currentVideo].video_url}`,
        content.videos[currentVideo]);
    });
  };

  useEffect(() => {
    logger.info(`Transitioned to video id: ${content.videos[currentVideo].id}`);
    handleNextVideo();
  }, [currentVideo]);

  return (
    <div className='grid-x align-center'>
      <div className='small-12 medium-10 large-6 align-center'>
        <div className='puppet-show-container'>
          {errorMsg && <div className='alert callout'>
            <p><b>{errorMsg}</b></p>
            <p><b>Please provide the research team with the following technical information:</b></p>
            <pre>{prettyPrintJson(content.videos[currentVideo])}</pre>
          </div>}
          {!errorMsg && <button
            className='puppet-show-pause button primary'
            onClick={handleToggleRecording}
            type='button'>{status === 'recording' ? 'Pause' : 'Play'}</button>}
          <div className='height-50 width-100'>
            <div
              id='vimeo-player'
              style={{display: playerDisplay}}/>
          </div>
        </div>
        {!errorMsg && <div className='grid-x align-center'>
          <div className='padding-2'>
            <button
              className='button primary'
              disabled={continueButtonDisabled}
              onClick={handleContinue}
              type='button'>
              Continue
            </button>
          </div>
        </div>}
        <div className='grid-x align-center'>
          <button
            className='button primary hollow'
            onClick={handleExit}
            type='button'>
            Exit
          </button>
        </div>
      </div>
    </div>
  );
};

/* eslint-disable max-len */
import React, {useEffect} from 'react';
import ROUTES from '../../../../routes';
import {useNavigate} from 'react-router';
import {Link} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {QUALTRICS_LINKS} from '../../../constants';

const currentChildSelector = state => state.study.currentChild;
const currentStudySelector = state => state.study.currentStudy;
const currentActivitySelector = state => state.templetonStudy.currentActivity;

export const Survey = () => {
  const navigate = useNavigate();
  const child = useSelector(currentChildSelector);
  const study = useSelector(currentStudySelector);
  const currentActivity = useSelector(currentActivitySelector);

  useEffect(() => {
    window.open(`${QUALTRICS_LINKS.TEMPLETON}?study_id=${study.id}&activity_id=${currentActivity.id}&participant_id=${child.id}`, '_blank');
  }, []);

  return (
    <div className='grid-x align-center'>
      <div className='small-12 medium-10 large-6 align-center'>
        <div className='padding-vertical-1 padding-horizontal-2'>
          <h1>Thanks!</h1>
          <Link
            className='text-underline'
            onClick={() => navigate(ROUTES.TEMPLETON_STUDY_ACTIVITIES)}
            target='_blank'
            to='/'>
            Complete Activity
          </Link>
        </div>
      </div>
    </div>
  );
};

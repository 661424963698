import React, {useState, useEffect} from 'react';
import {useNavigate} from 'react-router';
import ROUTES from '../../../../routes';
import {useDispatch, useSelector} from 'react-redux';
import {createActivityProgress, getChildSelectedActivityStatus} from '../slice/templetonSlice';
import {toast} from 'react-toastify';
import {isEmpty} from 'lodash';
import book1 from './storyBook1/storyBook1.json';
import book2 from './storyBook2/storyBook2.json';
import useErrorProvider from '../../../common/useErrorProvider';

const currentChildSelector = state => state.study.currentChild;
const currentActivitySelector = state => state.templetonStudy.currentActivity;

export const ActivityInfo = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const child = useSelector(currentChildSelector);
  const activity = useSelector(currentActivitySelector);
  const [activityProgress, setActivityProgress] = useState([]);
  const [loading, setLoading] = useState(false);
  const content = activity.slug === 'story_book_1' ? book1 : book2;
  const {apiErrorMsg, clearApiError, handleApiError} = useErrorProvider();

  useEffect(() => {
    if (apiErrorMsg) {
      toast.error(apiErrorMsg);
      clearApiError();
    }
  }, [apiErrorMsg]);

  useEffect(() => {
    setLoading(true);
    dispatch(getChildSelectedActivityStatus({childId: child.id, activityId: activity.id}))
      .then(action => {
        if (getChildSelectedActivityStatus.fulfilled.match(action)) {
          setActivityProgress(action.payload.details[0]);
        }
        else {
          handleApiError({action, message: 'Unable to get child activity status.'});
        }
      }).catch(error => {
        handleApiError({error, message: 'Error when fetching child activity status in templeton book info.'});
      }).finally(() => {
        setLoading(false);
      });
  }, []);

  const onActivityStart = () => {
    setLoading(true);
    dispatch(createActivityProgress({childId: child.id, activityId: activity.id, startedAt: Date()}))
      .then(action => {
        if (createActivityProgress.fulfilled.match(action)) {
          navigate(ROUTES.TEMPLETON_STUDY_STORYBOOK_1, {
            state: {nextSegmentPage: 0}
          });
        }
        else {
          handleApiError({action, message: 'Unable to start a new story book activity.'});
        }
      }).catch(error => {
        handleApiError({error, message: 'Error when saving activity progress for the Templeton story book.'});
      }).finally(() => {
        setLoading(false);
      });
  };

  const onActivityContinue = () => {
    if (isEmpty(activityProgress.progress)) {
      navigate(ROUTES.TEMPLETON_STUDY_STORYBOOK_1, {
        state: {nextSegmentPage: 0}
      });
      return;
    }

    const activityStoppedOnPage = activityProgress.progress[activityProgress.progress.length - 1].page;

    for (let i = activityStoppedOnPage; i < content.pages[content.pages.length - 1].id; i++) {
      if (content.pages[i].allowPause) {
        navigate(ROUTES.TEMPLETON_STUDY_STORYBOOK_1, {
          state: {nextSegmentPage: i}
        });
        break;
      }
    }
  };

  const onBack = () => {
    navigate(ROUTES.TEMPLETON_STUDY_ACTIVITIES);
  };

  return (
    <div className='grid-x align-center'>
      <div className='small-12 medium-10 large-6'>
        <div className='padding-vertical-1 padding-horizontal-2'>
          <button
            className='button primary small hollow'
            onClick={() => onBack()}
            type='button'
          >
            Back
          </button>
          <h1>Storybook Tutorial</h1>
          <p className='body-2'>
            We are about to start the storybook activity. Please be sure to find a quiet place to do this activity
            where you are not likely to be disturbed. Remember that your interactions during the study will recorded,
            so please be cognizant of your surroundings and ensure that you are appropriately dressed for
            the activity.
          </p>
        </div>
        <div className='sticky-consent bg-mid-beige'>
          <div className='width-100 padding-2 consent-button'>
            <div className='grid-x align-center'>
              <h1 className='margin-bottom-2'>Ready to go?</h1>
              {loading && <div className='loader-wrapper'><div className='loader'/></div>}
              {!loading &&
                <>
                  {isEmpty(activityProgress)
                    ? <button
                      className='button primary expanded margin-0'
                      onClick={() => onActivityStart()}
                      type='button'
                    >
                      Ok, let&apos;s start!
                    </button>
                    : <button
                      className='button primary expanded margin-0'
                      onClick={() => onActivityContinue()}
                      type='button'
                    >
                      Continue
                    </button>
                  }
                </>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

import React, {useEffect, useRef, useState} from 'react';
import {RecorderErrors, useReactMediaRecorder} from 'react-media-recorder';
import {useNavigate} from 'react-router';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';
import {toast} from 'react-toastify';
import ROUTES from '../../../../routes';
import STUDY_JSON from './content.json';
import {SSHRC_IMAGES} from '../../../../assets/images';
import {updateChildSelectedActivityStatus, completeChildSelectedActivity, saveCurrPageID} from '../slice/sshrcSlice';
import {uploadChildRecording} from '../../templeton/slice/templetonSlice';
import {prettyPrintJson} from '../../../common/utils';
import useErrorProvider from '../../../common/useErrorProvider';
import {useRollbar} from '@rollbar/react';
import logger from 'loglevel';
import 'plyr/src/sass/plyr.scss';
import Plyr from 'plyr';

const currentActivityProgressSelector = state => state.sshrcStudy.currentActivityProgress;
const currentChildSelector = state => state.study.currentChild;
const currentPageIdSelector = state => state.sshrcStudy.currentPageId;

const INACTIVITY_COUNTDOWN_IN_MS = 30000;
const FORCED_EXIT_COUNTDOWN_IN_MS = 60000;
const INACTIVITY_WARNING_DURATION_IN_MS = FORCED_EXIT_COUNTDOWN_IN_MS;
const BUTTONS_ENABLE_LONG_TIMER_IN_MS = 4000;
const BUTTONS_ENABLE_SHORT_TIMER_IN_MS = 1000;
const BYTES_IN_MEGABYTE = 1000000;
const PAGE_STATUS = {
  ABANDONED: 'page_abandoned_at',
  COMPLETED: 'page_completed_at',
  UNLOADED: 'page_unloaded_at'
};

export const Activity = () => {
  const playerRef = useRef(null);
  const startTimestampRef = useRef(null);

  // third party library hooks
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const rollbar = useRollbar();

  // timers
  const buttonsEnableTimerRef = useRef(null);
  const inactivityWarningTimerRef = useRef(null);
  const forcedExitTimerRef = useRef(null);

  const warningToastId = useRef(null);

  const [errorMsg, setErrorMsg] = useState('');

  const [currPageId, setCurrPageId] = useState(useSelector(currentPageIdSelector) || location.state.nextSegmentPage);
  const currPage = STUDY_JSON.pages[currPageId];

  const activityProgress = useSelector(currentActivityProgressSelector);
  const child = useSelector(currentChildSelector);

  const buttonsEnableTimerDuration = currPage.videoHasNoAudio
    ? BUTTONS_ENABLE_LONG_TIMER_IN_MS : BUTTONS_ENABLE_SHORT_TIMER_IN_MS;
  const isLastPage = currPageId === STUDY_JSON.pages.length - 1;
  const autoGoTo = currPage.autoGoTo;
  const currPageIsInstruction = currPage.isSampleAnswer;
  const [buttonsEnabled, setButtonsEnabled] = useState(currPageIsInstruction && currPage.buttonsEnabledDuringPlayback);
  const buttonsHorizontalAlignment = currPage.optionButtons.length > 1 ? 'align-spaced' : 'align-right';
  const buttonsMaxHeight = ['100px', '200px', '150px'][currPage.optionButtons.length - 1];

  const {apiErrorMsg, clearApiError, handleApiError} = useErrorProvider();

  const apiUploadActivityStatus = data => {
    logger.info('Uploading activity status to the cloud');
    dispatch(updateChildSelectedActivityStatus(data)).then(action => {
      if (updateChildSelectedActivityStatus.fulfilled.match(action)) {
        logger.info('Uploaded activity status to the cloud');
      }
      else {
        handleApiError({action, message: 'Unable to update child activity.'});
      }
    }).catch(apiError => {
      handleApiError({error: apiError, message: 'Error when updating child activities for the SSHRC study.'});
    });
  };

  const apiCompleteChildSelectedActivity = data => {
    logger.info('Uploading completed activity to the cloud');
    dispatch(completeChildSelectedActivity(data)).then(action => {
      if (completeChildSelectedActivity.fulfilled.match(action)) {
        logger.info('Uploaded completed activity to the cloud');
      }
      else {
        handleApiError({action, message: 'Unable to complete activity.'});
      }
    }).catch(apiError => {
      handleApiError({error: apiError, message: 'Error when completing the SSHRC study activity.'});
    });
  };

  const apiUploadChildRecording = (data, blobSize) => {
    logger.info('Uploading the recording to the cloud');
    const uploadStartedAt = Date.now();
    const toastId = toast.loading('Uploading recording...', {position: toast.POSITION.BOTTOM_RIGHT});
    dispatch(uploadChildRecording(data))
      .then(action => {
        if (uploadChildRecording.fulfilled.match(action)) {
          const uploadCompletedAt = Date.now();
          const timeTaken = uploadCompletedAt - uploadStartedAt;
          logger.info('Uploaded', blobSize / BYTES_IN_MEGABYTE, 'MB to the cloud in', timeTaken / 1000, 'seconds');
          toast.update(toastId, {render: 'Recording uploaded!', type: 'success', isLoading: false, autoClose: 500});
        }
        else {
          toast.update(toastId, {render: 'Upload failed!', type: 'error', isLoading: false, autoClose: 500});
          handleApiError({action, message: 'Failed to upload recording.'});
        }
      }).catch(apiError => {
        toast.update(toastId, {render: 'Upload failed!', type: 'error', isLoading: false, autoClose: 500});
        handleApiError({error: apiError, message: 'Error when uploading a SSHRC activity recording.'});
      });
  };

  const getDataForUpload = (task_name, title, answer, pageStatus) => {
    const activityPageDetails = {
      activity_array_idx: currPageId,
      page: currPageId,
      task: task_name,
      question: title,
      answer: answer,
      page_started_at: startTimestampRef.current,
      page_abandoned_at: null,
      page_completed_at: null,
      page_unloaded_at: null
    };

    switch (pageStatus) {
    case PAGE_STATUS.COMPLETED:
      activityPageDetails.page_completed_at = Date(); break;
    case PAGE_STATUS.UNLOADED:
      activityPageDetails.page_unloaded_at = Date(); break;
    case PAGE_STATUS.ABANDONED:
      activityPageDetails.page_abandoned_at = Date(); break;
    default:
      break;
    }

    const tempProgressArr = activityProgress.progress;

    return {
      childId: child.id,
      activityProgressId: activityProgress.id,
      progress: [...tempProgressArr, activityPageDetails]
    };
  };

  const handleForcedExit = () => {
    const data = getDataForUpload(currPage.task, currPage.title, 'n/a', PAGE_STATUS.ABANDONED);
    apiUploadActivityStatus(data);
    navigate(ROUTES.ROOT);
  };

  const startForcedExitTimer = () => {
    if (!forcedExitTimerRef.current) {
      logger.debug('Starting the forced exit countdown of', FORCED_EXIT_COUNTDOWN_IN_MS / 1000, 'seconds');
      forcedExitTimerRef.current = setTimeout(() => {
        forcedExitTimerRef.current = null;
        handleForcedExit();
      }, FORCED_EXIT_COUNTDOWN_IN_MS);
    }
  };

  const startButtonsEnableTimer = () => {
    if (!buttonsEnableTimerRef.current) {
      logger.debug('Initiating the countdown of', buttonsEnableTimerDuration / 1000, 'seconds before enabling buttons');
      buttonsEnableTimerRef.current = setTimeout(() => {
        buttonsEnableTimerRef.current = null;
        setButtonsEnabled(true);
      }, buttonsEnableTimerDuration);
    }
  };

  const handleInactivityWarningTimerElapsed = () => {
    if (!warningToastId.current) {
      warningToastId.current = toast.warning('Are you still here? Please click button to continue.',
        {autoClose: INACTIVITY_WARNING_DURATION_IN_MS});
    }

    startForcedExitTimer();
  };

  const startInactivityWarningTimer = () => {
    if (!inactivityWarningTimerRef.current) {
      logger.debug('Starting the inactivity warning countdown of', INACTIVITY_COUNTDOWN_IN_MS / 1000, 'seconds');
      inactivityWarningTimerRef.current = setTimeout(() => {
        inactivityWarningTimerRef.current = null;
        handleInactivityWarningTimerElapsed();
      }, INACTIVITY_COUNTDOWN_IN_MS);
    }
  };

  const clearAllTimers = () => {
    clearTimeout(forcedExitTimerRef.current);
    clearTimeout(inactivityWarningTimerRef.current);
    clearTimeout(buttonsEnableTimerRef.current);
    forcedExitTimerRef.current = null;
    inactivityWarningTimerRef.current = null;
    buttonsEnableTimerRef.current = null;
    logger.debug('Cleared all active timers');
  };

  const dismissInactivityToast = () => {
    if (warningToastId.current) {
      toast.dismiss(warningToastId.current);
      warningToastId.current = null;
      logger.debug('Dismissed the inactivity warning toast message');
    }
  };

  const handleOnPlay = () => {
    logger.debug('Video "play" event handler fired');

    if (!currPage.videoHasNoAudio) {
      dismissInactivityToast();
    }

    if (!buttonsEnabled && currPage.buttonsEnabledDuringPlayback) {
      startButtonsEnableTimer();
    }
  };

  const beginPlayingCurrPageVideo = () => {
    if (currPage && currPage.videoUrl) {
      playerRef.current.play()
        .catch(playerError => {
          if (!warningToastId.current) {
            warningToastId.current = toast.warn('Unable to automatically play the video. Please play it manually.',
              {autoClose: 10000});
          }

          logger.error('Unable to automatically play the video:', playerError.message);
        });
    }
  };

  const handleStartRecording = () => {
    logger.info('Video / audio recording has started');
  };

  const handleStopRecording = (blobUrl, blob) => {
    logger.info('Video / audio recording has stopped');
    logger.debug('The recording blob size is', blob.size / BYTES_IN_MEGABYTE, 'MB');
    const videoFile = new File([blob], 'video.mp4', {type: 'video/mp4'});
    const formData = new FormData();
    formData.append('recording', videoFile);
    formData.append('child_id', child.id);

    if (blob.size > 0) {
      apiUploadChildRecording({activityProgressId: activityProgress.id, formData: formData}, blob.size);
    }
    else {
      rollbar.error(errorMsg, 'Recording completed with a blob size of 0', {currPage, blob});
      logger.error('Recording completed with a blob size of 0');
    }
  };

  const {status, error, startRecording, stopRecording} = useReactMediaRecorder({
    video: true, askPermissionOnMount: true, blobPropertyBag: {type: 'video/mp4'},
    onStart: handleStartRecording, onStop: handleStopRecording,
    /*
     * We only record audio on videos that have no audio. Trying to record audio while playing audio automatically
     * decreases the volume on iOS devices to a very low level and does not restore it after the recording is stopped.
     * During most videos, only the video is needed, simply to check that the recording was being done by a child.
     * Audio is only needed on videos that have no audio, so that the child can record their own voice.
     */
    audio: currPage.videoHasNoAudio
  });

  const handleComplete = () => {
    logger.debug('Concluding the study after reaching the last page');
    const data = {childId: child.id, activityProgressId: activityProgress.id, completedAt: Date()};
    apiCompleteChildSelectedActivity(data);
    navigate(ROUTES.SSHRC_STUDY_CONGRATULATION);
  };

  const handlePageTransition = nextPageId => {
    if (isLastPage) {
      logger.debug('This is the last page so I am going to conclude this study');
      handleComplete();
    }
    else if (nextPageId) {
      setCurrPageId(nextPageId);
    }
  };

  const handleContinue = (taskName, pageTitle, taskAnswer, nextPageId) => {
    if (nextPageId || isLastPage) {
      const data = getDataForUpload(taskName, pageTitle, taskAnswer, PAGE_STATUS.COMPLETED);

      if (status === 'recording' && error === RecorderErrors.NONE) {
        stopRecording();
      }

      clearAllTimers();
      dismissInactivityToast();
      apiUploadActivityStatus(data);
      setButtonsEnabled(false);
    }

    handlePageTransition(nextPageId);
  };

  const handleVideoEnded = () => {
    if (!autoGoTo) {
      setButtonsEnabled(true);
    }

    if (autoGoTo === null) {
      startInactivityWarningTimer();
    }
    else {
      handleContinue(currPage.task,
        currPage.title,
        'n/a',
        autoGoTo);
    }
  };

  const handleOnEnded = () => {
    logger.debug('Video "ended" event handler fired');

    if (!currPage.videoHasNoAudio) {
      handleVideoEnded();
    }
  };

  const handleBeforeUnload = () => {
    const data = getDataForUpload(currPage.task, currPage.title, 'n/a', PAGE_STATUS.UNLOADED);
    logger.info('Page is unloading, so performing cleanup tasks');
    apiUploadActivityStatus(data);
  };

  const addWindowEventHandlers = () => {
    window.addEventListener('beforeunload', handleBeforeUnload);
    logger.debug('Added an event handler for the "beforeunload" event');
  };

  const removeWindowEventHandlers = () => {
    window.removeEventListener('beforeunload', handleBeforeUnload);
    logger.debug('Removed the event handler for the "beforeunload" event');
  };

  useEffect(() => {
    /*
     * Event handlers always use the same snapshot of React state at the time of assignment
     * which means none of the state changes will be reflected in the event handlers;
     * however, by removing and re-adding the event handlers on each re-render,
     * we can ensure that the event handlers always have the latest snapshot of React state.
     */
    removeWindowEventHandlers();
    addWindowEventHandlers();
  }, [currPageId]);

  useEffect(() => {
    logger.debug('Component is mounted, performing initialization tasks');

    if (!playerRef.current) {
      playerRef.current = new Plyr('#player', {
        controls: ['play-large'],
        fullscreen: {enabled: false, fallback: false, iosNative: false}
      });
      logger.debug('Created a new video player instance');
    }

    return () => {
      logger.debug('Component is unmounting, performing cleanup tasks. If you are seeing this message immediately ' +
          'after mounting the component, this means you are running in React Strict Mode. This is expected behaviour.');

      removeWindowEventHandlers();
      dismissInactivityToast();
      clearAllTimers();
    };
  }, []);

  useEffect(() => {
    if (apiErrorMsg) {
      logger.error('Encountered an API error:', apiErrorMsg);
      toast.error(apiErrorMsg);
      clearApiError();
    }
  }, [apiErrorMsg]);

  useEffect(() => {
    switch (error) {
    case RecorderErrors.AbortError:
      setErrorMsg('Video / audio recording aborted. Please try again.');
      break;
    case RecorderErrors.NotAllowedError:
      setErrorMsg('Video / audio recording not possible. Please allow access to your microphone and camera.');
      break;
    case RecorderErrors.NotFoundError:
    case RecorderErrors.NO_RECORDER:
      setErrorMsg('Unable to find a video / audio recorder. Please ensure you are using a modern browser.');
      break;
    case RecorderErrors.NotReadableError:
      setErrorMsg('Unable to read the video / audio stream. Please ensure you are using a modern browser.');
      break;
    case RecorderErrors.OverconstrainedError:
    case RecorderErrors.TypeError:
      setErrorMsg('Unable to record video / audio. Please ensure you are using a modern browser. ' +
        'Video / audio recording may not work over http.');
      break;
    default:
    }

    if (error !== RecorderErrors.NONE) {
      clearAllTimers();
      dismissInactivityToast();
      playerRef.current.pause();
    }
  }, [error]);

  useEffect(() => {
    logger.info(`Transitioned to page: ${STUDY_JSON.pages[currPageId].title} (id: ${currPageId})`);
    dispatch(saveCurrPageID(currPageId));

    if (error === RecorderErrors.NONE) {
      startTimestampRef.current = Date();

      beginPlayingCurrPageVideo();

      /*
       * for all tasks, we need to record the child and ensure set buttons to disabled statue
       * the buttons will be enabled using timer based on the specific type of activity they are performing
       */
      if (!currPageIsInstruction) {
        startRecording();
      }

      /*
       * when a response is expected from a child, we do not wait to enable the buttons by playing the video
       * we enable the buttons and start the inactivity timer immediately upon entering the page
       */
      if (currPage.videoHasNoAudio) {
        startButtonsEnableTimer();
        startInactivityWarningTimer();
      }
    }
  }, [currPageId, error]);

  return (
    <div className='grid-container'>
      <div className='grid-x align-center'>
        <div className='small-12 medium-10 large-7 align-center'>
          <div className='puppet-show-container margin-top-2'>
            {errorMsg && <div className='alert callout'>
              <p><b>{errorMsg}</b></p>
              <p><b>Try refreshing your screen to see if the error resolves itself.</b></p>
              <p><b>If needed, please provide the research team with the following technical information:</b></p>
              <pre>page: {prettyPrintJson(currPage)}</pre>
              <pre>react-media-recorder: &lt;{error}&gt;</pre>
            </div>}
            {!errorMsg && <>
              <div className={`grid-x align-middle ${buttonsHorizontalAlignment}`}>
                {currPage.optionButtons.map(button =>
                  <div
                    className={`${currPage.optionButtons.length > 1 ? 'auto' : 'shrink'} cell text-center`}
                    key={button.image}>
                    <button
                      className='button padding-0 border-none'
                      disabled={!buttonsEnabled}
                      onClick={() => {
                        handleContinue(currPage.task,
                          currPage.title,
                          button.image,
                          button.goTo);
                      }}
                      type='button'
                    >
                      <img
                        alt={button.image}
                        src={`${SSHRC_IMAGES[button.image]}`}
                        style={{maxHeight: buttonsMaxHeight, maxWidth: 'auto'}}
                      />
                    </button>
                  </div>)}
              </div>
              <div
                className='width-100'
                style={{
                  display: 'flex',
                  justifyContent: 'center'}}>
                <video
                  disablePictureInPicture
                  disableRemotePlayback
                  id='player'
                  onEnded={handleOnEnded}
                  onPlay={handleOnPlay}
                  playsInline
                  src={currPage.videoUrl}
                  style={{'--plyr-video-background': 'rgba(#ffffff, 0)'}}/>
              </div>
            </>}

            {process.env.NODE_ENV === 'development' && <fieldset className='radius fieldset'>
              <legend>Available in development environment only</legend>
              <div className='grid-x grid-margin-x align-spaced'>
                <div className='shrink cell'>
                  <button
                    className='large button'
                    disabled={currPageId <= 0}
                    onClick={() => setCurrPageId(id => id - 1)}
                    type='button'>&lt; Prev Page</button>
                </div>
                <div className='shrink cell'>
                  <button
                    className='large button'
                    onClick={() => playerRef.current.forward(playerRef.current.duration - 1)}
                    type='button'>Skip Video to Last Second</button>
                </div>
                <div className='shrink cell'>
                  <button
                    className='large button'
                    disabled={isLastPage}
                    onClick={() => setCurrPageId(id => id + 1)}
                    type='button'>Next Page &gt;</button>

                </div>
              </div>
            </fieldset>}
          </div>
        </div>
      </div>
    </div>
  );
};

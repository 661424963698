import React, {useEffect, useState} from 'react';
import {capitalize, isEmpty} from 'lodash';
import {useNavigate} from 'react-router';
import {useDispatch, useSelector} from 'react-redux';
import {toast} from 'react-toastify';
import {getChildAge, getRandomElement} from '../../common/utils';
import {AVATARS, STUDIES_IMAGES, ICONS, ACTIVITY_IMAGES} from '../../../assets/images';
import ROUTES from '../../../routes';
import {
  getChildActivities,
  setCurrentActivity
} from './slice/sshrcSlice';
import Modal from '../../common/Modal';
import {WithdrawFromStudy} from './withdrawFromStudy';
import useErrorProvider from '../../common/useErrorProvider';

const ACTIVITY_ROUTES = {
  sshrc_act_1: ROUTES.SSHRC_STUDY_ACTIVITY_INFO
};

const currentChildSelector = state => state.study.currentChild;
const currentChildActivitiesSelector = state => state.templetonStudy.activities;

export const Activities = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentChild = useSelector(currentChildSelector);
  const childActivities = useSelector(currentChildActivitiesSelector);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const {apiErrorMsg, clearApiError, handleApiError} = useErrorProvider();

  useEffect(() => {
    if (apiErrorMsg) {
      toast.error(apiErrorMsg);
      clearApiError();
    }
  }, [apiErrorMsg]);

  useEffect(() => {
    setLoading(true);
    dispatch(getChildActivities({childId: currentChild.id}))
      .then(action => {
        if (!getChildActivities.fulfilled.match(action)) {
          handleApiError({action, message: 'Unable to fetch child activities.'});
        }
      }).catch(error => {
        handleApiError({error, message: 'Error when fetching child activities for the SSHRC study.'});
      }).finally(() => {
        setLoading(false);
      });
  }, []);

  const onActivityStart = activity => {
    dispatch(setCurrentActivity(activity));
    navigate(ACTIVITY_ROUTES[activity.slug]);
  };

  return (
    <>
      <div className='grid-container padding-0'>
        <div className='grid-x align-center'>
          {loading && <div className='loader-wrapper'><div className='loader'/></div>}
          {!loading &&
            <div className='small-12 medium-10 large-6 custom-padding'>
              <div className='padding-2'>
                <button
                  className='button primary small hollow'
                  onClick={() => navigate(ROUTES.STUDIES)}
                  type='button'
                >
                  Back
                </button>
                <p className='overline'>The Children&apos;s Thinking Study</p>
                <h1>Available Activities</h1>
                <p>
                  In this study we seek to learn about core cognitive abilities in preschool age children. Your child
                  will participate in a series of tasks, that are designed as child-friendly games, to allow us to
                  learn about preschool age children’s language development, memory, their ability to think flexibly,
                  and their ability to understand people’s behaviour. The entire set of tasks should take no
                  more than 20 minutes.
                </p>
              </div>
              <div className='padding-horizontal-3 padding-bottom-2'>
                <div className='child-card-heading'>
                  <img
                    alt='avatar'
                    src={AVATARS.OWL}
                  />
                  <h2>{currentChild.firstName}</h2>
                  <p>| Age {getChildAge(currentChild.birthDate)}</p>
                </div>
                <div className='img-content-center-aligned green-bordered-callout margin-top-1'>
                  <p className='body-3'>
                    {isEmpty(childActivities.finished) ? 0 : childActivities.finished.length}/1 Activities completed
                  </p>
                </div>
              </div>
              <div className='padding-vertical-1 padding-horizontal-2'>
                <p className='sub-title-1'>
                  Next Activity
                </p>
                {!isEmpty(childActivities.started) && childActivities.started.map(activity =>
                  <div
                    className='study-callout margin-bottom-2'
                    key={activity.title}
                    style={{
                      backgroundImage: `url(${getRandomElement(ACTIVITY_IMAGES)})`,
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: 'right top',
                      backgroundSize: '250px auto'
                    }}
                  >
                    <div className='padding-horizontal-2 padding-top-3 padding-bottom-2'>
                      <img
                        alt='Trophy Dotted'
                        className='margin-bottom-2'
                        src={STUDIES_IMAGES.TROPHY_DOTTED}
                      />
                      <h1>{activity.title}</h1>
                      <p>{activity.description}</p>
                    </div>
                    <div
                      className='study-callout-bottom padding-horizontal-2 padding-vertical-1'
                      onClick={() => onActivityStart(activity)}
                    >
                      <p className='sub-title-1'>Start Activity</p>
                      <img
                        alt='pointing_arrow'
                        src={ICONS.POINTING_ARROW}
                      />
                    </div>
                  </div>)}
                {!isEmpty(childActivities.incomplete) && childActivities.incomplete.map(activity =>
                  <div
                    className='study-callout margin-bottom-2'
                    key={activity.title}
                    style={{
                      backgroundImage: `url(${getRandomElement(ACTIVITY_IMAGES)})`,
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: 'right top',
                      backgroundSize: '250px auto'
                    }}
                  >
                    <div className='padding-horizontal-2 padding-top-3 padding-bottom-2'>
                      <img
                        alt='Trophy Dotted'
                        className='margin-bottom-2'
                        src={STUDIES_IMAGES.TROPHY_DOTTED}
                      />
                      <h1>{activity.title}</h1>
                      <p>{activity.description}</p>
                    </div>
                    <div
                      className='study-callout-bottom padding-horizontal-2 padding-vertical-1'
                      onClick={() => onActivityStart(activity)}
                    >
                      <p className='sub-title-1'>Start Activity</p>
                      <img
                        alt='pointing_arrow'
                        src={ICONS.POINTING_ARROW}
                      />
                    </div>
                  </div>)}
              </div>
              <p className='sub-title-1 padding-vertical-1 padding-horizontal-2'>
                Completed Activities
              </p>
              <div className='completed-studies padding-horizontal-2'>
                <>
                  {!isEmpty(childActivities.finished) && childActivities.finished.map(ele =>
                    <div
                      className='study-card padding-horizontal-1'
                      key={ele.id}
                    >
                      <div className='content margin-vertical-2'>
                        <img
                          alt='Trophy'
                          src={STUDIES_IMAGES.TROPHY}
                        />
                        <p className='sub-title-2 margin-top-1'>{capitalize(ele.title)}</p>
                      </div>
                    </div>)}
                  {!isEmpty(childActivities.started) && childActivities.started.map(ele =>
                    <div
                      className='study-card padding-horizontal-1'
                      key={ele.id}
                    >
                      <div className='content margin-vertical-2'>
                        <img
                          alt='Trophy'
                          src={STUDIES_IMAGES.TROPHY_DOTTED}
                        />
                        <p className='sub-title-2 margin-top-1'>{capitalize(ele.title)}</p>
                      </div>
                    </div>)}
                  {!isEmpty(childActivities.incomplete) && childActivities.incomplete.map(ele =>
                    <div
                      className='study-card padding-horizontal-1'
                      key={ele.id}
                    >
                      <div className='content margin-vertical-2'>
                        <img
                          alt='Trophy'
                          src={STUDIES_IMAGES.TROPHY_DOTTED}
                        />
                        <p className='sub-title-2 margin-top-1'>{capitalize(ele.title)}</p>
                      </div>
                    </div>)}
                </>
              </div>
              <div className='margin-vertical-2 margin-horizontal-2'>
                <div className='grid-x align-center-middle opt-out-callout'>
                  <div
                    className='auto small-6 cell height-auto text-center'
                  >
                    <img
                      alt='Hedgehog'
                      src={STUDIES_IMAGES.HEDGEHOG}
                    />
                  </div>
                  <div className='auto small-6 cell'>
                    <p className='body-3 padding-1'>
                      Would you like to withdraw from the Children&apos;s Thinking Study Study?
                    </p>
                    <p className='padding-horizontal-1'>
                      <button
                        className='button primary'
                        onClick={() => setShowModal(true)}
                        type='button'
                      >Withdraw</button>
                    </p>
                  </div>
                </div>
              </div>
            </div>}
          <Modal
            onClose={() => setShowModal(false)}
            show={showModal}
          >
            <WithdrawFromStudy
              onClose={() => setShowModal(false)}
            />
          </Modal>
        </div>
      </div>
    </>
  );
};

import React, {useEffect} from 'react';
import {useNavigate} from 'react-router';
import {useDispatch, useSelector} from 'react-redux';
import {isEmpty} from 'lodash';
import ROUTES from '../../../routes';
import {HOME_IMAGES, ICONS} from '../../../assets/images';
import {userDetails} from '../profile/slice/profileSlice';
import Footer from '../../common/Footer';
import {toast} from 'react-toastify';
import useErrorProvider from '../../common/useErrorProvider';

const authSelector = state => state.auth.authenticated;
const userSelector = state => state.profile.details;

const Index = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authenticated = useSelector(authSelector);
  const user = useSelector(userSelector);
  const {apiErrorMsg, clearApiError, handleApiError} = useErrorProvider();

  useEffect(() => {
    if (apiErrorMsg) {
      toast.error(apiErrorMsg);
      clearApiError();
    }
  }, [apiErrorMsg]);

  useEffect(() => {
    if (!authenticated) {
      navigate(ROUTES.SIGN_IN, {replace: true});
    }
  }, []);

  useEffect(() => {
    if (authenticated) {
      dispatch(userDetails()).then(action => {
        if (!userDetails.fulfilled.match(action)) {
          handleApiError({action});
        }
      }).catch(error => {
        handleApiError({error, message: 'Error when fetching user details from home page.'});
      });
    }
  }, [authenticated]);

  return (
    <div>
      <div className='grid-container padding-0'>
        <div
          className='hero'
          style={{
            backgroundImage: `url(${HOME_IMAGES.SQUIRREL}), url(${HOME_IMAGES.BUTTERFLY})`,
            backgroundRepeat: 'no-repeat, no-repeat',
            backgroundPosition: 'right bottom, right 20px top 20px'
          }}
        >
          <div className='padding-vertical-1 padding-horizontal-2'>
            <h1>Hello {user.firstName}</h1>
            <h1>Welcome to Rosie</h1>
            <p className='width-75'>
              Rosie is an app that connects families and researchers, all for the purpose of further
              understanding child development and maximizing children’s well-being. Rosie allows families
              to learn about child-friendly studies that seek to understand children’s cognitive and
              social development, participate in those studies from their smart phone or tablet,
              and to learn about the science of child development.
            </p>
            <div className='study-callout'>
              <img
                alt='Check'
                src={HOME_IMAGES.GREEN_CHECK}/>
              <p>2 Studies Available</p>
            </div>
            <button
              className='button primary large'
              onClick={() => navigate(ROUTES.STUDIES, {replace: true})}
              type='button'>
            View Studies
            </button>
          </div>
        </div>
        <div className='padding-vertical-1 padding-horizontal-2'>
          <div className='alert-callout margin-vertical-2'>
            <div className='padding-2'>
              <img
                alt='Alert'
                className='alert-img'
                src={ICONS.ALERT}
              />
              <h1>Add a Child</h1>
              {isEmpty(user.children)
                ? <p>
                    You have not yet added a child to your profile. Please proceed to the Profile page and add a child
                    in order to view and participate in studies.
                </p>
                : <p>
                    You can add another child through profile. Please proceed to the Profile page and add another child.
                </p>
              }
            </div>
            <div
              className='alert-callout-bottom padding-horizontal-2 padding-vertical-1'
              onClick={() => navigate(ROUTES.CHILDREN, {replace: true})}
            >
              <p className='sub-title-1'>Update Profile</p>
              <img
                alt='pointing_arrow'
                src={ICONS.POINTING_ARROW}
              />
            </div>
          </div>
        </div>
        <div
          className='padding-top-1 padding-horizontal-3 home-bottom-padding'
          style={{
            backgroundImage: `url(${HOME_IMAGES.TOADSTOOLS})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'bottom 70px left 30px'
          }}
        >

          <h1>On the Rosie app you can</h1>
          <ul>
            <li>
              <p className='width-75'>
                Sign up to learn about child development studies your child is
                eligible for.
              </p>
            </li>
            <li>
              <p className='width-75'>
                Have your child participate in child development studies directly from your smart phone
                or tablet. After signing the consent form and setting up the study you can
                simply hand over your device to your child.
              </p>
            </li>
            <li>
              <p className='width-75'>
                Learn about the science of child development.
              </p>
            </li>
          </ul>
        </div>
      </div>
      <Footer/>
    </div>
  );
};

export default Index;

import React, {useEffect, useState} from 'react';
import {capitalize, isEmpty} from 'lodash';
import {useNavigate} from 'react-router';
import {useDispatch, useSelector} from 'react-redux';
import {toast} from 'react-toastify';
import {getChildAge, getRandomElement} from '../../common/utils';
import {AVATARS, STUDIES_IMAGES, ICONS, ACTIVITY_IMAGES} from '../../../assets/images';
import ROUTES from '../../../routes';
import {getChildActivities, getCurrentTempletonActivity, setCurrentActivity} from './slice/templetonSlice';
import Modal from '../../common/Modal';
import {WithdrawFromStudy} from './withdrawFromStudy';
import useErrorProvider from '../../common/useErrorProvider';

const ACTIVITY_ROUTES = {
  puppet_show: ROUTES.TEMPLETON_STUDY_ACTIVITY_INFO,
  story_book_1: ROUTES.TEMPLETON_STUDY_STORYBOOK_1_INFO,
  story_book_2: ROUTES.TEMPLETON_STUDY_STORYBOOK_1_INFO
};

const currentChildSelector = state => state.study.currentChild;
const currentChildActivitiesSelector = state => state.templetonStudy.activities;
const currentActivitySelector = state => state.templetonStudy.currentAvailableActivity;

export const Activities = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentChild = useSelector(currentChildSelector);
  const childActivities = useSelector(currentChildActivitiesSelector);
  const availableActivity = useSelector(currentActivitySelector);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const {apiErrorMsg, clearApiError, handleApiError} = useErrorProvider();

  useEffect(() => {
    if (apiErrorMsg) {
      toast.error(apiErrorMsg);
      clearApiError();
    }
  }, [apiErrorMsg]);

  useEffect(() => {
    setLoading(true);
    Promise.all([
      dispatch(getChildActivities({childId: currentChild.id}))
        .then(action => {
          if (!getChildActivities.fulfilled.match(action)) {
            handleApiError({action, message: 'Unable to fetch child activities.'});
          }
        }).catch(error => {
          handleApiError({error, message: 'Error when fetching child activities for the Templeton study.'});
        }),
      dispatch(getCurrentTempletonActivity({childId: currentChild.id}))
        .then(action => {
          if (!getCurrentTempletonActivity.fulfilled.match(action)) {
            handleApiError({action, message: 'Unable to fetch Templeton study activities.'});
          }
        }).catch(error => {
          handleApiError({error, message: 'Error when fetching Templeton study activities.'});
        })
    ]).finally(() => {
      setLoading(false);
    });
  }, []);

  const onActivityStart = activity => {
    dispatch(setCurrentActivity(activity));
    navigate(ACTIVITY_ROUTES[activity.slug]);
  };

  return (
    <>
      <div className='grid-container padding-0'>
        <div className='grid-x align-center'>
          {loading && <div className='loader-wrapper'><div className='loader'/></div>}
          {!loading &&
            <div className='small-12 medium-10 large-6 custom-padding'>
              <div className='padding-2'>
                <button
                  className='button primary small hollow'
                  onClick={() => navigate(ROUTES.STUDIES)}
                  type='button'
                >
                  Back
                </button>
                <p className='overline'>The Helping Study</p>
                <h1>Available Activities</h1>
                <p>
                  In this study we are interested in learning about how activities that promote 3- and 4-year-old
                  children’s tendency to consider other people’s needs may benefit their prosocial behavior (their
                  ability to do things that will benefit other people). Your child will complete 3 different
                  activities over a one-week period; you will receive reminder notifications when it is time to do
                  a particular activity and you will have 48 hours to complete the activity with your child. Two of
                  these activities will consist of storybooks that you read to your child and record their responses
                  to key questions by touching a relevant button on the screen. The third will consist of a puppet
                  show that your child will watch; at certain times the narrator will direct questions toward your
                  child to answer.
                </p>
              </div>
              <div className='padding-horizontal-3 padding-bottom-2'>
                <div className='child-card-heading'>
                  <img
                    alt='avatar'
                    src={AVATARS.OWL}
                  />
                  <h2>{currentChild.firstName}</h2>
                  <p>| Age {getChildAge(currentChild.birthDate)}</p>
                </div>
                <div className='img-content-center-aligned green-bordered-callout margin-top-1'>
                  <p className='body-3'>
                    {isEmpty(childActivities.finished) ? 0 : childActivities.finished.length}/3 Activities completed
                  </p>
                </div>
              </div>
              <div className='padding-vertical-1 padding-horizontal-2'>
                <p className='sub-title-1'>
                  Next Activity
                </p>
                {isEmpty(availableActivity) &&
                  <div
                    className='study-callout margin-bottom-2'
                  >
                    <div className='padding-2'>
                      <h1>Coming soon!</h1>
                      <p>
                        Your next Activity isn’t available just yet! Keep your eyes on this space.
                      </p>
                    </div>
                    <div
                      className='study-callout-bottom graded-out-callout padding-horizontal-2 padding-vertical-1'
                    >
                      <p className='sub-title-1'>Start Activity</p>
                      <img
                        alt='pointing_arrow'
                        src={ICONS.POINTING_ARROW}
                      />
                    </div>
                  </div>
                }
                {!isEmpty(availableActivity) &&
                  <div
                    className='study-callout margin-bottom-2'
                    key={availableActivity.title}
                    style={{
                      backgroundImage: `url(${getRandomElement(ACTIVITY_IMAGES)})`,
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: 'right top',
                      backgroundSize: '250px auto'
                    }}
                  >
                    <div className='padding-horizontal-2 padding-top-3 padding-bottom-2'>
                      <img
                        alt='Trophy Dotted'
                        className='margin-bottom-2'
                        src={STUDIES_IMAGES.TROPHY_DOTTED}
                      />
                      <h1>{availableActivity.title}</h1>
                      <p>{availableActivity.description}</p>
                    </div>
                    <div
                      className='study-callout-bottom padding-horizontal-2 padding-vertical-1'
                      onClick={() => onActivityStart(availableActivity)}
                    >
                      <p className='sub-title-1'>Start Activity</p>
                      <img
                        alt='pointing_arrow'
                        src={ICONS.POINTING_ARROW}
                      />
                    </div>
                  </div>
                }
              </div>
              <p className='sub-title-1 padding-vertical-1 padding-horizontal-2'>
                Completed Activities
              </p>
              <div className='completed-studies padding-horizontal-2'>
                <>
                  {!isEmpty(childActivities.finished) && childActivities.finished.map(ele =>
                    <div
                      className='templeton-study-card'
                      key={ele.id}
                    >
                      <div className='content margin-vertical-2'>
                        <img
                          alt='Trophy'
                          src={STUDIES_IMAGES.TROPHY}
                        />
                        <p className='sub-title-2 margin-top-1'>{capitalize(ele.title)}</p>
                      </div>
                    </div>)}
                  {!isEmpty(childActivities.started) && childActivities.started.map(ele =>
                    <div
                      className='templeton-study-card'
                      key={ele.id}
                    >
                      <div className='content margin-vertical-2'>
                        <img
                          alt='Trophy'
                          src={STUDIES_IMAGES.TROPHY_DOTTED}
                        />
                        <p className='sub-title-2 margin-top-1'>{capitalize(ele.title)}</p>
                      </div>
                    </div>)}
                  {!isEmpty(childActivities.incomplete) && childActivities.incomplete.map(ele =>
                    <div
                      className='templeton-study-card'
                      key={ele.id}
                    >
                      <div className='content margin-vertical-2'>
                        <img
                          alt='Trophy'
                          src={STUDIES_IMAGES.TROPHY_DOTTED}
                        />
                        <p className='sub-title-2 margin-top-1'>{capitalize(ele.title)}</p>
                      </div>
                    </div>)}
                </>
              </div>
              <div className='margin-vertical-2 margin-horizontal-2'>
                <div className='grid-x align-center-middle opt-out-callout'>
                  <div
                    className='auto small-6 cell height-auto text-center'
                  >
                    <img
                      alt='Hedgehog'
                      src={STUDIES_IMAGES.HEDGEHOG}
                    />
                  </div>
                  <div className='auto small-6 cell'>
                    <p className='body-3 padding-1'>
                      Would you like to withdraw from the Helping Study?
                    </p>
                    <p className='padding-horizontal-1'>
                      <button
                        className='button primary'
                        onClick={() => setShowModal(true)}
                        type='button'
                      >Withdraw</button>
                    </p>
                  </div>
                </div>
              </div>
              <div>
                <details>
                  <summary
                    className='child-card-heading padding-vertical-1 padding-horizontal-2'
                    style={{
                      backgroundImage: `url(${ICONS.ARROW})`,
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: 'right 30px center'
                    }}
                  >
                    <p className='sub-title-1'>Future Activities</p>
                  </summary>
                  <div className='padding-horizontal-2'>
                    {!isEmpty(childActivities.incomplete) && childActivities.incomplete.map(ele =>
                      <div
                        className='study-callout padding-2 margin-bottom-1'
                        key={ele.title}
                      >
                        <div
                          className='padding-vertical-1'
                          style={{
                            backgroundImage: `url(${STUDIES_IMAGES.LOCK})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'right center'
                          }}
                        >
                          <h1 className='width-50'>{ele.title}</h1>
                        </div>
                        <p>{ele.description}</p>
                      </div>)}
                  </div>
                </details>
              </div>
            </div>}
          <Modal
            onClose={() => setShowModal(false)}
            show={showModal}
          >
            <WithdrawFromStudy
              onClose={() => setShowModal(false)}
            />
          </Modal>
        </div>
      </div>
    </>
  );
};

import {useForm} from 'react-hook-form';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router';
import {useGoogleReCaptcha} from 'react-google-recaptcha-v3';
import React, {useCallback, useState} from 'react';
import {registerUser} from './slice/authSlice';
import ROUTES from '../../../routes';
import {Link} from 'react-router-dom';
import Modal from '../../common/Modal';
import {TermsAndConditions} from '../../components/termsAndConditions';
import {PrivacyPolicy} from '../../components/PrivacyPolicy';
import {toast} from 'react-toastify';
import useErrorProvider from '../../common/useErrorProvider';

export const RegistrationForm = () => {
  const {
    register,
    handleSubmit,
    getValues,
    formState: {errors}
  } = useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {executeRecaptcha} = useGoogleReCaptcha();
  const {apiErrorMsg, clearApiError, handleApiError} = useErrorProvider();
  const [termsAndConditionsTime, setTermsAndConditionsTime] = useState(null);
  const [privacyPolicyTime, setPrivacyPolicyTime] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [modelType, setModalType] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleReCaptchaVerify = useCallback(() =>
    new Promise((resolve, reject) => {
      if (executeRecaptcha) {
        resolve(executeRecaptcha('RegistrationForm'));
      }
      else {
        reject(new Error('executeRecaptcha is not available'));
      }
    }), [executeRecaptcha]);

  const onSubmit = data => {
    setLoading(true);
    clearApiError();

    handleReCaptchaVerify().then(() => {
      dispatch(registerUser({...data,
        privacy_policy_accepted_at: privacyPolicyTime, // eslint-disable-line camelcase
        terms_of_use_accepted_at: termsAndConditionsTime // eslint-disable-line camelcase
      })).then(action => {
        if (registerUser.fulfilled.match(action)) {
          navigate(ROUTES.SIGN_IN, {replace: true});
          toast.success('Registration successful. Please check your inbox for confirmation email.');
        }
        else {
          handleApiError({action});
        }
      }).catch(error => {
        handleApiError({error, message: 'Error when registering an account.'});
      }).finally(() => {
        setLoading(false);
      });
    }).catch(() => {
      setLoading(false);
      toast.error('Recaptcha validation failed. Please try again.');
    });
  };

  return <>
    {loading && <div className='loader-wrapper'><div className='loader'/></div>}
    {!loading &&
      <form
        onSubmit={handleSubmit(onSubmit)}>
        <h1 className='padding-vertical-2'>Register</h1>
        {apiErrorMsg && <div className='alert callout'>{apiErrorMsg}</div>}
        <div className='form-card-wrapper'>

          <label>Parent First Name
            <input
              className='form-card'
              type='text'
              {...register('parent_first_name', {required: true})}
            />
            {errors.parent_first_name && <p className='error'>Parent First Name is required.</p>}
          </label>
          <label>Parent Last Name
            <input
              className='form-card'
              type='text'
              {...register('parent_last_name', {required: true})}
            />
            {errors.parent_last_name && <p className='error'>Parent Last Name is required.</p>}
          </label>
          <label>Email
            <input
              className='form-card'
              type='email'
              {...register('email', {required: true})}
            />
            {errors.email && <p className='error'>Email is required.</p>}
          </label>
          <label>
          Create a Password
            <input
              className='form-card'
              type='password'
              {...register('password', {required: true})}
            />
            {errors.password && <p className='error'>Password is required.</p>}
          </label>
          <label>
          Confirm Password
            <input
              className='form-card'
              type='password'
              {...register('password_confirmation',
                {required: true, validate: value => value === getValues().password})}
            />
            {errors.password_confirmation &&
            <p className='error'>The passwords above should match. Please try again</p>}
          </label>
          <label>
          Select Time Zone
            <select
              name='time_zone'
              {...register('time_zone', {required: true})}>
              <option value=''>-- Select a time zone --</option>
              {
                ct.getCountry('CA').timezones.map(tz => <option // eslint-disable-line no-undef
                  key={tz}
                  value={tz}>{tz}</option>)
              }
            </select>
            {errors.time_zone && <p className='error'>Time Zone is required.</p>}
          </label>
          <label>
          Postal Code (optional)
            <input
              className='form-card'
              type='text'
              {...register('postal_code')}
            />
          </label>
        </div>
        <div className='padding-1'>
          <div>
            <input
              className='middle'
              id='terms_and_conditions'
              onClick={data => data.target.checked && setTermsAndConditionsTime(new Date())}
              type='checkbox'
              {...register('terms_and_conditions', {required: true})}
            />
            <label
              className='margin-right-0'
              htmlFor='terms_and_conditions'>
              I have read and accept the
            </label>
            &nbsp;
            <a
              className='text-underline'
              onClick={() => {
                setShowModal(true);
                setModalType(ROUTES.TERMS_AND_CONDITIONS);
              }}>
              Terms & Conditions
            </a>
            {errors.terms_and_conditions && <p className='error'>Field is required.</p>}
          </div>
          <div>
            <input
              id='privacy_policy'
              onClick={data => data.target.checked && setPrivacyPolicyTime(new Date())}
              type='checkbox'
              {...register('privacy_policy', {required: true})}
            />
            <label
              className='margin-right-0'
              htmlFor='privacy_policy'>
              I have read and agree to the
            </label>
            &nbsp;<a
              className='text-underline'
              onClick={() => {
                setShowModal(true);
                setModalType(ROUTES.PRIVACY_POLICY);
              }}>Privacy Policy</a>
            {errors.privacy_policy && <p className='error'>Field is required.</p>}
          </div>
        </div>

        <div className='grid-x align-justify'>
          <div className='cell'>
            <div className=' border-top padding-vertical-2'>
              This form is protected by reCAPTCHA, and subject to the Google&nbsp;
              <a
                className='text-underline'
                href='//policies.google.com/privacy'
                rel='noopener noreferrer'
                target='_blank'>Privacy Policy</a>
              &nbsp;and <a
                className='text-underline'
                href='//policies.google.com/terms'
                rel='noopener noreferrer'
                target='_blank'>Terms of Service</a>.
            </div>
            <button
              className='button primary expanded margin-top-0'
              type='submit'>Submit
            </button>
            <Link
              className='button primary hollow expanded'
              to={ROUTES.SIGN_IN}
            >Back</Link>
          </div>
        </div>
        <Modal
          onClose={() => {
            setShowModal(false);
            setModalType(null);
          }}
          show={showModal}>
          {
            modelType === ROUTES.TERMS_AND_CONDITIONS &&
            <TermsAndConditions onClose={() => {
              setShowModal(false);
              setModalType(null);
            }}/>
          }
          {
            modelType === ROUTES.PRIVACY_POLICY &&
            <PrivacyPolicy onClose={() => {
              setShowModal(false);
              setModalType(null);
            }}/>
          }
        </Modal>
      </form>
    }
  </>;
};

import React, {useEffect, useState} from 'react';
import {STUDIES_IMAGES} from '../../../assets/images';
import ROUTES from '../../../routes';
import {useNavigate} from 'react-router';
import {useDispatch, useSelector} from 'react-redux';
import {completeChildEnrollment} from './slice/sshrcSlice';
import {toast} from 'react-toastify';
import useErrorProvider from '../../common/useErrorProvider';

const enrollmentSelector = state => state.sshrcStudy.enrollment;
const currentChildSelector = state => state.study.currentChild;

export const Congratulations = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const enrollment = useSelector(enrollmentSelector);
  const child = useSelector(currentChildSelector);
  const {apiErrorMsg, clearApiError, handleApiError} = useErrorProvider();

  useEffect(() => {
    if (apiErrorMsg) {
      toast.error(apiErrorMsg);
      clearApiError();
    }
  }, [apiErrorMsg]);

  useEffect(() => {
    setLoading(true);
    dispatch(completeChildEnrollment({
      childId: child.id,
      enrollmentId: enrollment.id,
      studyCompletedAt: Date()
    })).then(action => {
      if (completeChildEnrollment.fulfilled.match(action)) {
        toast.success('Study successfully completed!');
      }
      else {
        handleApiError({action, message: 'Unable to complete the study.'});
      }
    }).catch(error => {
      handleApiError({error, message: 'Error when completing the SSHRC study.'});
    }).finally(() => {
      setLoading(false);
    });
  }, []);

  return (
    <>
      <div className='grid-x align-center'>
        <div className='small-12 medium-10 large-6'>
          {loading && <div className='loader-wrapper'><div className='loader'/></div>}
          {!loading &&
            <>
              <div className='padding-vertical-3 padding-horizontal-2 bg-white'>
                <h1>Congratulations!</h1>
                <div className='grid-x align-center margin-vertical-3'>
                  <img
                    alt='Congratulations Trophy'
                    src={STUDIES_IMAGES.TROPHY_2}
                  />
                </div>
              </div>
              <div className='padding-2'>
                <p className='sub-title-1'>You completed the Children&apos;s Thinking Study!</p>
                <button
                  className='button primary expanded margin-0 margin-top-3'
                  onClick={() => navigate(ROUTES.STUDIES)}
                  type='button'
                >
                  Continue
                </button>
              </div>
            </>
          }
        </div>
      </div>
    </>
  );
};

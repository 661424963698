import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {axiosCall, responseFormat} from '../../../../config/axios';
import * as ENDPOINTS from '../../../../config/endpoints';

export const initialState = {
  studies: [],
  currentChild: {
    id: '',
    participantId: '',
    firstName: '',
    lastName: '',
    birthDate: '',
    active: ''
  },
  currentStudy: {
    id: '',
    title: '',
    category: '',
    minAgeInMonths: 0,
    maxAgeInMonths: 0,
    activities: []
  }
};

export const getStudies = createAsyncThunk('studies/all', (_, {rejectWithValue}) => {
  try {
    return axiosCall('get', ENDPOINTS.STUDY.ALL, {})
      .then(response => responseFormat(response, {details: response.data}))
      .catch(error => rejectWithValue({error}));
  }
  catch (error) {
    return rejectWithValue({error});
  }
});

export const studySlice = createSlice({
  name: 'study',
  initialState,
  reducers: {
    setCurrentChild: (state, payload) => {
      state.currentChild = {
        id: payload.payload.id,
        participantId: payload.payload.participantId,
        firstName: payload.payload.firstName,
        lastName: payload.payload.lastName,
        birthDate: payload.payload.birthDate,
        active: payload.payload.active
      };
    },
    setCurrentStudy: (state, payload) => {
      state.currentStudy = {
        id: payload.payload.id,
        title: payload.payload.title,
        slug: payload.payload.slug,
        minAgeInMonths: payload.payload.minAgeInMonths,
        maxAgeInMonths: payload.payload.maxAgeInMonths,
        activities: payload.payload.activities
      };
    },
    resetStudyState: state => {
      state.currentChild = {};
      state.currentStudy = {};
    }
  },
  extraReducers: builder => {
    builder.addCase(getStudies.fulfilled, (state, action) => {
      state.studies = action.payload.details;
    });
  }
});

export const {setCurrentChild, setCurrentStudy, resetStudyState} = studySlice.actions;

export default studySlice.reducer;

const ROUTES = {
  ROOT: '/',
  SIGN_IN: '/sign_in',
  REGISTER: '/register',
  FORGET_PASSWORD: '/forget_password',
  RESET_PASSWORD: '/reset_password',
  RESEND_CONFIRMATION: '/resend_confirmation',
  STUDIES: '/studies',
  TEMPLETON_STUDY: '/templeton_study',
  TEMPLETON_STUDY_INTRO: '/templeton_study/intro',
  TEMPLETON_STUDY_CONSENT: '/templeton_study/consent',
  TEMPLETON_STUDY_ACTIVITIES: '/templeton_study/activities',
  TEMPLETON_STUDY_ACTIVITY_INFO: '/templeton_study/activity/info',
  TEMPLETON_STUDY_ACTIVITY_VIDEO: '/templeton_study/activity/video_activity',
  TEMPLETON_STUDY_ACTIVITY_RESULTS: '/templeton_study/activity/results',
  TEMPLETON_STUDY_ACTIVITY_CONGRATULATIONS: '/templeton_study/activity/congratulations',
  TEMPLETON_STUDY_QUESTIONNAIRE: '/templeton_study/activity/questionnaire',
  TEMPLETON_STUDY_STORYBOOK_1: '/templeton_study/activity/storybook/book',
  TEMPLETON_STUDY_STORYBOOK_1_INFO: '/templeton_study/activity/storybook/info',
  TEMPLETON_STUDY_STORYBOOK_1_SURVEY: '/templeton_study/activity/storybook/survey',
  TEMPLETON_STUDY_STORYBOOK_1_CONGRATULATIONS: '/templeton_study/activity/storybook/congratulations',
  TEMPLETON_STUDY_PUPPETSHOW_SURVEY: '/templeton_study/activity/puppet-show/survey',
  TEMPLETON_STUDY_PUPPETSHOW_CONGRATULATIONS: '/templeton_study/activity/puppet-show/congratulations',
  SSHRC_STUDY: '/sshrc_study',
  SSHRC_STUDY_INTRO: '/sshrc_study/intro',
  SSHRC_STUDY_CONSENT: '/sshrc_study/consent',
  SSHRC_STUDY_ACTIVITIES: '/sshrc_study/activities',
  SSHRC_STUDY_ACTIVITY_INFO: '/sshrc_study/activity/info',
  SSHRC_STUDY_ACTIVITY: '/sshrc_study/activity',
  SSHRC_STUDY_CONGRATULATION: '/sshrc_study/congratulations',
  RESOURCES: '/resources',
  PROFILE: '/profile',
  CHILDREN: '/profile/add_children',
  PHONE_VERIFICATION: '/profile/phone_verification',
  SECOND: '/second',
  PRIVACY_POLICY: '/privacy_policy',
  TERMS_AND_CONDITIONS: '/terms_and_conditions'
};

export default ROUTES;

import React from 'react';
import {FOOTER_ICONS} from '../../assets/images';
import ROUTES from '../../routes';
import {useLocation} from 'react-router-dom';

const Footer = () => {
  const location = useLocation();

  return (
    <div className='footer padding-bottom-1'>
      <ul className='menu'>
        <li
          className='menu-item'
          style={{flex: 1}}>
          <a
            className={`menu-item ${location.pathname === ROUTES.ROOT ? 'menu-item-active' : ''}`}
            href={ROUTES.ROOT}>
            <img
              alt='Home'
              src={location.pathname === ROUTES.ROOT ? FOOTER_ICONS.HOME_ACTIVE : FOOTER_ICONS.HOME}/>
            Home
          </a>
        </li>
        <li
          className='menu-item'
          style={{flex: 1}}>
          <a
            className={`menu-item ${location.pathname === ROUTES.PROFILE ? 'menu-item-active' : ''}`}
            href={ROUTES.PROFILE}>
            <img
              alt='Profile'
              src={location.pathname === ROUTES.PROFILE ? FOOTER_ICONS.PROFILE_ACTIVE : FOOTER_ICONS.PROFILE}/>
            Profile
          </a>
        </li>
        <li
          className='menu-item'
          style={{flex: 1}}>
          <a
            className={`menu-item ${location.pathname === ROUTES.STUDIES ? 'menu-item-active' : ''}`}
            href={ROUTES.STUDIES}>
            <img
              alt='Studies'
              src={location.pathname === ROUTES.STUDIES ? FOOTER_ICONS.STUDIES_ACTIVE : FOOTER_ICONS.STUDIES}/>
            Studies
          </a>
        </li>
      </ul>
    </div>
  );
};

export default Footer;

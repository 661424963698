import React from 'react';
import {Routes, Route} from 'react-router-dom';
import HelmetWrapper from '../HelmetWrapper';
import ROUTES from '../../../routes';
import {SignIn} from '../../features/authentication/SignIn';
import {Register} from '../../features/authentication/Register';
import {ForgetPassword} from '../../features/authentication/ForgetPassword';
import {ResetPassword} from '../../features/authentication/ResetPassword';
import {ResendConfirmation} from '../../features/authentication/ResendConfirmation';
import {PrivacyPolicy} from '../../components/PrivacyPolicy';
import {TermsAndConditions} from '../../components/termsAndConditions';
import PageNotFound from '../../features/PageNotFound';
import Home from '../../features/home';

const UnProtectedRoutes = () => <Routes>
  <Route
    element={<HelmetWrapper
      component={Home}
      description='Description'
      title='Un protected Home'
    />}
    exact
    path={ROUTES.ROOT}
  />
  <Route
    element={<HelmetWrapper
      component={SignIn}
      description='Description'
      title='Sign In'
    />}
    exact
    path={ROUTES.SIGN_IN}
  />
  <Route
    element={<HelmetWrapper
      component={Register}
      description='Description'
      title='Sign Up'
    />}
    exact
    path={ROUTES.REGISTER}
  />
  <Route
    element={<HelmetWrapper
      component={ForgetPassword}
      description='Description'
      title='Forget Password'
    />}
    exact
    path={ROUTES.FORGET_PASSWORD}
  />
  <Route
    element={<HelmetWrapper
      component={ResetPassword}
      description='Description'
      title='Reset Password'
    />}
    exact
    path={ROUTES.RESET_PASSWORD}
  />
  <Route
    element={<HelmetWrapper
      component={ResendConfirmation}
      description='Description'
      title='Reset Confirmation'
    />}
    exact
    path={ROUTES.RESEND_CONFIRMATION}
  />
  <Route
    element={<HelmetWrapper
      component={PrivacyPolicy}
      description='Description'
      title='Privacy Policy'
    />}
    exact
    path={ROUTES.PRIVACY_POLICY}
  />
  <Route
    element={<HelmetWrapper
      component={TermsAndConditions}
      description='Description'
      title='Terms And Conditions'
    />}
    exact
    path={ROUTES.TERMS_AND_CONDITIONS}
  />
  <Route
    element={<HelmetWrapper
      component={PageNotFound}
      description='Page Not found'
      title='Page Not Found'
    />}
    exact
    path='*'
  />
</Routes>;

export default UnProtectedRoutes;

export const getChildAge = birthDate => {
  const dob = new Date(birthDate);
  const ageDt = new Date(Date.now() - dob.getTime());
  const year = ageDt.getUTCFullYear();
  const age = Math.abs(year - 1970);

  if (age === 0) {
    return '< 1 year';
  }

  return age;
};

export const getRandomElement = object => {
  const objectValues = Object.values(object);
  return objectValues[Math.floor(Math.random() * objectValues.length)];
};

export const getVideoId = url => {
  const splitUrl = url.split('/');
  return splitUrl[splitUrl.length - 1];
};

export const prettyPrintJson = json => JSON.stringify(json, null, 2);

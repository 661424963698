import React, {useEffect} from 'react';
import ReactGA from 'react-ga4';
import {useLocation} from 'react-router';
import ProtectedRoutes from './app/common/router/ProtectedRoutes';
import UnProtectedRoutes from './app/common/router/UnProtectedRoutes';
import {useSelector} from 'react-redux';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Header from './app/common/Header';
import {config} from './config';

ReactGA.initialize(config.google.analytics.measurementID, {
  anonymizeIp: true,
  testMode: process.env.NODE_ENV === 'test',
  gaOptions: {
    anonymizeIp: true,
    cookieFlags: 'SameSite=None;Secure'
  }
});

const trackPage = location => {
  ReactGA.send({hitType: 'pageview', page: location.pathname});
};

const selector = state => state.auth.authenticated;

const App = () => {
  const authenticated = useSelector(selector);
  const location = useLocation();
  useEffect(() => {
    trackPage(location);
  }, [location]);

  return <>
    <Header/>
    {authenticated ? <ProtectedRoutes/> : <UnProtectedRoutes/>}
    <ToastContainer
      hideProgressBar
      position='top-center'
    />
  </>;
};

export default App;

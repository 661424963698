import React from 'react';
import {GoogleReCaptchaProvider} from 'react-google-recaptcha-v3';
import {RegistrationForm} from './RegistrationForm';
import {config} from '../../../config';

export const Register = () => <div className='grid-x align-center'>
  <div className='small-10 medium-6 large-4 cell padding-bottom-2'>
    <section>
      <GoogleReCaptchaProvider reCaptchaKey={config.google.recaptcha.siteKey}>
        <RegistrationForm/>
      </GoogleReCaptchaProvider>
    </section>
  </div>
</div>;

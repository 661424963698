import React, {useEffect, useState} from 'react';
import {isEmpty, findIndex} from 'lodash';
import ROUTES from '../../../../routes';
import {useNavigate} from 'react-router';
import {useDispatch, useSelector} from 'react-redux';
import {
  completeChildSelectedActivity,
  getChildActivities,
  createActivityProgress,
  getChildSelectedActivityStatus
} from '../slice/sshrcSlice';
import {toast} from 'react-toastify';
import content from './content.json';
import {SSHRC_TASK_TYPES} from '../../../constants';
import useErrorProvider from '../../../common/useErrorProvider';
import 'plyr/src/sass/plyr.scss';
import Plyr from 'plyr';

const currentActivitySelector = state => state.sshrcStudy.currentActivity;
const currentChildSelector = state => state.study.currentChild;

export const Index = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const child = useSelector(currentChildSelector);
  const activity = useSelector(currentActivitySelector);
  const currentChild = useSelector(currentChildSelector);
  const [activityProgress, setActivityProgress] = useState([]);
  const [continueButtonDisabled, setContinueButtonDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const {apiErrorMsg, clearApiError, handleApiError} = useErrorProvider();

  useEffect(() => {
    if (apiErrorMsg) {
      toast.error(apiErrorMsg);
      clearApiError();
    }
  }, [apiErrorMsg]);

  const apiGetChildActivityStatus = () => {
    setLoading(true);
    dispatch(getChildSelectedActivityStatus({childId: child.id, activityId: activity.id}))
      .then(action => {
        if (getChildSelectedActivityStatus.fulfilled.match(action)) {
          setActivityProgress(action.payload.details[0]);
        }
        else {
          handleApiError({action, message: 'Unable to get child activity status.'});
        }
      }).catch(error => {
        handleApiError({error, message: 'Error when fetching child activity status in SSHRC activity index.'});
      }).finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    new Plyr('#player', {
      controls: ['play-large', 'progress', 'current-time'],
      fullscreen: {enabled: false, fallback: false, iosNative: false},
      invertTime: false
    });

    apiGetChildActivityStatus();
  }, []);

  useEffect(() => {
    setLoading(true);
    dispatch(getChildActivities({childId: currentChild.id}))
      .then(action => {
        if (!getChildActivities.fulfilled.match(action)) {
          handleApiError({action, message: 'Unable to fetch child activities.'});
        }
      }).catch(error => {
        handleApiError({error, message: 'Error when fetching child activities index for the SSHRC study.'});
      }).finally(() => {
        setLoading(false);
      });
  }, []);

  const onBack = () => {
    navigate(ROUTES.SSHRC_STUDY_ACTIVITIES);
  };

  const onActivityContinue = () => {
    if (isEmpty(activityProgress.progress)) {
      navigate(ROUTES.SSHRC_STUDY_ACTIVITY, {
        state: {nextSegmentPage: 0}
      });
      return;
    }

    // Get the last saved record task type
    const activityStoppedOnPage = activityProgress.progress[activityProgress.progress.length - 1].task;

    // check what that saved record task type is set the next segment as starting point
    if (activityStoppedOnPage === SSHRC_TASK_TYPES.DCCS) {
      navigate(ROUTES.SSHRC_STUDY_ACTIVITY, {
        state: {nextSegmentPage: findIndex(content.pages, ele => ele.task === SSHRC_TASK_TYPES.DIVERSE_DESIRES)}
      });
    }
    else if (activityStoppedOnPage === SSHRC_TASK_TYPES.DIVERSE_DESIRES) {
      navigate(ROUTES.SSHRC_STUDY_ACTIVITY, {
        state: {nextSegmentPage: findIndex(content.pages, ele => ele.task === SSHRC_TASK_TYPES.DIVERSE_BELIEFS)}
      });
    }
    else if (activityStoppedOnPage === SSHRC_TASK_TYPES.DIVERSE_BELIEFS) {
      navigate(ROUTES.SSHRC_STUDY_ACTIVITY, {
        state: {nextSegmentPage: findIndex(content.pages, ele => ele.task === SSHRC_TASK_TYPES.KNOWLEDGE_ACCESS)}
      });
    }
    else if (activityStoppedOnPage === SSHRC_TASK_TYPES.KNOWLEDGE_ACCESS) {
      navigate(ROUTES.SSHRC_STUDY_ACTIVITY, {
        state: {nextSegmentPage: findIndex(content.pages, ele => ele.task === SSHRC_TASK_TYPES.FALSE_BELIEFS)}
      });
    }
    else if (activityStoppedOnPage === SSHRC_TASK_TYPES.FALSE_BELIEFS) {
      navigate(ROUTES.SSHRC_STUDY_ACTIVITY, {
        state: {nextSegmentPage: findIndex(content.pages, ele => ele.task === SSHRC_TASK_TYPES.FALSE_BELIEFS_CONTENTS)}
      });
    }
    else if (activityStoppedOnPage === SSHRC_TASK_TYPES.FALSE_BELIEFS_CONTENTS) {
      navigate(ROUTES.SSHRC_STUDY_ACTIVITY, {
        state: {nextSegmentPage: findIndex(content.pages, ele => ele.task === SSHRC_TASK_TYPES.HIDDEN_EMOTIONS)}
      });
    }
    else if (activityStoppedOnPage === SSHRC_TASK_TYPES.HIDDEN_EMOTIONS) {
      navigate(ROUTES.SSHRC_STUDY_ACTIVITY, {
        state: {nextSegmentPage: findIndex(content.pages, ele => ele.task === SSHRC_TASK_TYPES.WORKING_MEMORY)}
      });
    }
    else {
      dispatch(completeChildSelectedActivity({
        childId: child.id, activityProgressId: activityProgress.id, completedAt: Date()
      })).then(action => {
        if (completeChildSelectedActivity.fulfilled.match(action)) {
          navigate(ROUTES.SSHRC_STUDY_CONGRATULATION);
        }
        else {
          handleApiError({action, message: 'Unable to complete activity.'});
        }
      }).catch(error => {
        handleApiError({error, message: 'Error when completing the SSHRC activity index.'});
      });
    }
  };

  const onActivityStart = () => {
    setLoading(true);
    dispatch(createActivityProgress({childId: child.id, activityId: activity.id, startedAt: Date()}))
      .then(action => {
        if (createActivityProgress.fulfilled.match(action)) {
          navigate(ROUTES.SSHRC_STUDY_ACTIVITY, {
            state: {nextSegmentPage: 0}
          });
        }
        else {
          handleApiError({action, message: 'Unable to start a new activity.'});
        }
      }).catch(error => {
        handleApiError({error, message: 'Error when saving activity progress for the SSHRC study.'});
      }).finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className='grid-x align-center'>
      <div className='small-12 medium-10 large-6'>
        <div className='padding-vertical-1 padding-horizontal-2'>
          <button
            className='button primary small hollow'
            onClick={() => onBack()}
            type='button'
          >
              Back
          </button>
          <h1>Task Tutorial</h1>
          <p>
              Here is a reminder of how to set up your tablet/phone in preparation for the study.
          </p>
        </div>
        <video
          disablePictureInPicture
          disableRemotePlayback
          id='player'
          onEnded={() => setContinueButtonDisabled(false)}
          playsInline
          src='https://pub-98b9233f39024486a747dc9faf6888ad.r2.dev/sshrc_allintroclip%20(1080p).mp4'
          style={{'--plyr-video-background': 'rgba(#ffffff, 0)'}}/>
        <div className='sticky-consent bg-mid-beige'>
          <div className='padding-2 consent-button'>
            <div className='grid-x align-center'>
              {loading && <div className='loader-wrapper'><div className='loader'/></div>}
              {!loading &&
                  <>
                    {isEmpty(activityProgress)
                      ? <button
                        className='button primary expanded margin-0'
                        disabled={continueButtonDisabled}
                        onClick={() => onActivityStart()}
                        type='button'
                      >
                        Ok, let&apos;s start!
                      </button>
                      : <button
                        className='button primary expanded margin-0'
                        onClick={() => onActivityContinue()}
                        type='button'
                      >
                        Continue
                      </button>
                    }
                  </>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const {dependencies} = require('./../package.json');

/*
 * Use this file when upgrading or downgrading a package causes issues
 * that may be difficult to detect. Unfortunately. package.json does not
 * permit including comments, so this is a workaround to save any future
 * developer from inadvertently updating packages that break things in
 * subtle ways and spending hours and hours debugging the issue.
 */

const REACT_MEDIA_RECORDER = {
  name: 'react-media-recorder',
  version: '1.6.5'
};

if (dependencies[REACT_MEDIA_RECORDER.name] > REACT_MEDIA_RECORDER.version) {
  // eslint-disable-next-line max-len
  throw new Error(`Unsupported "${REACT_MEDIA_RECORDER.name}" version. Expected: <= ${REACT_MEDIA_RECORDER.version}. Actual: ${dependencies[REACT_MEDIA_RECORDER.name]}. 
  Higher versions (only 1.6.6 at the time of writing) cause the following issue when changing page routes:
  
  Uncaught Error: There is already an encoder stored which handles exactly the same mime types.
  
  To reproduce the issue, begin the Sshrc study, listen to the initial instructions video
  and wait for it to finish. The error will occur immediately after you hit "Continue".
  
  Until this issue is fixed, this package should not be updated.
  
  More information about this issue can be found here:
  https://github.com/DeltaCircuit/react-media-recorder/issues/105
  
  Added by Vadim Kononov on October 23, 2023.
  `);
}

import {logout} from '../app/features/authentication/slice/authSlice';

const tokenValidationMiddleware = store => next => action => {
  const {
    auth: {authenticated, headers}
  } = store.getState();
  const now = Math.floor(new Date().valueOf() / 1000);

  if (authenticated && headers.expiry < now && action.match && !action.match('auth/logout')) {
    return next(logout());
  }

  return next(action);
};

export default tokenValidationMiddleware;

export const STUDIES_SLUG = {
  TEMPLETON: 'templeton',
  SSHRC: 'sshrc'
};

export const STUDIES_TITLE = {
  TEMPLETON: 'The Helping Study',
  SSHRC: 'The Children\'s Thinking Study'
};

export const SSHRC_TASK_TYPES = {
  DCCS: 'DCCS',
  KNOWLEDGE_ACCESS: 'KNOWLEDGE_ACCESS',
  DIVERSE_DESIRES: 'DIVERSE_DESIRES',
  DIVERSE_BELIEFS: 'DIVERSE_BELIEFS',
  FALSE_BELIEFS: 'FALSE_BELIEFS',
  FALSE_BELIEFS_CONTENTS: 'FALSE_BELIEFS_CONTENTS',
  HIDDEN_EMOTIONS: 'HIDDEN_EMOTIONS',
  WORKING_MEMORY: 'WORKING_MEMORY'
};

export const QUALTRICS_LINKS = {
  TEMPLETON: 'https://utorontopsych.az1.qualtrics.com/jfe/form/SV_9Nby2Z8D93qBuR0',
  SSHRC_VIDEO_RELEASE_FORM: 'https://utorontopsych.az1.qualtrics.com/jfe/form/SV_bEDJWxITKKnkWtE',
  SSHRC_DEMOGRAPHIC_FORM: 'https://utorontopsych.az1.qualtrics.com/jfe/form/SV_eXyGJt6FDWq4B0i',
  SSHRC_DVAP: 'https://utorontopsych.az1.qualtrics.com/jfe/form/SV_bQ8ycG0ad2GLfUO',
  SSHRC_CSUS_SHORT: 'https://utorontopsych.az1.qualtrics.com/jfe/form/SV_2tVNmU09xPaXwmG'
};

import React, {useState} from 'react';
import {useForm} from 'react-hook-form';
import {useDispatch} from 'react-redux';
import {resendConfirmation} from './slice/authSlice';
import ROUTES from '../../../routes';
import {Link} from 'react-router-dom';
import {toast} from 'react-toastify';
import {useNavigate} from 'react-router';
import useErrorProvider from '../../common/useErrorProvider';

export const ResendConfirmation = () => {
  const {
    register,
    handleSubmit,
    formState: {errors}
  } = useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const {apiErrorMsg, clearApiError, handleApiError} = useErrorProvider();

  const onSubmit = data => {
    setLoading(true);
    clearApiError();
    dispatch(resendConfirmation(data)).then(action => {
      if (resendConfirmation.fulfilled.match(action)) {
        toast.success(`Confirmation email sent to the email address provided. Please confirm your email
         address and sign in.`);
        navigate(ROUTES.SIGN_IN, {replace: true});
      }
      else {
        handleApiError({action});
      }
    }).catch(error => {
      handleApiError({error, message: 'Error when resending email confirmation.'});
    }).finally(() => {
      setLoading(false);
    });
  };

  return (
    <div className='grid-x align-center'>
      <div className='small-10 medium-6 large-4 cell padding-bottom-2'>
        <section>
          <div className='padding-vertical-2'>
            <h1>Resend Confirmation</h1>
            <p>Form to resend confirmation</p>
          </div>
          {loading && <div className='loader-wrapper'><div className='loader'/></div>}
          {!loading &&
            <div className='form-card-wrapper'>
              <form onSubmit={handleSubmit(onSubmit)}>
                {apiErrorMsg && <div className='alert callout'>{apiErrorMsg}</div>}
                <label>Email Address
                  <input
                    className='form-card'
                    type='email'
                    {...register('email', {required: true})}
                  />
                  {errors.email && <p className='error'>Email is required.</p>}
                </label>
                <button
                  className='button primary expanded'
                  type='submit'
                >Submit
                </button>
                <Link
                  className='button primary hollow expanded '
                  to={ROUTES.SIGN_IN}
                >Back</Link>
              </form>
            </div>
          }
        </section>
      </div>
    </div>
  );
};

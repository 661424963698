import React, {useState} from 'react';
import {AVATARS} from '../../assets/images';
import PropTypes from 'prop-types';

export const Avatar = ({imageName}) => {
  const [avatar, setAvatar] = useState(imageName ? imageName.toUpperCase() : '');
  const avatarList = ['ORANGE_OWL', 'FOX', 'DEER', 'CLOSED_EYES_OWL', 'CATERPILLAR', 'BUNNY', 'BEAR', 'OWL'];

  if (!avatar || avatar === '' || !avatarList.includes(avatar)) {
    setAvatar('ORANGE_OWL');
  }

  return <div>
    <img
      alt='avatar'
      src={`${AVATARS[avatar]}`}
    />
  </div>;
};

export default Avatar;

Avatar.propTypes = {
  imageName: PropTypes.string.isRequired
};

import React from 'react';
import ROUTES from '../../routes';
import PropTypes from 'prop-types';

export const TermsAndConditions = ({onClose}) =>
  <section className='margin-2'>
    <h1 className='margin-bottom-1'>Terms And Conditions</h1>
    <div className='scroll'>
      <h5>Terms And Conditions</h5>
      <p>
        These terms and conditions (“Agreement”) set forth the general terms and conditions of your
        use of the “Rosie” mobile application (“Mobile Application” or “Service”) and any of its related
        products and services (collectively, “Services”). This Agreement is legally binding between you
        (“User”, “you” or “your”) and this Mobile Application developer (“Operator”, “we”, “us” or
        “our”). If you are entering into this agreement on behalf of a business or other legal entity, you
        represent that you have the authority to bind such entity to this agreement, in which case the
        terms “User”, “you” or “your” shall refer to such entity. If you do not have such authority, or if
        you do not agree with the terms of this agreement, you must not accept this agreement and
        may not access and use the Mobile Application and Services. By accessing and using the Mobile
        Application and Services, you acknowledge that you have read, understood, and agree to be
        bound by the terms of this Agreement. You acknowledge that this Agreement is a contract
        between you and the Operator, even though it is electronic and is not physically signed by you,
        and it governs your use of the Mobile Application and Services.
      </p>
      <h5>Accounts and membership</h5>
      <p>
        If you create an account in the Mobile Application, you are responsible for maintaining the
        security of your account and you are fully responsible for all activities that occur under the
        account and any other actions taken in connection with it. We may, but have no obligation to,
        monitor and review new accounts before you may sign in and start using the Services. Providing
        false contact information of any kind may result in the termination of your account. You must
        immediately notify us of any unauthorized uses of your account or any other breaches of
        security. We will not be liable for any acts or omissions by you, including any damages of any
        kind incurred as a result of such acts or omissions.
      </p>
      <h5>Links to other resources</h5>
      <p>
        Although the Mobile Application and Services may link to other resources (such as websites,
        mobile applications, etc.), we are not, directly or indirectly, implying any approval, association,
        sponsorship, endorsement, or affiliation with any linked resource, unless specifically stated
        herein. We are not responsible for examining or evaluating, and we do not warrant the
        offerings of, any businesses or individuals or the content of their resources. We do not assume
        any responsibility or liability for the actions, products, services, and content of any other third
        parties. You should carefully review the legal statements and other conditions of use of any
        resource which you access through a link in the Mobile Application. Your linking to any other
        off-site resources is at your own risk.
      </p>
      <h5>Intellectual property rights</h5>
      <p>
        “Intellectual Property Rights” means all present and future rights conferred by statute, common
        law or equity in or in relation to any copyright and related rights, trademarks, designs, patents,
        inventions, goodwill and the right to sue for passing off, rights to inventions, rights to use, and
        all other intellectual property rights, in each case whether registered or unregistered and
        including all applications and rights to apply for and be granted, rights to claim priority from,
        such rights and all similar or equivalent rights or forms of protection and any other results of
        intellectual activity which subsist or will subsist now or in the future in any part of the world.
        This Agreement does not transfer to you any intellectual property owned by the Operator or
        third parties, and all rights, titles, and interests in and to such property will remain (as between
        the parties) solely with the Operator. All trademarks, service marks, graphics and logos used in
        connection with the Mobile Application and Services, are trademarks or registered trademarks
        of the Operator or its licensors. Other trademarks, service marks, graphics and logos used in
        connection with the Mobile Application and Services may be the trademarks of other third
        parties. Your use of the Mobile Application and Services grants you no right or license to
        reproduce or otherwise use any of the Operator or third party trademarks.
      </p>
      <h5>Changes and amendments</h5>
      <p>
        We reserve the right to modify this Agreement or its terms related to the Mobile Application
        and Services at any time at our discretion. When we do, we will revise the updated date at the
        bottom of this page. We may also provide notice to you in other ways at our discretion, such as
        through the contact information you have provided.
      </p>
      <p>
        An updated version of this Agreement will be effective immediately upon the posting of the
        revised Agreement unless otherwise specified. Your continued use of the Mobile Application
        and Services after the effective date of the revised Agreement (or such other act specified at
        that time) will constitute your consent to those changes.
      </p>
      <h5>Acceptance of these terms</h5>
      <p>
        You acknowledge that you have read this Agreement and agree to all its terms and conditions.
        By accessing and using the Mobile Application and Services you agree to be bound by this
        Agreement. If you do not agree to abide by the terms of this Agreement, you are not authorized
        to access or use the Mobile Application and Services.
      </p>
      <h5>Contacting us</h5>
      <p>
        If you have any questions, concerns, or complaints regarding this Agreement, we encourage
        you to contact us using the details below:
      </p>
      <p>
        TheRosieProject@utoronto.ca
      </p>
      <p>
        This document was last updated on August 18, 2022
      </p>
    </div>
    <div className='grid-x align-justify margin-vertical-2'>
      <div className='cell auto shrink'>
        <button
          className='primary button'
          onClick={onClose}
          to={ROUTES.REGISTER}
          type='button'
        >Close</button>
      </div>
    </div>
  </section>;

TermsAndConditions.propTypes = {
  onClose: PropTypes.func.isRequired
};

const AUTH = {
  REGISTER: '/users/auth',
  SIGN_IN: '/users/auth/sign_in',
  SIGN_OUT: '/users/auth/sign_out',
  FORGOT_PASSWORD: '/users/auth/password',
  RESET_PASSWORD: '/users/auth/password',
  RESEND_CONFIRMATION: '/users/auth/confirmation'
};

const USER = {
  DETAILS: '/users',
  UPDATE: '/users',
  CHILDREN_UPDATE: '/children',
  ADD_CHILD: '/children'
};

const STUDY = {
  ALL: '/studies'
};

const ENROLLMENT = {
  GET: '/enrollments',
  CREATE: '/enrollments',
  UPDATE: '/enrollments'
};

const ACTIVITY_PROGRESS = {
  CREATE: '/activity_progresses',
  GET: '/activity_progresses',
  UPDATE: '/activity_progresses'
};

const ACTIVITY = {
  GET: '/activities',
  CURRENT_TEMPLETON_ACTIVITY: '/activities/current_templeton_activity'
};

const TWILIO = {
  GET: '/twilio/send_verification_token',
  CHECK: '/twilio/check_verification_token'
};

export {
  AUTH,
  USER,
  STUDY,
  ENROLLMENT,
  ACTIVITY,
  ACTIVITY_PROGRESS,
  TWILIO
};

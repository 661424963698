import {applyMiddleware, createStore} from 'redux';
import thunk from 'redux-thunk';
import storage from 'redux-persist/lib/storage';
import {persistReducer, persistStore} from 'redux-persist'; // defaults to localStorage for web
import reducers from './reducers';
import {composeWithDevTools} from '@redux-devtools/extension';
import tokenValidationMiddleware from '../middleware/token_validation';

/*
 * Uncomment the lower section and add additional middlewares over here
 * const middlewares = [
 *   thunk
 * ];
 */

// Once the middlewares array above gets populated, replace the "thunk" with "...middlewares" in line below
const enhancers = [applyMiddleware(thunk, tokenValidationMiddleware)];
const persistConfig = {key: 'root', storage, blacklist: ['router']};
const persistedReducer = persistReducer(persistConfig, reducers);

export default preloadedState => {
  const store = createStore(persistedReducer,
    preloadedState,
    composeWithDevTools(...enhancers),);
  const persistor = persistStore(store);

  // persistor.purge();

  return {store, persistor};
};

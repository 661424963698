import {createAsyncThunk} from '@reduxjs/toolkit';
import {axiosCall, responseFormat} from '../../../../config/axios';
import * as ENDPOINTS from '../../../../config/endpoints';

export const initialState = {};

export const updateUserPhoneNumber = createAsyncThunk('user_phone/update', (details, {rejectWithValue}) => {
  try {
    return axiosCall('patch', ENDPOINTS.USER.UPDATE, {
      phone: details.phone
    })
      .then(response => responseFormat(response, {details: response.data}))
      .catch(error => rejectWithValue({error}));
  }
  catch (error) {
    return rejectWithValue({error});
  }
});

export const getVerificationToken = createAsyncThunk('twilio/get', (_, {rejectWithValue}) => {
  try {
    return axiosCall('post', ENDPOINTS.TWILIO.GET, {})
      .then(response => responseFormat(response, {details: response.data}))
      .catch(error => rejectWithValue({error}));
  }
  catch (error) {
    return rejectWithValue({error});
  }
});

export const checkVerificationToken = createAsyncThunk('twilio/check', (details, {rejectWithValue}) => {
  try {
    return axiosCall('post', ENDPOINTS.TWILIO.CHECK, {
      code: details.verification_code
    })
      .then(response => responseFormat(response, {details: response.data}))
      .catch(error => rejectWithValue({error}));
  }
  catch (error) {
    return rejectWithValue({error});
  }
});

import React from 'react';
import {SITE_LOGO} from '../../assets/images';
import ROUTES from '../../routes';
import {config} from '../../config';

const Header = () => {
  const handleLogoClick = () => {
    window.location.href = ROUTES.ROOT;
  };

  return (
    <div className='header text-left align-left'>
      <div className='grid-container width-100 padding-horizontal-2'>
        <div className='grid-x grid-margin-x align-justify align-middle'>
          <div
            className='shrink cell'
            onClick={handleLogoClick}>
            <a href={ROUTES.ROOT}>
              <img
                alt='Rosie'
                src={SITE_LOGO}
              />
            </a>
          </div>
          {config.app.version.date && <div className='shrink cell'>
            <div className='text-right line-height-1 help-text'>
              <small>
                Version Date:<br/>
                {new Date(config.app.version.date).toLocaleString()}
              </small>
            </div>
          </div>}
        </div>
      </div>
    </div>
  );
};

export default Header;

import React from 'react';

export const SSHRCConsentForm = () =>
  <div>
    <h4>STUDY INFORMATION SHEET</h4>
    <p>
      Please read this study information sheet very carefully before you participate in this experiment.
      If anything is unclear to you or if you have any questions, please ask the experimenter for more information.
      This study is being run by the Toronto Early Cognition Lab at the University of Toronto and the Early Experience
      Lab at Queen’s University. This study is funded by the Social Sciences and Humanities Research Council’s New
      Frontier Research Fund.
    </p>
    <h5>Purpose of the Study:</h5>
    <p>
      The purpose of this research is to investigate children’s thinking and problem solving. We are interested in
      their ability to follow complicated rules, understand other peoples’ thoughts and feelings, and remember short
      lists. To do this, we have created tasks that can be done on a smartphone or a tablet. We want to see how children
      do on these tasks. We are also interested in seeing whether children can do these tasks without the help of a
      parent or other adult.
    </p>
    <h5>What you and your child are going to be doing:</h5>
    <p>
      During the session, your child will play three separate games on this tablet or smart phone. In the first game,
      children will be asked to sort cards of simple objects based on their color (red or blue) or their shape
      (rabbit or boat). In the second game, your child will watch a series of simple stories and will be asked to answer
      questions about what characters might think or feel. In the third game, your child will be asked to remember short
      lists of objects and repeat them in backwards order. Sometimes your child will be asked to answer the questions
      out loud, and sometimes by tapping on the screen of the smartphone or tablet.
    </p>
    <p>
      In addition, you will be asked to complete 3 surveys before your child’s completion of the games. This includes a
      demographics form that asks questions about your child and your family, a vocabulary development form that asks
      you to check off words that your child understandings, and a form that asks you to respond to questions about your
      child’s social understanding.
    </p>
    <p>
      In total, this study will take about 30 minutes to complete. We will collect video and audio recording of your
      child completing the study, and the responses they make by tapping on the screen. We do this so we can analyze
      their answers later. We hope that you’ll be able to do the surveys and tasks all at once. If you need to take a
      short break, you can, and the app should return to where you left off.
    </p>
    <h5>Risks and Discomforts:</h5>
    <p>
      There are no known risks to your child for being in this study. If your child does not wish to participate or gets
      upset during the study, they can stop playing the games whenever they like without penalty.
    </p>
    <h5>Benefits:</h5>
    <p>
      While there are no direct benefits to you or your child, many children enjoy the study activities and playing with
      the experimenters. You and your child will also be making a substantial contribution to improving our
      understanding of the development of children’s social behaviour.
    </p>
    <h5>Costs and Compensation:</h5>
    <p>
      There are no known costs to you for participating except for you and your child’s time. You will be paid with a
      small gift certificate for being in this study. Your child will receive a participation certificate and a small
      ‘thank you’ gift for participating.
    </p>
    <h5>Confidentiality, Participation and Withdrawal:</h5>
    <p>
      We will follow all applicable laws about keeping your data confidential. No one will know that you and your child
      participated in today’s experiment. The data that you produce from this experiment, from the surveys or your
      child’s responses, will never have your name or your child’s name attached to it. Everything that happens in this
      experiment, including you and your child participating today, will stay completely confidential. The file linking
      yours and your child’s name to their data will be securely stored on a university encrypted server. Your data can
      only be accessed by the study team at University of Toronto and Queen’s University.
    </p>
    <p>
      The Human Research Ethics Unit at The University of Toronto and the Queen’s General Research Ethics Board (GREB)
      may request confidential access to the data to help ensure participation protection procedures are followed, or
      that we are meeting our ethical obligations in conducting this research. These oversight committees are bound by
      confidentiality agreements and will not release any personal information.
    </p>
    <p>
      Ultimately, we aim to present the results of this experiment at conferences and publish them in a journal,
      magazine, or newspaper. If we do, you and your child will never be mentioned. Your data will be destroyed about
      ten years after the study is complete unless separate consent for future research is given.
    </p>
    <p>
      Your child can stop the experiment at any time without penalty, by simply stopping to respond to the games on
      screen. If your child decides to stop at any point, no questions will be asked. If you are participating in the
      lab or with another researcher on zoom, all you have to say is that you would like to end the experiment and that
      you’re ready to go. If you are participating on your own, you can just stop responding to the games, or stop
      doing the surveys. You will still receive the gift card and your child will still receive a certificate and a
      small gift for participating.
    </p>
    <p>
      If you choose to end the experiment, we will withdraw your data. You can also choose to withdraw your data after
      the experiment is completed. If you stop in the middle of the study, we might use the data that you gave us up to
      that point. You can withdraw your data at any time, whether you completed the whole study or stopped in the
      middle, without penalty. You can do so by following these steps: go to the “Studies” tab in the app, click “Go to
      Study” under your child’s name, then click “Withdraw” at the bottom of the page. Once you tell us to withdraw your
      data it will not be included in any further analyses, presentations, or publications.
    </p>
    <h5>Video Recording:</h5>
    <p>
      We are requesting to record your child while your child participates in the study tasks. Your child’s name would
      never be associated with any of these uses. The video footage will be securely stored without identifying
      information. The footage will only be seen by the research investigators, unless separate consent is given for
      the videos to be used in other venues (e.g., conferences or in the media; see below). Footage will be destroyed
      approximately ten years after the study is complete, unless separate consent for use in future research is given.
      In one of the surveys that we ask you to do we will ask for your consent to use the recordings in this way. You
      do not have to give consent for that to participate in this study.
    </p>
    <h5>Contact:</h5>
    <p>
      If you have any questions about this experiment at this point, please ask the experimenter before signing the
      consent form. You can also contact the principal investigators, Dr. Jessica Sommerville at
      <u> jessica.sommerville@utoronto.ca</u> or 416-978-1815 or Dr. Mark Sabbagh at <u>sabbagh@queensu.ca</u> or
      613-533-2887. If you have any ethics concerns, please contact the General Research Ethics Board (GREB) at
      1-844-535- 2988 (Toll free in North America) or email <u>chair.GREB@queensu.ca.</u>
    </p>
    <h4>RESEARCH CONSENT FORM</h4>
    <p>
      Please read this consent agreement very carefully before you participate in this experiment. If anything is
      unclear to you or if you have any questions, please ask the experimenter for more information. You have not waived
      any legal rights by consenting to be involved in this study.
    </p>
    <h5>Agreement to Participate</h5>
    <p>
      By signing this consent form you are indicating that:</p>
    <ul>
      <li>You’ve read the included study information sheet, or it has been read to you.</li>
      <li>You are the legal parent/guardian of the child you are enrolling.</li>
      <li>You are voluntarily agreeing that you and your child will participate in the study.</li>
      <li>You agree to have your child’s participation video recorded just so we can analyze their responses later.
      </li>
    </ul>
      To sign this consent form, please check the box below:
  </div>;

/* eslint-disable max-len */
import React, {useEffect, useState} from 'react';
import ROUTES from '../../../routes';
import {useNavigate} from 'react-router';
import {useDispatch, useSelector} from 'react-redux';
import {createChildEnrollment} from './slice/sshrcSlice';
import {toast} from 'react-toastify';
import {QUALTRICS_LINKS, STUDIES_TITLE} from '../../constants';
import {SSHRCConsentForm} from './sshrc_consent_form';
import useErrorProvider from '../../common/useErrorProvider';

const currentChildSelector = state => state.study.currentChild;
const currentStudySelector = state => state.study.currentStudy;
const currentActivitySelector = state => state.sshrcStudy.currentActivity;

export const Consent = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentChild = useSelector(currentChildSelector);
  const currentStudy = useSelector(currentStudySelector);
  const currentActivity = useSelector(currentActivitySelector);
  const [loading, setLoading] = useState(false);
  const [isConsentButtonDisabled, setIsConsentButtonDisabled] = useState(true);
  const [isConsentFormSigned, setIsConsentFormSigned] = useState(false);
  const [isFirstLinkClicked, setIsFirstLinkClicked] = useState(false);
  const [isSecondLinkClicked, setIsSecondLinkClicked] = useState(false);
  const [isThirdLinkClicked, setIsThirdLinkClicked] = useState(false);
  const [isFourthLinkClicked, setIsFourthLinkClicked] = useState(false);
  const {apiErrorMsg, clearApiError, handleApiError} = useErrorProvider();

  useEffect(() => {
    if (apiErrorMsg) {
      toast.error(apiErrorMsg);
      clearApiError();
    }
  }, [apiErrorMsg]);

  const onLinksClicked = () => {
    if (isConsentFormSigned && isFirstLinkClicked && isSecondLinkClicked && isThirdLinkClicked && isFourthLinkClicked) {
      setIsConsentButtonDisabled(false);
    }
    else {
      setIsConsentButtonDisabled(true);
    }
  };

  useEffect(() => {
    onLinksClicked();
  }, [isConsentFormSigned, isFirstLinkClicked, isSecondLinkClicked, isThirdLinkClicked, isFourthLinkClicked]);

  const onSave = () => {
    setLoading(true);
    dispatch(createChildEnrollment({childId: currentChild.id, studyId: currentStudy.id, enrolledToStudyAt: Date()}))
      .then(action => {
        if (createChildEnrollment.fulfilled.match(action)) {
          navigate(ROUTES.SSHRC_STUDY_INTRO);
          toast.success('Enrolled successfully!');
        }
        else {
          handleApiError({action, message: 'Unable to enroll into the study.'});
        }
      }).catch(error => {
        handleApiError({error, message: 'Error when enrolling into the SSHRC study.'});
      }).finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className='grid-x align-center'>
      <div className='small-12 medium-10 large-6'>
        <div className='padding-vertical-1 padding-horizontal-2 sshrc-consent-content'>
          <button
            className='button primary small hollow'
            onClick={() => navigate(ROUTES.SSHRC_STUDY_INTRO)}
            type='button'
          >
            Back
          </button>
          <h1>{STUDIES_TITLE.SSHRC}</h1>
          {!isConsentFormSigned && <>
            <div>
              <SSHRCConsentForm/>
              <div className='flex-row'>
                <input
                  id='consent'
                  onChange={() => setIsConsentFormSigned(true)}
                  type='checkbox'
                />
                <label
                  className='margin-right-0'
                  htmlFor='consent'>
                  I have read the associated consent form and agreed to the terms and conditions outlined.
                </label>
              </div>
            </div>
          </>}
          {isConsentFormSigned && <>
            <p>
              Please click on each of the following links and complete each questionnaire. Each link is specific to
              certain information we need for this study. Once all questionnaires are complete, you may press the
              ”Begin Study” button.
            </p>
            <div
              className='flex-row'
              style={{
                marginBottom: '2rem'
              }}>
              <label
                className='margin-right-0'
                htmlFor='first_qualtrics_survey'>
                <input
                  checked={isFirstLinkClicked}
                  id='first_qualtrics_survey'
                  readOnly
                  type='checkbox'
                />
                <span>
                  <a
                    className='margin-bottom-1 text-underline'
                    href={`${QUALTRICS_LINKS.SSHRC_VIDEO_RELEASE_FORM}?study_id=${currentStudy.id}&activity_id=${currentActivity.id}&participant_id=${currentChild.id}`}
                    onClick={() => setIsFirstLinkClicked(true)}
                    rel='noreferrer'
                    target='_blank'>
                    Questionnaire 1
                  </a>
                </span>
              </label>
            </div>
            <div
              className='flex-row'
              style={{
                marginBottom: '2rem'
              }}>
              <label
                className='margin-right-0'
                htmlFor='second_qualtrics_survey'>
                <input
                  checked={isSecondLinkClicked}
                  id='second_qualtrics_survey'
                  readOnly
                  type='checkbox'
                />
                <span>
                  <a
                    className='margin-bottom-1 text-underline'
                    href={`${QUALTRICS_LINKS.SSHRC_DEMOGRAPHIC_FORM}?study_id=${currentStudy.id}&activity_id=${currentActivity.id}&participant_id=${currentChild.id}`}
                    onClick={() => setIsSecondLinkClicked(true)}
                    rel='noreferrer'
                    target='_blank'>
                    Questionnaire 2
                  </a>
                </span>
              </label>
            </div>
            <div
              className='flex-row'
              style={{
                marginBottom: '2rem'
              }}>
              <label
                className='margin-right-0'
                htmlFor='third_qualtrics_survey'>
                <input
                  checked={isThirdLinkClicked}
                  id='third_qualtrics_survey'
                  readOnly
                  type='checkbox'
                />
                <span>
                  <a
                    className='margin-bottom-1 text-underline'
                    href={`${QUALTRICS_LINKS.SSHRC_DVAP}?study_id=${currentStudy.id}&activity_id=${currentActivity.id}&participant_id=${currentChild.id}`}
                    onClick={() => setIsThirdLinkClicked(true)}
                    rel='noreferrer'
                    target='_blank'>
                    Questionnaire 3
                  </a>
                </span>
              </label>
            </div>
            <div
              className='flex-row'
              style={{
                marginBottom: '4rem'
              }}>
              <label
                className='margin-right-0'
                htmlFor='fourth_qualtrics_survey'>
                <input
                  checked={isFourthLinkClicked}
                  id='fourth_qualtrics_survey'
                  readOnly
                  type='checkbox'
                />
                <span>
                  <a
                    className='margin-bottom-1 text-underline'
                    href={`${QUALTRICS_LINKS.SSHRC_CSUS_SHORT}?study_id=${currentStudy.id}&activity_id=${currentActivity.id}&participant_id=${currentChild.id}`}
                    onClick={() => setIsFourthLinkClicked(true)}
                    rel='noreferrer'
                    target='_blank'>
                    Questionnaire 4
                  </a>
                </span>
              </label>
            </div>
            <p
              className='text-underline'
              onClick={() => setIsConsentFormSigned(false)}>
              View Consent Form again
            </p>
          </>}
        </div>
        {loading && <div className='loader-wrapper'><div className='loader'/></div>}
        {!loading &&
          <div className='sshrc-sticky-consent bg-light-beige'>
            <div className='width-100 padding-2 consent-button'>
              <div className='grid-x align-center'>
                <button
                  className='button primary expanded margin-0'
                  disabled={isConsentButtonDisabled}
                  onClick={() => onSave()}
                  type='button'>Begin Study
                </button>
              </div>
            </div>
          </div>
        }
      </div>
    </div>
  );
};

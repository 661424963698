import React, {useEffect, useState} from 'react';
import ROUTES from '../../../routes';
import {useNavigate} from 'react-router';
import {useDispatch, useSelector} from 'react-redux';
import {ICONS} from '../../../assets/images';
import {toast} from 'react-toastify';
import {getChildSshrcEnrollment} from './slice/sshrcSlice';
import {STUDIES_TITLE} from '../../constants';
import useErrorProvider from '../../common/useErrorProvider';

const sshrcSelector = state => state.sshrcStudy;
const currentChildSelector = state => state.study.currentChild;

export const Info = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const sshrcStudy = useSelector(sshrcSelector);
  const currentChild = useSelector(currentChildSelector);
  const [loading, setLoading] = useState(false);
  const {apiErrorMsg, clearApiError, handleApiError} = useErrorProvider();

  useEffect(() => {
    if (apiErrorMsg) {
      toast.error(apiErrorMsg);
      clearApiError();
    }
  }, [apiErrorMsg]);

  useEffect(() => {
    setLoading(true);
    dispatch(getChildSshrcEnrollment({childId: currentChild.id}))
      .then(action => {
        if (!getChildSshrcEnrollment.fulfilled.match(action)) {
          handleApiError({action, message: 'Unable to get SSHRC enrollment status.'});
        }
      }).catch(error => {
        handleApiError({error, message: 'Error when fetching SSHRC enrollment status.'});
      }).finally(() => {
        setLoading(false);
      });
  }, []);

  const onBack = () => {
    navigate(ROUTES.STUDIES);
  };

  return (
    <div className='grid-x align-center'>
      <div className='small-12 medium-10 large-6'>
        <div className='padding-vertical-1 padding-horizontal-2'>
          <button
            className='button primary small hollow'
            onClick={() => onBack()}
            type='button'
          >
            Back
          </button>
          <h1>{STUDIES_TITLE.SSHRC}</h1>
          <p>
            In this study we seek to learn about core cognitive abilities in preschool age children. Your child will
            participate in a series of tasks, that are designed as child-friendly games, to allow us to learn about
            preschool age children’s language development, memory, their ability to think flexibly, and their ability
            to understand people’s behaviour. The entire set of tasks should take no more than 20 minutes.
          </p>
        </div>
        <div className='consent-callout-wrapper padding-2'>
          <p className='sub-title-1'>Sign the Study Consent Form</p>
          <div className='consent-callout padding-3'>
            <p className='overline'>The Children&apos;s Thinking Study</p>
            <h1>Parental Consent</h1>
            <p>Please read and sign to continue.</p>
            {loading && <div className='loader-wrapper'><div className='loader'/></div>}
            {!loading &&
              <>
                <button
                  className='button primary img-content-center-aligned'
                  disabled={sshrcStudy.enrolledToStudyAt}
                  onClick={() => navigate(ROUTES.SSHRC_STUDY_CONSENT)}
                  type='button'
                >
                  <img
                    alt='Pen'
                    src={sshrcStudy.enrolledToStudyAt ? ICONS.CHECK_MARK : ICONS.PEN}/>
                  Sign consent form
                </button><br/>
                <button
                  className='primary button'
                  disabled={!sshrcStudy.enrolledToStudyAt}
                  onClick={() => navigate(ROUTES.SSHRC_STUDY_ACTIVITIES)}
                  type='button'
                >Begin Study</button>
              </>
            }
          </div>
        </div>
      </div>
    </div>
  );
};

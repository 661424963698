import {useRollbar} from '@rollbar/react';
import React, {useState} from 'react';
import {AxiosError} from 'axios';
import logger from 'loglevel';

const useErrorProvider = () => {
  const UNKNOWN_ERROR_MESSAGE = 'An unknown error has occurred';
  const [apiErrorMsg, setErrorMsg] = useState();
  const rollbar = useRollbar();

  /**
   * This method centralizes error handling for API calls, making it easier to manage and report errors.
   * It allows for custom error messages and can extract error information from various sources,
   * including the error and action objects. It also integrates with Rollbar to log and report errors.
   *
   * NOTE: At least one [params.action] or [params.error] must be provided for this method to function.
   *
   * @param {Object} params - An object containing parameters.
   * @param {Object} [params.action] - A redux action containing the error from a "then" block (optional).
   * @param {Object} [params.error] - The error object that comes from a "catch" block (optional).
   * @param {String} [params.message] - A custom error message to override API error message (optional).
   *
   * @throws {Error} Throws an error if neither "action" nor "error" is provided.
   *
   * @return {void}
   */
  const handleApiError = ({action = null, error = null, message = null}) => {
    if (!action && !error) {
      throw Error('At least one "action" or "error" must be provided');
    }

    let tmpErrorMsg = UNKNOWN_ERROR_MESSAGE;
    const err = error || action?.payload?.error || action?.error;

    if (message) {
      tmpErrorMsg = message;
    }
    else if (err.response && err.response.data && err.response.data.error) {
      tmpErrorMsg = err.response.data.error.toString();
    }
    else if (err.response && err.response.data && err.response.data.errors) {
      if (err.response.data.errors.constructor === Array) {
        // eslint-disable-next-line react/no-array-index-key
        tmpErrorMsg = <ul>{err.response.data.errors.map((e, i) => <li key={i}>{e}</li>)}</ul>;
      }
      else if (err.response.data.errors.full_messages?.constructor === Array) {
        // eslint-disable-next-line react/no-array-index-key
        tmpErrorMsg = <ul>{err.response.data.errors.full_messages.map((e, i) => <li key={i}>{e}</li>)}</ul>;
      }
      else {
        tmpErrorMsg = err.response.data.errors.toString();
      }
    }
    else if (err.data) {
      tmpErrorMsg = err.data;
    }
    else if (err.message) {
      tmpErrorMsg = err.message;
    }

    setErrorMsg(tmpErrorMsg);

    logger.error(tmpErrorMsg, error || action?.payload?.error);

    // only unexpected errors are sent to Rollbar (not validation errors)
    if (error || action?.payload?.error?.constructor !== AxiosError) {
      rollbar.error(tmpErrorMsg, error);
    }
  };

  const clearApiError = () => {
    setErrorMsg(null);
  };

  return {
    apiErrorMsg,
    clearApiError,
    handleApiError
  };
};

export default useErrorProvider;

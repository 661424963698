import React from 'react';
import {useNavigate} from 'react-router';
import ROUTES from '../../../routes';

export const Questionnaire = () => {
  const navigate = useNavigate();

  return (
    <div className='grid-x align-center'>
      <div className='small-12 medium-10 large-6'>
        <div className='padding-vertical-1 padding-horizontal-2'>
          <h1>Questionnaire</h1>
          <p>
            Please complete this survey/log on your experience with your child during this Activity.
          </p>
          <div className='study-callout padding-2 margin-bottom-1'>
            <form>
              <label className='padding-bottom-1'>
                Question
                <select name='question'>
                  <option>Select</option>
                  <option>Option 1</option>
                  <option>Option 2</option>
                  <option>Option 3</option>
                </select>
              </label>
              <label className='padding-bottom-1'>
                Question
                <select name='question'>
                  <option>Select</option>
                  <option>Option 1</option>
                  <option>Option 2</option>
                  <option>Option 3</option>
                </select>
              </label>
              <label>
                Question
                <select name='question'>
                  <option>Select</option>
                  <option>Option 1</option>
                  <option>Option 2</option>
                  <option>Option 3</option>
                </select>
              </label>
            </form>
          </div>
          <div className='padding-horizontal-3'>
            <button
              className='button primary expanded margin-0 margin-top-3'
              onClick={() => navigate(ROUTES.TEMPLETON_STUDY_ACTIVITIES)}
              type='button'
            >
              Continue
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

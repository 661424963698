import React, {useEffect, useState} from 'react';
import ROUTES from '../../../routes';
import {useNavigate} from 'react-router';
import {useDispatch, useSelector} from 'react-redux';
import {createChildEnrollment} from './slice/templetonSlice';
import {toast} from 'react-toastify';
import {STUDIES_TITLE} from '../../constants';
import useErrorProvider from '../../common/useErrorProvider';

const currentChildSelector = state => state.study.currentChild;
const currentStudySelector = state => state.study.currentStudy;

export const Consent = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentChild = useSelector(currentChildSelector);
  const currentStudy = useSelector(currentStudySelector);
  const [loading, setLoading] = useState(false);
  const [isConsentButtonDisabled, setIsConsentButtonDisabled] = useState(true);
  const {apiErrorMsg, clearApiError, handleApiError} = useErrorProvider();

  useEffect(() => {
    if (apiErrorMsg) {
      toast.error(apiErrorMsg);
      clearApiError();
    }
  }, [apiErrorMsg]);

  const onConsentCheckboxSelected = () => {
    setIsConsentButtonDisabled(!isConsentButtonDisabled);
  };

  const onSave = () => {
    setLoading(true);
    dispatch(createChildEnrollment({childId: currentChild.id, studyId: currentStudy.id, enrolledToStudyAt: Date()}))
      .then(action => {
        if (createChildEnrollment.fulfilled.match(action)) {
          navigate(ROUTES.TEMPLETON_STUDY_INTRO);
          toast.success('Enrolled successfully!');
        }
        else {
          handleApiError({action, message: 'Unable to enroll into the study.'});
        }
      }).catch(error => {
        handleApiError({error, message: 'Error when enrolling into the Templeton study.'});
      }).finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className='grid-x align-center'>
      <div className='small-12 medium-10 large-6'>
        <div className='padding-vertical-1 padding-horizontal-2'>
          <button
            className='button primary small hollow'
            onClick={() => navigate(ROUTES.TEMPLETON_STUDY_INTRO)}
            type='button'
          >
            Back
          </button>
          <h1>{STUDIES_TITLE.TEMPLETON}</h1>
          <p>
            In this study we are interested in learning about how activities that promote 3- and 4-year-old children’s
            tendency to consider other people’s needs may benefit their prosocial behaviour (their ability to do things
            that will benefit other people). Your child will complete 3 different activities over a one-week period;
            you will receive reminder notifications when it is time to do a particular activity and you will have
            48 hours to complete the activity with your child. Two of these activities will consist of storybooks
            that you read to your child and record their responses to key questions by touching a relevant button
            on the screen. The third will consist of a puppet show that your child will watch; at certain times the
            narrator will direct questions toward your child to answer.
          </p>
          <p>
            During your visit to our lab, you were provided with a consent form to review and sign. Please check the
            box below confirming you acknowledge having reviewed and signed the form.
          </p>
          <div>
            <input
              id='consent'
              onChange={() => onConsentCheckboxSelected()}
              type='checkbox'
            />
            <label
              className='margin-right-0'
              htmlFor='consent'>
              I have read the Consent Form and agree to the terms.
            </label>
          </div>
        </div>
        {loading && <div className='loader-wrapper'><div className='loader'/></div>}
        {!loading &&
          <div className='sticky-consent bg-light-beige'>
            <div className='width-100 padding-2 consent-button'>
              <div className='grid-x align-center'>
                <button
                  className='button primary expanded margin-0'
                  disabled={isConsentButtonDisabled}
                  onClick={() => onSave()}
                  type='button'>I provide consent
                </button>
              </div>
            </div>
          </div>
        }
      </div>
    </div>
  );
};

import React from 'react';
import Footer from '../../common/Footer';
import {Resource} from './resource';
import {CHILD_IMAGES} from '../../../assets/images';
import data from './resources.json';

export const Resources = () =>
  <div>
    <div className='grid-container padding-0'>
      <div className='padding-vertical-1 padding-horizontal-2'>
        <h1>Resources</h1>
      </div>
      <div className='padding-vertical-1 padding-horizontal-2'>
        {data.resources.map(resource => <Resource
          description={resource.description}
          image={`${CHILD_IMAGES[resource.image]}`}
          key={resource.id}
          title={resource.title}
          url={resource.url}/>)}
      </div>
    </div>
    <Footer/>
  </div>;

import React from 'react';
import PropTypes from 'prop-types';

const Modal = ({show, onClose, children}) => {
  if (!show) {
    return null;
  }

  return (
    <div
      className='modal-wrapper'
      onClick={onClose}>
      <div
        className='modal'
        onClick={e => e.stopPropagation()}>
        {children}
      </div>
    </div>
  );
};

export default Modal;

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired
};

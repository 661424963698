import React, {useState, useEffect} from 'react';
import {useNavigate} from 'react-router';
import ROUTES from '../../../../routes';
import {useDispatch, useSelector} from 'react-redux';
import {createActivityProgress, getChildSelectedActivityStatus} from '../slice/templetonSlice';
import {toast} from 'react-toastify';
import {isEmpty} from 'lodash';
import content from './content.json';
import useErrorProvider from '../../../common/useErrorProvider';
import 'plyr/src/sass/plyr.scss';
import Plyr from 'plyr';

const currentChildSelector = state => state.study.currentChild;
const currentActivitySelector = state => state.templetonStudy.currentActivity;

export const ActivityInfo = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const child = useSelector(currentChildSelector);
  const activity = useSelector(currentActivitySelector);
  const [activityProgress, setActivityProgress] = useState([]);
  const [loading, setLoading] = useState(false);
  const [continueButtonDisabled, setContinueButtonDisabled] = useState(true);
  const {apiErrorMsg, clearApiError, handleApiError} = useErrorProvider();

  useEffect(() => {
    if (apiErrorMsg) {
      toast.error(apiErrorMsg);
      clearApiError();
    }
  }, [apiErrorMsg]);

  const apiGetChildActivityStatus = () => {
    setLoading(true);
    dispatch(getChildSelectedActivityStatus({childId: child.id, activityId: activity.id}))
      .then(action => {
        if (getChildSelectedActivityStatus.fulfilled.match(action)) {
          setActivityProgress(action.payload.details[0]);
        }
        else {
          handleApiError({action, message: 'Unable to get child activity status.'});
        }
      }).catch(error => {
        handleApiError({error, message: 'Error when fetching child activity status in templeton activity info.'});
      }).finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    setLoading(true);

    new Plyr('#player', {
      controls: ['play-large', 'progress', 'current-time'],
      fullscreen: {enabled: false, fallback: false, iosNative: false},
      invertTime: false
    });

    apiGetChildActivityStatus();
  }, []);

  const onBack = () => {
    navigate(ROUTES.TEMPLETON_STUDY_ACTIVITIES);
  };

  const onActivityStart = () => {
    setLoading(true);
    dispatch(createActivityProgress({childId: child.id, activityId: activity.id, startedAt: Date()}))
      .then(action => {
        if (createActivityProgress.fulfilled.match(action)) {
          navigate(ROUTES.TEMPLETON_STUDY_ACTIVITY_VIDEO, {
            state: {nextSegmentPage: 0}
          });
        }
        else {
          handleApiError({action, message: 'Unable to start a new activity.'});
        }
      }).catch(error => {
        handleApiError({error, message: 'Error when saving activity progress for the Templeton study.'});
      }).finally(() => {
        setLoading(false);
      });
  };

  const onActivityContinue = () => {
    if (isEmpty(activityProgress.progress)) {
      navigate(ROUTES.TEMPLETON_STUDY_ACTIVITY_VIDEO, {
        state: {nextSegmentPage: 0}
      });
      return;
    }

    const activityStoppedOnPage = activityProgress.progress[activityProgress.progress.length - 1].page;

    for (let i = activityStoppedOnPage + 1; i < content.videos[content.videos.length - 1].id; i++) {
      if (content.videos[i].allowPause) {
        navigate(ROUTES.TEMPLETON_STUDY_ACTIVITY_VIDEO, {
          state: {nextSegmentPage: i}
        });
        break;
      }
    }
  };

  return (
    <div className='grid-x align-center'>
      <div className='small-12 medium-10 large-7'>
        <div className='padding-vertical-1 padding-horizontal-2'>
          <button
            className='button primary small hollow'
            onClick={() => onBack()}
            type='button'
          >
            Back
          </button>
          <h1>Activity Tutorial</h1>
          <p>
            Watch the video tutorial below to help guide you through the Activity on the next page!
          </p>
        </div>
        <video
          disablePictureInPicture
          disableRemotePlayback
          id='player'
          onEnded={() => setContinueButtonDisabled(false)}
          playsInline
          src='https://pub-98b9233f39024486a747dc9faf6888ad.r2.dev/sshrc_allintroclip%20(1080p).mp4'
          style={{'--plyr-video-background': 'rgba(#ffffff, 0)'}}/>
        <div className='sticky-consent bg-mid-beige'>
          <div className='padding-2 consent-button'>
            <div className='grid-x align-center'>
              {loading && <div className='loader-wrapper'><div className='loader'/></div>}
              {!loading &&
                <>
                  {isEmpty(activityProgress)
                    ? <button
                      className='button primary expanded margin-0'
                      disabled={continueButtonDisabled}
                      onClick={() => onActivityStart()}
                      type='button'
                    >
                      Ok, let&apos;s start!
                    </button>
                    : <button
                      className='button primary expanded margin-0'
                      onClick={() => onActivityContinue()}
                      type='button'
                    >
                      Continue
                    </button>
                  }
                </>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

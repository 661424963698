import React from 'react';
import {Routes, Route} from 'react-router-dom';
import HelmetWrapper from '../HelmetWrapper';
import ROUTES from '../../../routes';
import Home from '../../features/home';
import {Studies} from '../../features/studies';
import {Resources} from '../../features/resources';
import {Profile} from '../../features/profile';
import {PhoneVerification} from '../../features/phoneVerification';
import {Templeton} from '../../features/templeton';
import {Sshrc} from '../../features/sshrc';
import PageNotFound from '../../features/PageNotFound';
import {Info as TempletonStudyInfo} from '../../features/templeton/info';
import {Info as SshrcStudyInfo} from '../../features/sshrc/info';
import {Consent as TempletonStudyConsent} from '../../features/templeton/consent';
import {Consent as SshrcStudyConsent} from '../../features/sshrc/consent';
import AddChildren from '../../features/profile/addChildren';
import {Activities as TempletonActivities} from '../../features/templeton/activities';
import {Activities as SshrcActivities} from '../../features/sshrc/activities';
import {ActivityInfo} from '../../features/templeton/puppet_show/activityInfo';
import {Index as SshrcActivityInfo} from '../../features/sshrc/activity';
import {Activity as TempletonActivity} from '../../features/templeton/puppet_show/activity';
import {Activity as SshrcActivity} from '../../features/sshrc/activity/activity';
import {ActivityResult as TempletonActivityResults} from '../../features/templeton/puppet_show/activityResult';
import {Storybook1} from '../../features/templeton/storybook/book';
import {ActivityInfo as Storybook1Info} from '../../features/templeton/storybook/bookInfo';
import {Survey as Storybook1Survey} from '../../features/templeton/storybook/survey';
import {Survey as PuppetShowSurvey} from '../../features/templeton/puppet_show/survey';
import {Congratulations} from '../../features/templeton/congratulations';
import {Congratulations as StoryBookCongrats} from '../../features/templeton/storybook/congratulations';
import {Congratulations as PuppetShowCongrats} from '../../features/templeton/puppet_show/congratulations';
import {Congratulations as SshrcCongratulations} from '../../features/sshrc/congratulations';
import {Questionnaire} from '../../features/templeton/questionnaire';

const ProtectedRoutes = () => <>
  <Routes>
    <Route
      element={<HelmetWrapper
        component={Home}
        description='Description'
        title='Home'
      />}
      exact
      path={ROUTES.ROOT}
    />
    <Route
      element={<HelmetWrapper
        component={Profile}
        description='Description'
        title='Profile'/>}
      exact
      path={ROUTES.PROFILE}
    />
    <Route
      element={<HelmetWrapper
        component={AddChildren}
        description='Description'
        title='Add Children'/>}
      exact
      path={ROUTES.CHILDREN}
    />
    <Route
      element={<HelmetWrapper
        component={PhoneVerification}
        description='Description'
        title='Verify Phone Number'/>}
      exact
      path={ROUTES.PHONE_VERIFICATION}
    />
    <Route
      element={<HelmetWrapper
        component={Studies}
        description='Description'
        title='Studies'/>}
      exact
      path={ROUTES.STUDIES}
    />
    <Route
      element={<HelmetWrapper
        component={Templeton}
        description='Description'
        title='The Helping Study'
      />}
      exact
      path={ROUTES.TEMPLETON_STUDY}
    >
      <Route
        element={<HelmetWrapper
          component={TempletonStudyInfo}
          description='Description'
          title='Templeton Intro'/>}
        index
      />
      <Route
        element={<HelmetWrapper
          component={TempletonStudyInfo}
          description='Description'
          title='Templeton Intro'/>}
        exact
        path={ROUTES.TEMPLETON_STUDY_INTRO}
      />
      <Route
        element={<HelmetWrapper
          component={TempletonStudyConsent}
          description='Description'
          title='Templeton Consent'/>}
        exact
        path={ROUTES.TEMPLETON_STUDY_CONSENT}
      />
      <Route
        element={<HelmetWrapper
          component={TempletonActivities}
          description='Description'
          title='The Helping Study Activities'/>}
        exact
        path={ROUTES.TEMPLETON_STUDY_ACTIVITIES}
      />
      <Route
        element={<HelmetWrapper
          component={ActivityInfo}
          description='Description'
          title='Activity Info'/>}
        exact
        path={ROUTES.TEMPLETON_STUDY_ACTIVITY_INFO}
      />
      <Route
        element={<HelmetWrapper
          component={TempletonActivity}
          description='Description'
          title='Activity Video'/>}
        exact
        path={ROUTES.TEMPLETON_STUDY_ACTIVITY_VIDEO}
      />
      <Route
        element={<HelmetWrapper
          component={TempletonActivityResults}
          description='Description'
          title='Activity Results'/>}
        exact
        path={ROUTES.TEMPLETON_STUDY_ACTIVITY_RESULTS}
      />
      <Route
        element={<HelmetWrapper
          component={Congratulations}
          description='Description'
          title='Congratulations'/>}
        exact
        path={ROUTES.TEMPLETON_STUDY_ACTIVITY_CONGRATULATIONS}
      />
      <Route
        element={<HelmetWrapper
          component={Questionnaire}
          description='Description'
          title='Questionnaire'/>}
        exact
        path={ROUTES.TEMPLETON_STUDY_QUESTIONNAIRE}
      />
      <Route
        element={<HelmetWrapper
          component={Storybook1Info}
          description='Description'
          title='Storybook1 Info'/>}
        exact
        path={ROUTES.TEMPLETON_STUDY_STORYBOOK_1_INFO}
      />
      <Route
        element={<HelmetWrapper
          component={Storybook1}
          description='Description'
          title='Storybook1'/>}
        exact
        path={ROUTES.TEMPLETON_STUDY_STORYBOOK_1}
      />
      <Route
        element={<HelmetWrapper
          component={Storybook1Survey}
          description='Description'
          title='Storybook1 Survey'/>}
        exact
        path={ROUTES.TEMPLETON_STUDY_STORYBOOK_1_SURVEY}
      />
      <Route
        element={<HelmetWrapper
          component={StoryBookCongrats}
          description='Description'
          title='Congratulations'/>}
        exact
        path={ROUTES.TEMPLETON_STUDY_STORYBOOK_1_CONGRATULATIONS}
      />
      <Route
        element={<HelmetWrapper
          component={PuppetShowSurvey}
          description='Description'
          title='Puppet Show Survey'/>}
        exact
        path={ROUTES.TEMPLETON_STUDY_PUPPETSHOW_SURVEY}
      />
      <Route
        element={<HelmetWrapper
          component={PuppetShowCongrats}
          description='Description'
          title='Congratulations'/>}
        exact
        path={ROUTES.TEMPLETON_STUDY_PUPPETSHOW_CONGRATULATIONS}
      />
    </Route>
    <Route
      element={<HelmetWrapper
        component={Sshrc}
        description='Description'
        title="The Children's Thinking Study Study"
      />}
      exact
      path={ROUTES.SSHRC_STUDY}
    >
      <Route
        element={<HelmetWrapper
          component={SshrcStudyInfo}
          description='Description'
          title="The Children's Thinking Study Intro"/>}
        index
      />
      <Route
        element={<HelmetWrapper
          component={SshrcStudyInfo}
          description='Description'
          title="The Children's Thinking Study Intro"/>}
        exact
        path={ROUTES.SSHRC_STUDY_INTRO}
      />
      <Route
        element={<HelmetWrapper
          component={SshrcStudyConsent}
          description='Description'
          title="The Children's Thinking Study Consent"/>}
        exact
        path={ROUTES.SSHRC_STUDY_CONSENT}
      />
      <Route
        element={<HelmetWrapper
          component={SshrcActivities}
          description='Description'
          title="The Children's Thinking Study Activities"/>}
        exact
        path={ROUTES.SSHRC_STUDY_ACTIVITIES}
      />
      <Route
        element={<HelmetWrapper
          component={SshrcActivityInfo}
          description='Description'
          title='Activity Info'/>}
        exact
        path={ROUTES.SSHRC_STUDY_ACTIVITY_INFO}
      />
      <Route
        element={<HelmetWrapper
          component={SshrcActivity}
          description='Description'
          title='Activity Video'/>}
        exact
        path={ROUTES.SSHRC_STUDY_ACTIVITY}
      />
      <Route
        element={<HelmetWrapper
          component={SshrcCongratulations}
          description='Congratulations'
          title='Congratulations'/>}
        exact
        path={ROUTES.SSHRC_STUDY_CONGRATULATION}
      />
    </Route>
    <Route
      element={<HelmetWrapper
        component={Resources}
        description='Description'
        title='Resources'/>}
      exact
      path={ROUTES.RESOURCES}
    />
    <Route
      element={<HelmetWrapper
        component={PageNotFound}
        description='Page Not found'
        title='Page Not Found'
      />}
      exact
      path='*'
    />
  </Routes>
</>;

export default ProtectedRoutes;

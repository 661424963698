import {combineReducers} from '@reduxjs/toolkit';
import authReducer from './features/authentication/slice/authSlice';
import profileReducer from './features/profile/slice/profileSlice';
import studyReducer from './features/studies/slice/studySlice';
import templetonReducer from './features/templeton/slice/templetonSlice';
import sshrcReducer from './features/sshrc/slice/sshrcSlice';

const reducers = combineReducers({
  auth: authReducer,
  profile: profileReducer,
  study: studyReducer,
  templetonStudy: templetonReducer,
  sshrcStudy: sshrcReducer
});

const DESTRUCTIVE_ACTIONS = [
  'auth/logout/fulfilled',
  'auth/logout/rejected'
];

const rootReducer = (state, action) => {
  let responseHeaders;

  // If a logout action OR a 401 unauthorized status returned, reset state so user can re-authenticate
  if (DESTRUCTIVE_ACTIONS.includes(action.type) || action.payload?.error?.response?.status === 401) {
    return reducers({}, action);
  }

  if (action.type === 'auth/logout/pending') {
    return reducers({auth: {...state.auth, user: {}, authenticated: false}}, action);
  }

  if (action.payload) {
    responseHeaders = action.payload.headers;
  }
  else if (action.error && action.error.response) {
    responseHeaders = action.error.response.headers;
  }

  if (responseHeaders) {
    const headers = {
      'access-token':
        responseHeaders['access-token'] ||
        state.auth.headers['access-token'],
      client: responseHeaders.client || state.auth.headers.client,
      expiry: responseHeaders.expiry || state.auth.headers.expiry,
      uid: responseHeaders.uid || state.auth.headers.uid
    };

    return reducers({...state, auth: {...state.auth, headers}}, action);
  }

  return reducers(state, action);
};

export default rootReducer;

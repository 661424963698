import React from 'react';
import {PAGE_NOT_FOUND_IMAGES} from '../../../assets/images';
import {Link} from 'react-router-dom';
import ROUTES from '../../../routes';

const Index = () => <div className='grid-container'>
  <div className='grid-x align-center text-center small-up-10'>
    <div
      className='cell auto shrink'>
      <div className='margin-vertical-3'>
        <h1 className='font-bold'>404</h1>
        <p>Whoops! something went wrong, the page you are looking for is not found.</p>
        <p>
          <Link
            className='button small primary hollow'
            to={ROUTES.ROOT}
          >Home</Link>
        </p>
      </div>
      <img
        src={PAGE_NOT_FOUND_IMAGES.FOX_AND_A_BIN}
        width='60%'/>
    </div>
  </div>
</div>;

export default Index;

import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import {AVATARS} from '../../../assets/images';
import {withdrawChildEnrollment} from './slice/templetonSlice';
import {toast} from 'react-toastify';
import {useNavigate} from 'react-router';
import ROUTES from '../../../routes';
import useErrorProvider from '../../common/useErrorProvider';

const currentChildSelector = state => state.study.currentChild;
const currentStudyEnrollmentSelector = state => state.templetonStudy.enrollment;

export const WithdrawFromStudy = ({onClose}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentChild = useSelector(currentChildSelector);
  const currentEnrollment = useSelector(currentStudyEnrollmentSelector);
  const [loading, setLoading] = useState(false);
  const {apiErrorMsg, clearApiError, handleApiError} = useErrorProvider();

  useEffect(() => {
    if (apiErrorMsg) {
      toast.error(apiErrorMsg);
      clearApiError();
    }
  }, [apiErrorMsg]);

  const requestData = {
    enrollmentId: currentEnrollment.id,
    childId: currentChild.id,
    withdrawFromStudyAt: Date()
  };

  const onWithdraw = () => {
    setLoading(true);
    dispatch(withdrawChildEnrollment(requestData))
      .then(action => {
        if (withdrawChildEnrollment.fulfilled.match(action)) {
          toast.success('Successfully withdrawn.');
          onClose();
          navigate(ROUTES.STUDIES);
        }
        else {
          handleApiError({action, message: 'Unable to withdraw from the study.'});
        }
      }).catch(error => {
        handleApiError({error, message: 'Error when withdrawing from the Templeton study.'});
      }).finally(() => {
        setLoading(false);
      });
  };

  return (
    <section>
      <div className='padding-2'>
        <h1>Opt-Out</h1>
        <p>Please confirm that you wish to opt the following child out of future studies:</p>
      </div>
      <div className='bg-mid-beige padding-horizontal-2 padding-vertical-1 margin-bottom-2 flex-row'>
        <img
          alt='avatar'
          className='margin-right-2'
          src={AVATARS.OWL}
        />
        <div>
          <p className='margin-bottom-0'>{currentChild.firstName} {currentChild.lastName}</p>
          <p className='margin-bottom-0'>{currentChild.birthDate}</p>
        </div>
      </div>
      {loading && <div className='loader-wrapper'><div className='loader'/></div>}
      {!loading &&
        <div className='grid-x margin-bottom-1'>
          <button
            className='cell auto margin-horizontal-3 button primary hollow'
            onClick={onClose}
            type='button'
          >No
          </button>
          <button
            className='cell auto margin-horizontal-3 button primary'
            onClick={() => onWithdraw()}
            type='button'
          >Yes
          </button>
        </div>
      }
    </section>
  );
};

WithdrawFromStudy.propTypes = {
  onClose: PropTypes.func.isRequired
};

import React, {useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {Link} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router';
import ROUTES from '../../../routes';
import {login} from './slice/authSlice';
import {SIGN_IN} from '../../../assets/images';
import useErrorProvider from '../../common/useErrorProvider';
import {toast} from 'react-toastify';

export const SignIn = () => {
  const {
    register,
    handleSubmit,
    formState: {errors}
  } = useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const searchParams = new URLSearchParams(location.search);
  const confirmationSuccess = searchParams.get('account_confirmation_success');
  const {apiErrorMsg, clearApiError, handleApiError} = useErrorProvider();

  useEffect(() => {
    if (confirmationSuccess) {
      if (confirmationSuccess === 'true') {
        toast.success('Account confirmation was successful! You can now sign in to the app.', {autoClose: 5000});
      }
      else if (confirmationSuccess === 'false') {
        toast.error('Account confirmation failed! Please try resending the confirmation email.', {autoClose: 5000});
      }

      navigate(ROUTES.SIGN_IN, {replace: true});
    }
  }, [confirmationSuccess]);

  const onSubmit = data => {
    setLoading(true);
    clearApiError();
    dispatch(login(data)).then(action => {
      if (login.fulfilled.match(action)) {
        if (action.payload.user.phoneVerified) {
          navigate(ROUTES.ROOT);
        }
        else {
          navigate(ROUTES.PHONE_VERIFICATION);
        }
      }
      else {
        handleApiError({action});
      }
    }).catch(error => {
      handleApiError({error, message: 'Error when logging in.'});
    }).finally(() => {
      setLoading(false);
    });
  };

  return (
    <>
      <div className='grid-x align-center'>
        <div className='small-10 medium-6 large-4 cell padding-bottom-2'>
          <section>
            <div
              className='padding-vertical-2'
              style={{
                backgroundImage: `url(${SIGN_IN.BIRD})`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'right center'
              }}>
              <h1>Welcome</h1>
            </div>
            {loading && <div className='loader-wrapper'><div className='loader'/></div>}

            <div className='form-card-wrapper'>
              {!loading &&
              <form
                onSubmit={handleSubmit(onSubmit)}>
                {apiErrorMsg && <div className='alert callout'>{apiErrorMsg}</div>}
                <label>Email Address
                  <input
                    className='form-card'
                    id='email'
                    type='email'
                    {...register('email', {required: true})}
                  />
                  {errors.email && <p className='error'>Email is required.</p>}
                </label>
                <label>Password
                  <input
                    className='form-card'
                    id='Password'
                    type='password'
                    {...register('password', {required: true})}
                  />
                  {errors.password && <p className='error'>Password is required.</p>}
                </label>
                <button
                  className='button primary expanded'
                  type='submit'>Submit
                </button>
                <div className='grid-x align-right margin-vertical-2'>
                  <div className='cell auto shrink'>
                    <Link
                      className='alert-color'
                      to={ROUTES.FORGET_PASSWORD}
                    >Forget your Password?</Link><br/>
                    <Link
                      className='alert-color'
                      to={ROUTES.RESEND_CONFIRMATION}
                    >Resend Confirmation</Link>
                  </div>
                </div>
              </form>
              }
              <h1 className='.sub-title-2 padding-bottom-1' >First time here?</h1>
              <Link
                className='button primary hollow expanded'
                to={ROUTES.REGISTER}
              >Register Now</Link>
            </div>
          </section>
        </div>
      </div>
      <img
        alt='Bird Tracks'
        src={SIGN_IN.BIRD_TRACKS}
        style={{
          position: 'absolute',
          bottom: '0',
          zIndex: '-1'
        }}
      />
    </>
  );
};

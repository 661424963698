import React from 'react';
import PropTypes from 'prop-types';

export const Resource = ({image, title, description, url}) =>
  <div className='grid-x padding-1 resource-card'>
    <div className='cell shrink auto padding-right-2'>
      <img
        alt='profile image'
        src={image}
        width='80px'/>
    </div>
    <div className='cell auto'>
      <h2 style={{width: '30ch'}}>
        {title}
      </h2>
      <p>
        {description}
      </p>
      <a
        className='button primary'
        href={url}
        rel='noreferrer'
        target='_blank'
        type='button'
      >Read more &gt;
      </a>
    </div>
  </div>;

Resource.propTypes = {
  description: PropTypes.string,
  image: PropTypes.string,
  title: PropTypes.string,
  url: PropTypes.string
};

/* eslint-disable global-require */
import React, {useEffect, useRef, useState} from 'react';
import {useReactMediaRecorder} from 'react-media-recorder';
import {useNavigate} from 'react-router';
import parse from 'html-react-parser';
import ROUTES from '../../../../routes';
import book1 from './storyBook1/storyBook1.json';
import book2 from './storyBook2/storyBook2.json';
import {toast} from 'react-toastify';
import {useDispatch, useSelector} from 'react-redux';
import {
  completeChildSelectedActivity,
  updateChildSelectedActivityStatus,
  uploadChildRecording
} from '../slice/templetonSlice';
import {useLocation} from 'react-router-dom';
import useErrorProvider from '../../../common/useErrorProvider';
import logger from 'loglevel';
import {useRollbar} from '@rollbar/react';

const currentChildSelector = state => state.study.currentChild;
const currentActivitySelector = state => state.templetonStudy.currentActivity;
const currentActivityProgressSelector = state => state.templetonStudy.currentActivityProgress;

const INACTIVITY_COUNTDOWN_IN_MS = 120000;
const FORCED_EXIT_COUNTDOWN_IN_MS = 60000;
const INACTIVITY_WARNING_DURATION_IN_MS = FORCED_EXIT_COUNTDOWN_IN_MS;
const BYTES_IN_MEGABYTE = 1000000;

export const Storybook1 = () => {
  // third party library hooks
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const rollbar = useRollbar();

  // timers
  const inactivityWarningTimerRef = useRef(null);
  const forcedExitTimerRef = useRef(null);

  const warningToastId = useRef(null);

  const child = useSelector(currentChildSelector);
  const activity = useSelector(currentActivitySelector);
  const activityProgress = useSelector(currentActivityProgressSelector);
  const isFirstStory = activity.slug === 'story_book_1';
  const content = activity.slug === 'story_book_1' ? book1 : book2;
  const [currentPage, setCurrentPage] = useState(location.state.nextSegmentPage);
  const [isRecording, setIsRecording] = useState(false);
  const isLastPage = currentPage === content.pages.length - 1;
  const [pageStartAt, setPageStartAt] = useState('');

  const {apiErrorMsg, clearApiError, handleApiError} = useErrorProvider();

  useEffect(() => {
    if (apiErrorMsg) {
      toast.error(apiErrorMsg);
      clearApiError();
    }
  }, [apiErrorMsg]);

  const clearAllTimers = () => {
    clearTimeout(forcedExitTimerRef.current);
    clearTimeout(inactivityWarningTimerRef.current);
    logger.debug('Cleared all active timers');
  };

  const dismissInactivityToast = () => {
    if (warningToastId.current) {
      toast.dismiss(warningToastId.current);
      logger.debug('Dismissed the inactivity warning toast message');
    }
  };

  useEffect(() => {
    logger.debug('Component is mounted, performing initialization tasks');

    return () => {
      logger.debug('Component is unmounting, performing cleanup tasks. If you are seeing this message immediately ' +
          'after mounting the component, this means you are running in React Strict Mode. This is expected behaviour.');

      dismissInactivityToast();
      clearAllTimers();
    };
  }, []);

  const handleStartRecording = () => {
    logger.info('Video / audio recording has started');
  };

  const handleStopRecording = (blobUrl, blob) => {
    logger.info('Video / audio recording has stopped');
    logger.debug('The recording blob size is', blob.size / BYTES_IN_MEGABYTE, 'MB');
    const uploadStartedAt = Date.now();
    const videoFile = new File([blob], 'video.mp4', {type: 'video/mp4'});
    const formData = new FormData();
    formData.append('recording', videoFile);
    formData.append('child_id', child.id);

    if (blob.size > 0) {
      logger.info('Uploading the recording to the cloud');
      const toastId = toast.loading('Uploading recording...', {position: toast.POSITION.BOTTOM_RIGHT});
      dispatch(uploadChildRecording({
        activityProgressId: activityProgress.id,
        formData: formData
      }))
        .then(action => {
          if (uploadChildRecording.fulfilled.match(action)) {
            const uploadCompletedAt = Date.now();
            const timeTaken = uploadCompletedAt - uploadStartedAt;
            toast.update(toastId, {render: 'Recording uploaded!', type: 'success', isLoading: false, autoClose: 500});
            logger.info('Uploaded', blob.size / BYTES_IN_MEGABYTE, 'MB to the cloud in', timeTaken / 1000, 'seconds');
          }
          else {
            toast.update(toastId, {render: 'Upload failed!', type: 'error', isLoading: false, autoClose: 500});
            handleApiError({action, message: 'Failed to upload story book recording.'});
          }
        }).catch(error => {
          toast.update(toastId, {render: 'Upload failed!', type: 'error', isLoading: false, autoClose: 500});
          handleApiError({error, message: 'Error when uploading a Templeton story book recording.'});
        });
    }
    else {
      rollbar.error('Recording completed with a blob size of 0');
      logger.error('Recording completed with a blob size of 0');
    }
  };

  const {startRecording, stopRecording, pauseRecording, resumeRecording} = useReactMediaRecorder({
    video: true,
    askPermissionOnMount: true,
    blobPropertyBag: {type: 'video/mp4'},
    onStart: handleStartRecording,
    onStop: handleStopRecording
  });

  const handleComplete = () => {
    dispatch(completeChildSelectedActivity({
      childId: child.id, activityProgressId: activityProgress.id, completedAt: Date()
    })).then(action => {
      if (completeChildSelectedActivity.fulfilled.match(action)) {
        stopRecording();
        navigate(ROUTES.TEMPLETON_STUDY_STORYBOOK_1_CONGRATULATIONS);
      }
      else {
        handleApiError({action, message: 'Unable to complete story book activity.'});
      }
    }).catch(error => {
      handleApiError({error, message: 'Error when completing the Templeton story book activity.'});
    });
  };

  const handleContinue = (goTo, buttonText) => {
    dismissInactivityToast();
    clearAllTimers();
    stopRecording();

    const activityPageDetails = {
      activity_array_idx: currentPage,
      page: currentPage,
      question: content.pages[currentPage].text,
      answer: buttonText,
      page_started_at: pageStartAt,
      page_completed_at: Date()
    };

    const tempProgressArr = activityProgress.progress;

    const data = {
      childId: child.id,
      activityProgressId: activityProgress.id,
      progress: [...tempProgressArr, activityPageDetails]
    };

    dispatch(updateChildSelectedActivityStatus(data))
      .then(action => {
        if (!updateChildSelectedActivityStatus.fulfilled.match(action)) {
          handleApiError({action, message: 'Unable to update child activity.'});
        }
        else if (isLastPage) {
          handleComplete();
        }
        else {
          setCurrentPage(goTo);
          setIsRecording(false);
        }
      }).catch(error => {
        handleApiError({error, message: 'Error when updating child activities for the Templeton story book.'});
      });
  };

  const handleToggleRecording = () => {
    if (isRecording) {
      resumeRecording();
      toast.success('Recording resumed.', {autoClose: 1000});
    }
    else {
      pauseRecording();
      toast.success('Recording paused.', {autoClose: 1000});
    }

    setIsRecording(!isRecording);
  };

  const handleForcedExit = () => {
    let lastSegment;

    for (let i = content.pages.length - 1; i > 0; i--) {
      if (content.pages[currentPage].allowPause) {
        lastSegment = i;
      }
    }

    if (content.pages[currentPage].id >= lastSegment) {
      handleComplete();
    }
    else {
      handleContinue();
      navigate(ROUTES.ROOT);
    }
  };

  const startForcedExitTimer = () => {
    logger.debug('Starting the forced exit countdown of', FORCED_EXIT_COUNTDOWN_IN_MS / 1000, 'seconds');
    forcedExitTimerRef.current = setTimeout(handleForcedExit, FORCED_EXIT_COUNTDOWN_IN_MS);
  };

  const handleInactivityWarningTimerElapsed = () => {
    warningToastId.current = toast.warning('Are you still there? Please make a selection to continue.',
      {autoClose: INACTIVITY_WARNING_DURATION_IN_MS});
    startForcedExitTimer();
  };

  useEffect(() => {
    if (content.pages[currentPage].id >= 0) {
      logger.info('Transitioned to page:', content.pages[currentPage].title || '(untitled)',
        `(id: ${content.pages[currentPage].id})`);
      setPageStartAt(Date());
      startRecording();
      logger.debug('Starting the inactivity warning countdown of', INACTIVITY_COUNTDOWN_IN_MS / 1000, 'seconds');
      inactivityWarningTimerRef.current = setTimeout(handleInactivityWarningTimerElapsed, INACTIVITY_COUNTDOWN_IN_MS);
    }
  }, [currentPage]);

  return (
    <div className='grid-x align-center'>
      <div className='small-12 medium-10 large-6 align-center'>
        <div className='book-page-container'>
          {content.pages[currentPage].allowPause &&
            <button
              className='book-page-pause-button button primary margin-0'
              onClick={handleToggleRecording}
              type='button'
            >{isRecording ? 'Pause' : 'Play'}</button>
          }
          {content.pages[currentPage].title &&
            <div className='margin-vertical-1'>
              <h1>{content.pages[currentPage].title}</h1>
            </div>
          }
          {content.pages[currentPage].image &&
            <div className='book-page-image'>
              <img
                alt='Book image'
                src={require(`./${isFirstStory
                  ? 'storyBook1/storybook1 Images'
                  : 'storyBook2/storybook2 Images'}/${content.pages[currentPage].image}`)}/>
            </div>
          }
          <div className='book-page-content'>
            <div className='padding-1'>
              <p className='body-4'>{parse(content.pages[currentPage].text)}</p>
            </div>
          </div>
          <div className='book-page-actions margin-top-2'>
            {content.pages[currentPage].buttons.map(button =>
              <button
                className='button large primary margin-horizontal-1'
                key={button.text}
                onClick={() => handleContinue(button.goTo, button.text)}
                type='button'
              >
                {button.text}
              </button>)}
          </div>
        </div>
      </div>
    </div>
  );
};

/* eslint camelcase: 0 */
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import * as ENDPOINTS from '../../../../config/endpoints';
import {axiosCall, responseFormat} from '../../../../config/axios';
import {v4 as uuidv4} from 'uuid';

export const initialState = {
  authenticated: false,
  user: {},
  headers: {}
};

export const registerUser = createAsyncThunk('auth', (userDetails, {rejectWithValue}) => {
  try {
    return axiosCall('post', ENDPOINTS.AUTH.REGISTER,
      {
        participant_id: uuidv4(),
        first_name: userDetails.parent_first_name,
        last_name: userDetails.parent_last_name,
        email: userDetails.email,
        password: userDetails.password,
        password_confirmation: userDetails.password_confirmation,
        time_zone: userDetails.time_zone,
        confirm_success_url: `${window.location.origin}/sign_in`,
        postal_code: userDetails.postal_code,
        terms_of_use_accepted_at: userDetails.terms_of_use_accepted_at,
        privacy_policy_accepted_at: userDetails.privacy_policy_accepted_at
      })
      .then(response => responseFormat(response, {user: response.data.data}))
      .catch(error => rejectWithValue({error}));
  }
  catch (error) {
    return rejectWithValue({error});
  }
});

export const login = createAsyncThunk('auth/login', (userDetails, {rejectWithValue}) => {
  try {
    return axiosCall('post', ENDPOINTS.AUTH.SIGN_IN,
      {
        email: userDetails.email,
        password: userDetails.password
      })
      .then(response => responseFormat(response, {user: response.data.data}))
      .catch(error => rejectWithValue({error}));
  }
  catch (error) {
    return rejectWithValue({error});
  }
});

export const logout = createAsyncThunk('auth/logout', (_, {rejectWithValue}) => {
  try {
    return axiosCall('get', ENDPOINTS.AUTH.SIGN_OUT, {});
  }
  catch (error) {
    return rejectWithValue({error});
  }
});

export const forgotPassword = createAsyncThunk('auth/forgot_password', (userDetails, {rejectWithValue}) => {
  try {
    return axiosCall('post', ENDPOINTS.AUTH.FORGOT_PASSWORD,
      {
        email: userDetails.email,
        redirect_url: `${window.location.origin}/reset_password` // eslint-disable-line camelcase
      })
      .then(response => responseFormat(response, {user: response.data.data}))
      .catch(error => rejectWithValue({error}));
  }
  catch (error) {
    return rejectWithValue({error});
  }
});

export const resetPassword = createAsyncThunk('auth/reset_password', (userDetails, {rejectWithValue}) => {
  try {
    return axiosCall('put', ENDPOINTS.AUTH.RESET_PASSWORD,
      {
        password: userDetails.password,
        password_confirmation: userDetails.password, // eslint-disable-line camelcase
        redirect_url: `${window.location.origin}/sign_in` // eslint-disable-line camelcase
      })
      .then(response => responseFormat(response, {user: response.data.data}))
      .catch(error => rejectWithValue({error}));
  }
  catch (error) {
    return rejectWithValue({error});
  }
});

export const resendConfirmation = createAsyncThunk('auth/resend_confirmation', (userDetails, {rejectWithValue}) => {
  try {
    return axiosCall('post', ENDPOINTS.AUTH.RESEND_CONFIRMATION,
      {
        email: userDetails.email,
        redirect_url: `${window.location.origin}/sign_in` // eslint-disable-line camelcase
      })
      .then(response => responseFormat(response, {user: response.data.data}))
      .catch(error => rejectWithValue({error}));
  }
  catch (error) {
    return rejectWithValue({error});
  }
});

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setResetPasswordHeaders: (state, payload) => {
      state.headers = {
        'access-token': payload.payload.accessToken,
        client: payload.payload.client,
        uid: payload.payload.uid,
        token: payload.payload.token,
        expiry: payload.payload.expiry
      };
    }
  },
  extraReducers: builder => {
    builder.addCase(login.fulfilled, (state, action) => {
      state.user = action.payload.user;
      state.authenticated = true;
    });
    builder.addCase(login.rejected, state => {
      state.user = {};
    });
  }
});

export const {setResetPasswordHeaders} = authSlice.actions;

export default authSlice.reducer;

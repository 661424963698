import React from 'react';
import {STUDIES_IMAGES} from '../../../../assets/images';
import ROUTES from '../../../../routes';
import {useNavigate} from 'react-router';

export const Congratulations = () => {
  const navigate = useNavigate();

  return (
    <>
      <div className='grid-x align-center'>
        <div className='small-12 medium-10 large-6'>
          <div className='padding-vertical-3 padding-horizontal-2 bg-white'>
            <h1>Congratulations!</h1>
            <div className='grid-x align-center margin-vertical-3'>
              <img
                alt='Congratulations Trophy'
                src={STUDIES_IMAGES.TROPHY_2}
              />
            </div>
          </div>
          <div className='padding-2'>
            <p className='sub-title-1'>You completed the Puppet Show Activity!</p>
            <p>
              Please see your locked Activities list to view when the next Activity
              will become available to you.
            </p>
            <button
              className='button primary expanded margin-0 margin-top-3'
              onClick={() => navigate(ROUTES.TEMPLETON_STUDY_STORYBOOK_1_SURVEY)}
              type='button'
            >
              Continue
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

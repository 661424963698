import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {useNavigate} from 'react-router';
import {capitalize, isEmpty} from 'lodash';
import {toast} from 'react-toastify';
import {STUDIES_IMAGES, ICONS} from '../../../assets/images';
import ROUTES from '../../../routes';
import Footer from '../../common/Footer';
import {setCurrentChild, setCurrentStudy} from './slice/studySlice';
import {getChildAge} from '../../common/utils';
import {getChildTempletonEnrollment} from '../templeton/slice/templetonSlice';
import Modal from '../../common/Modal';
import {STUDIES_SLUG, STUDIES_TITLE} from '../../constants';
import {getChildSshrcEnrollment} from '../sshrc/slice/sshrcSlice';
import {userDetails} from '../profile/slice/profileSlice';
import Avatar from '../../common/avatar.js';
import useErrorProvider from '../../common/useErrorProvider';

const userDetailsSelector = state => state.profile.details;

export const Studies = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector(userDetailsSelector);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const {apiErrorMsg, clearApiError, handleApiError} = useErrorProvider();

  useEffect(() => {
    if (apiErrorMsg) {
      toast.error(apiErrorMsg);
      clearApiError();
    }
  }, [apiErrorMsg]);

  useEffect(() => {
    setLoading(true);
    clearApiError();
    dispatch(userDetails())
      .then(action => {
        if (!userDetails.fulfilled.match(action)) {
          handleApiError({action});
        }
      }).catch(error => {
        handleApiError({error, message: 'Error when fetching user details from studies page.'});
      }).finally(() => {
        setLoading(false);
      });
  }, []);

  const onStudySelected = (child, study) => {
    setLoading(true);
    dispatch(study.slug === STUDIES_SLUG.TEMPLETON
      ? getChildTempletonEnrollment({childId: child.id})
      : getChildSshrcEnrollment({childId: child.id}))
      .then(action => {
        if (!getChildTempletonEnrollment.fulfilled.match(action) && !getChildSshrcEnrollment.fulfilled.match(action)) {
          handleApiError({action, message: 'Unable to load study.'});
        }
        else if (isEmpty(action.payload.details)) {
          dispatch(setCurrentChild(child));
          dispatch(setCurrentStudy(study));
          navigate(study.slug === STUDIES_SLUG.TEMPLETON ? ROUTES.TEMPLETON_STUDY_INTRO : ROUTES.SSHRC_STUDY_INTRO);
        }
        else if (isEmpty(action.payload.details[0].withdrawFromStudyAt) &&
          !isEmpty(action.payload.details[0].enrolledToStudyAt)) {
          dispatch(setCurrentChild(child));
          dispatch(setCurrentStudy(study));
          navigate(study.slug === STUDIES_SLUG.TEMPLETON
            ? ROUTES.TEMPLETON_STUDY_ACTIVITIES : ROUTES.SSHRC_STUDY_ACTIVITIES);
        }
        else {
          setShowModal(true);
        }
      })
      .catch(error => {
        handleApiError({error, message: 'Error when fetching studies.'});
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const sshrcDescription = <div>
    <p>
        In this study we seek to learn about core cognitive abilities in preschool age children.
        Your child will participate in a series of tasks, that are designed as child-friendly games,
        to allow us to learn about preschool age children’s language development, memory, their ability to
        think flexibly, and their ability to understand people’s behavior.
        The entire set of tasks should take no more than 20 minutes.
    </p>
  </div>;

  const templetonDescription = <div>
    <p>
        In this study we are interested in learning about how activities that promote 3- and 4-year-old children’s
        tendency to consider other people’s needs may benefit their prosocial behavior (their ability to do
        things that will benefit other people).
    </p>
    <p>
        Your child will complete 3 different activities over a
        one-week period; you will receive reminder notifications when it is time to do a particular activity
        and you will have 48 hours to complete the activity with your child. Two of these activities will
        consist of storybooks that you read to your child and record their responses to key questions by
        touching a relevant button on the screen. The third will consist of a puppet show that your child
        will watch; at certain times the narrator will direct questions toward your child to answer.
    </p>
  </div>;

  return (
    <>
      <div className='grid-container padding-0'>
        <div className='grid-x align-center'>
          <div className='small-12 medium-10 large-6 custom-padding'>
            <div
              className='study-hero content-center-aligned'
              style={{
                backgroundImage: `url(${STUDIES_IMAGES.GROWTH})`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'right center'
              }}
            >
              <div className='padding-vertical-1 padding-horizontal-2'>
                <h1>Studies</h1>
                <p className='width-75'>
                  Rosie is an app that connects families and researchers, all for the purpose of further understanding
                  child development and maximizing children’s well-being.
                </p>
                <p className='width-75'>
                  Rosie studies allow families to learn about child-friendly studies that seek to understand
                  children’s cognitive and social development, participate in those studies from their smart phone
                  or tablet, and to learn about the science of child development.
                </p>
              </div>
            </div>
            {loading && <div className='loader-wrapper'><div className='loader'/></div>}
            {!loading &&
              <div>
                {isEmpty(user.children) &&
                  <div className='padding-vertical-1 padding-horizontal-2'>
                    <div className='alert-callout margin-vertical-2'>
                      <div className='padding-2'>
                        <img
                          alt='Alert'
                          className='alert-img'
                          src={ICONS.ALERT}
                        />
                        <h1>Add a Child</h1>
                        <p>
                          You have not yet added a child to your profile. Please proceed to the Profile page and add
                          a child in order to view and participate in studies.
                        </p>
                      </div>
                      <div
                        className='alert-callout-bottom padding-horizontal-2 padding-vertical-1'
                        onClick={() => navigate(ROUTES.PROFILE, {replace: true})}
                      >
                        <p className='sub-title-1'>Update Profile</p>
                        <img
                          alt='pointing_arrow'
                          src={ICONS.POINTING_ARROW}
                        />
                      </div>
                    </div>
                  </div>
                }
                {!isEmpty(user.children) && user.children.map(child =>
                  <div
                    className='child-study-dropdown'
                    key={child.id}
                  >
                    <details>
                      <summary
                        className='child-card-heading padding-vertical-1 padding-horizontal-2'
                        style={{
                          backgroundImage: `url(${ICONS.ARROW})`,
                          backgroundRepeat: 'no-repeat',
                          backgroundPosition: 'right 30px center'
                        }}
                      >
                        <Avatar imageName='deer'/>
                        <h2>{capitalize(child.firstName)}</h2>
                        <p>| Age {getChildAge(child.birthDate)}</p>
                      </summary>
                      <div className='margin-vertical-1'>
                        <div className='padding-vertical-1 padding-horizontal-2'>
                          <p className='sub-title-1'>
                            Available Studies
                          </p>
                          {!isEmpty(child.eligibleStudies) && child.eligibleStudies.map(study =>
                            <div
                              className='study-callout margin-bottom-2'
                              key={study.id}
                            >
                              <div className='padding-2'>
                                <p className='overline'>Study Type</p>
                                <h1 className='padding-bottom-1'>{study.title}</h1>
                                {study.title === STUDIES_TITLE.TEMPLETON ? templetonDescription : sshrcDescription}
                              </div>
                              <div
                                className='study-callout-bottom padding-horizontal-2 padding-vertical-1'
                                onClick={() => onStudySelected(child, study)}
                              >
                                <p className='sub-title-1'>Go to Study</p>
                                <img
                                  alt='pointing_arrow'
                                  src={ICONS.POINTING_ARROW}
                                />
                              </div>
                            </div>)
                          }
                          {isEmpty(child.eligibleStudies) &&
                            !isEmpty(child.enrollments) && child.enrollments.map(enrollment =>
                            <div
                              className='study-callout margin-bottom-2'
                              key={enrollment.study.id}
                            >
                              <div className='padding-2'>
                                <p className='overline'>Study Type</p>
                                <h1 className='padding-bottom-1'>{enrollment.study.title}</h1>
                                {enrollment.study.title === STUDIES_TITLE.TEMPLETON
                                  ? templetonDescription : sshrcDescription}
                              </div>
                              <div
                                className='study-callout-bottom padding-horizontal-2 padding-vertical-1'
                                onClick={() => onStudySelected(child, enrollment.study)}
                              >
                                <p className='sub-title-1'>Go to Study</p>
                                <img
                                  alt='pointing_arrow'
                                  src={ICONS.POINTING_ARROW}
                                />
                              </div>
                            </div>)
                          }
                          {isEmpty(child.eligibleStudies) && isEmpty(child.enrollments) &&
                            <div
                              className='study-callout margin-bottom-2'
                            >
                              <div className='padding-2'>
                                <p className='overline'>Study Type</p>
                                <h1>No Study Available</h1>
                                <p>
                                  The child does not currently qualify for an active study. We will notify you when
                                  a qualification exists in future.
                                </p>
                              </div>
                              <div
                                className='
                                study-callout-bottom graded-out-callout padding-horizontal-2 padding-vertical-1'
                              >
                                <p className='sub-title-1'>Go to Study</p>
                                <img
                                  alt='pointing_arrow'
                                  src={ICONS.POINTING_ARROW}
                                />
                              </div>
                            </div>
                          }
                        </div>
                        {(!isEmpty(child.enrollments) ||
                          !isEmpty(child.completedEnrollments) ||
                          !isEmpty(child.eligibleStudies)) &&
                        <p className='sub-title-1 padding-vertical-1 padding-horizontal-2'>
                          Completed Studies
                        </p>
                        }
                        <div className='completed-studies padding-horizontal-2'>
                          {!isEmpty(child.completedEnrollments) && child.completedEnrollments.map(ele =>
                            <div
                              className='study-card padding-horizontal-1'
                              key={ele.study.id}
                            >
                              <div className='content margin-vertical-2'>
                                <img
                                  alt='Trophy'
                                  src={STUDIES_IMAGES.TROPHY}
                                />
                                <p className='overline padding-top-1'>Study Type</p>
                                <h4>{ele.study.title}</h4>
                              </div>
                            </div>)
                          }
                          {!isEmpty(child.eligibleStudies) && child.eligibleStudies.map(ele =>
                            <div
                              className='study-card padding-horizontal-1'
                              key={ele.id}
                            >
                              <div className='content margin-vertical-2'>
                                <img
                                  alt='Trophy'
                                  src={STUDIES_IMAGES.TROPHY_DOTTED}
                                />
                                <p className='overline padding-top-1'>Study Type</p>
                                <h4>{ele.title}</h4>
                              </div>
                            </div>)
                          }
                          {isEmpty(child.completedEnrollments) && isEmpty(child.eligibleStudies) &&
                            !isEmpty(child.enrollments) && child.enrollments.map(ele =>
                            <div
                              className='study-card padding-horizontal-1'
                              key={ele.study.id}
                            >
                              <div className='content margin-vertical-2'>
                                <img
                                  alt='Trophy'
                                  src={STUDIES_IMAGES.TROPHY_DOTTED}
                                />
                                <p className='overline padding-top-1'>Study Type</p>
                                <h4>{ele.study.title}</h4>
                              </div>
                            </div>)
                          }
                        </div>
                      </div>
                    </details>
                  </div>)}
              </div>
            }
          </div>
        </div>
        <Modal
          onClose={() => setShowModal(false)}
          show={showModal}
        >
          <section>
            <div className='padding-2'>
              <h1>Opt-Out</h1>
              <p>Child withdrawn from this study.</p>
            </div>
            <div className='grid-x margin-bottom-1'>
              <button
                className='cell auto margin-horizontal-3 button primary'
                onClick={() => setShowModal(false)}
                type='button'
              >Close
              </button>
            </div>
          </section>
        </Modal>
      </div>
      <Footer/>
    </>
  );
};

import React from 'react';
import {config} from '../../config';

const EnvironmentRibbon = () =>
  <div className='environment-ribbon-wrapper'>
    <div className='environment-ribbon'>
      {config.app.environment}
    </div>
  </div>;

export default EnvironmentRibbon;

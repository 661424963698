import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router';
import {useForm} from 'react-hook-form';
import {toast} from 'react-toastify';
import {logout} from '../authentication/slice/authSlice';
import ROUTES from '../../../routes';
import {removeUserChild, updateUserChildren, updateUserProfile, userDetails} from './slice/profileSlice';
import {ICONS, PROFILE_IMAGES} from '../../../assets/images';
import Footer from '../../common/Footer';
import {Link} from 'react-router-dom';
import useErrorProvider from '../../common/useErrorProvider';

const userDetailsSelector = state => state.profile.details;

export const Profile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userData = useSelector(userDetailsSelector);
  const [user, updateUser] = useState(userData);
  const [loading, setLoading] = useState(true);
  const [updating, setUpdating] = useState(false);
  const {apiErrorMsg, clearApiError, handleApiError} = useErrorProvider();

  useEffect(() => {
    if (apiErrorMsg) {
      toast.error(apiErrorMsg);
      clearApiError();
    }
  }, [apiErrorMsg]);

  const getUserDefaultValues = () => {
    const values = {
      parentFirstName: user.firstName,
      parentLastName: user.lastName,
      email: user.email,
      phone: user.phone,
      notificationsEnabled: user.notificationsEnabled,
      postalCode: user.postalCode,
      termsAndConditions: user.termsOfUseAcceptedAt,
      privacyPolicy: user.privacyPolicyAcceptedAt
    };

    let userMappedChildren = [];

    if (user.children) {
      userMappedChildren = user.children.map((child, idx) => ({
        [`childId_${idx}`]: child.id,
        [`childParticipantId_${idx}`]: child.participantId,
        [`childFirstName_${idx}`]: child.firstName,
        [`childLastName_${idx}`]: child.lastName,
        [`childBirthDate_${idx}`]: child.birthDate
      }));
    }

    const newUserObj = Object.assign({}, ...userMappedChildren);
    return {...values, ...newUserObj};
  };

  const getStructuredData = data => {
    const children = [];

    for (let i = 0; i < user.children.length; i++) {
      const child = {
        id: data[`childId_${i}`],
        participantId: data[`childParticipantId_${i}`],
        firstName: data[`childFirstName_${i}`],
        lastName: data[`childLastName_${i}`],
        birthDate: data[`childBirthDate_${i}`]
      };
      children.push(child);
    }

    return children;
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: {errors}
  } = useForm({
    mode: 'onChange',
    defaultValues: getUserDefaultValues()
  });

  useEffect(() => {
    updateUser(userData);
    reset(getUserDefaultValues());
  }, [user.children]);

  const loadUserDetails = () => {
    setLoading(true);
    clearApiError();
    dispatch(userDetails()).then(action => {
      if (userDetails.fulfilled.match(action)) {
        updateUser(action.payload.details);
      }
      else {
        handleApiError({action});
      }
    }).catch(error => {
      handleApiError({error, message: 'Error when fetching user details on profile page.'});
    }).finally(() => {
      setLoading(false);
    });
  };

  useEffect(() => {
    loadUserDetails();
  }, []);

  const handleSignOut = () => {
    dispatch(logout()).then(action => {
      if (!logout.fulfilled.match(action)) {
        handleApiError({action, message: 'Something went wrong while logging you out.'});
      }
    }).catch(error => {
      handleApiError({error, message: 'Error when signing out.'});
    }).finally(() => {
      toast.success('Successfully logged out!');
      navigate(ROUTES.ROOT, {replace: true});
    });
  };

  const onProfileSubmit = data => {
    setUpdating(true);
    setLoading(true);
    const children = getStructuredData(data);
    const parentData = {
      firstName: data.parentFirstName,
      lastName: data.parentLastName,
      postalCode: data.postalCode,
      notificationsEnabled: data.notificationsEnabled
    };

    children.forEach(child => {
      dispatch(updateUserChildren(child)).then(action => {
        if (!updateUserChildren.fulfilled.match(action)) {
          handleApiError({action, message: 'Could not update child.'});
        }
      }).catch(error => {
        handleApiError({error, message: 'Error when updating a child.'});
      });
    });

    dispatch(updateUserProfile(parentData)).then(action => {
      if (updateUserProfile.fulfilled.match(action)) {
        updateUser(action.payload.details);
        toast.success('Successfully updated!');
      }
      else {
        handleApiError({action, message: 'Could not update user profile.'});
      }
    }).catch(error => {
      handleApiError({error, message: 'Error when updating user profile.'});
    }).finally(() => {
      setUpdating(false);
      setLoading(false);
    });
  };

  const onRemoveChild = child => {
    setLoading(true);
    dispatch(removeUserChild(child)).then(action => {
      if (removeUserChild.fulfilled.match(action)) {
        toast.success('Child removed successfully!');
        loadUserDetails();
      }
      else {
        handleApiError({action, message: 'Could not remove a child.'});
      }
    }).catch(error => {
      handleApiError({error, message: 'Error when removing a child.'});
    }).finally(() => {
      setLoading(false);
    });
  };

  return (
    <div>
      <div className='grid-container padding-0'>
        <div className='grid-x align-center'>
          <div
            className='small-10 medium-8 large-6 cell padding-vertical-1'
            style={{
              backgroundImage: `url(${PROFILE_IMAGES.BEE})`,
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'right center'
            }}
          >
            <h1>Profile</h1>
            <p className='width-75'>
              Keeping your profile page up to date is important to ensure the Rosie app can make you aware of studies
              that you and your child have qualified for. In this section you can update your contact details and add
              children for study considerations.
            </p>
          </div>
        </div>
        {loading && <div className='loader-wrapper'><div className='loader'/></div>}
        {!loading &&
          <div className='profile-update-form'>
            <form onSubmit={handleSubmit(onProfileSubmit)}>
              <div className='grid-x align-center'>
                <div className='small-10 medium-8 large-6 cell padding-horizontal-2'>
                  <label>Parent First Name
                    <input
                      className='form-card'
                      type='text'
                      {...register('parentFirstName', {required: true})}
                    />
                    {errors.parentFirstName && <p className='error'>Parent First Name is required.</p>}
                  </label>
                  <label>Parent Last Name
                    <input
                      className='form-card'
                      type='text'
                      {...register('parentLastName', {required: true})}
                    />
                    {errors.parentLastName && <p className='error'>Parent Last Name is required.</p>}
                  </label>
                  <label>Email Address
                    <input
                      className='form-card'
                      disabled
                      type='email'
                      {...register('email', {required: true})}
                    />
                    {errors.email && <p className='error'>Email is required.</p>}
                  </label>
                  <label>
                    Postal Code (optional)
                    <input
                      className='form-card'
                      type='text'
                      {...register('postalCode')}
                    />
                  </label>
                  <label>
                    Terms of use accepted at
                    <input
                      className='form-card'
                      disabled
                      type='text'
                      {...register('termsAndConditions')}
                    />
                  </label>
                  <label>
                    Privacy Policy accepted at
                    <input
                      className='form-card'
                      disabled
                      type='text'
                      {...register('privacyPolicy')}
                    />
                  </label>
                  <>
                    <input
                      className='middle'
                      id='notifications_enabled'
                      type='checkbox'
                      {...register('notificationsEnabled')}
                    />
                    <label
                      className='margin-right-0'
                      htmlFor='notifications_enabled'>
                      Notifications Enabled
                    </label>
                  </>
                </div>
              </div>
              <div className='grid-x align-center bg-mid-beige padding-top-3 margin-top-1'>
                <div className='small-10 medium-8 large-6 cell padding-horizontal-2'>
                  <label>
                    Phone Number
                    <input
                      className='form-card'
                      disabled
                      type='number'
                      {...register('phone')}
                    />
                  </label>
                  <button
                    className='button primary hollow expanded'
                    onClick={() => navigate(ROUTES.PHONE_VERIFICATION)}
                    type='button'
                  >Update Phone</button>
                </div>
              </div>
              {user.children.length === 0 &&
                <div className='grid-x align-center bg-mid-beige padding-bottom-3'>
                  <div className='small-10 medium-8 large-6 cell padding-1'>
                    <div
                      className='margin-vertical-2'
                    >
                      <p className='sub-title-1'>
                        Add a child?
                      </p>
                      <div className='padding-1 child-form-card'>
                        <Link
                          className='button primary hollow expanded margin-vertical-1 img-content-center-aligned'
                          to={ROUTES.CHILDREN}
                        >
                          <img
                            alt='Check'
                            src={ICONS.ADDITION}/>
                          <p>Add child</p>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              }
              {user.children.length > 0 &&
                <div className='grid-x align-center bg-mid-beige padding-bottom-3'>
                  <div className='small-10 medium-8 large-6 cell padding-1'>
                    {user.children.map((child, idx) =>
                      <div
                        className='margin-vertical-2'
                        key={child.id}
                      >
                        {child.active &&
                          <>
                            <p className='sub-title-1'>
                              Child {idx + 1}
                            </p>
                            <div className='padding-1 child-form-card'>
                              <input
                                type='hidden'
                                {...register(`childId_${idx}`, {required: true})}
                              />
                              <input
                                type='hidden'
                                {...register(`childParticipantId_${idx}`, {required: true})}
                              />
                              <label>Child&apos;s First Name
                                <input
                                  className='form-card'
                                  type='text'
                                  {...register(`childFirstName_${idx}`, {required: true})}
                                />
                                {errors[`childFirstName_${idx}`] &&
                                  <p className='error'>Child&apos;s First Name is required.</p>}
                              </label>
                              <label>Child&apos;s Last Name
                                <input
                                  className='form-card'
                                  type='text'
                                  {...register(`childLastName_${idx}`, {required: true})}
                                />
                                {errors[`childLastName_${idx}`] &&
                                  <p className='error'>Child&apos;s Last Name is required.</p>}
                              </label>
                              <label>Child&apos;s Birthday
                                <input
                                  className='form-card'
                                  type='date'
                                  {...register(`childBirthDate_${idx}`, {required: true,
                                    validate: value => new Date() - new Date(value) > 0})}
                                />
                                {errors[`childBirthDate_${idx}`] &&
                                  <p className='error'>Child&apos;s Birthday is required.
                                  Please choose a date in the past.</p>}
                              </label>
                              <button
                                className='button primary hollow expanded margin-top-2 img-content-center-aligned'
                                onClick={() => {
                                  // eslint-disable-next-line no-alert
                                  if (window.confirm('Are you sure you wish to remove this Child?')) {
                                    onRemoveChild(child);
                                  }
                                }}
                                type='button'
                              >
                                <img
                                  alt='Check'
                                  src={ICONS.SUBTRACTION}/>
                                <p>Remove child</p>
                              </button>
                            </div>
                          </>
                        }
                      </div>)}
                    <div
                      className='margin-vertical-2'
                    >
                      <p className='sub-title-1'>
                        Add another child?
                      </p>
                      <div className='padding-1 child-form-card'>
                        <Link
                          className='button primary hollow expanded margin-vertical-1 img-content-center-aligned'
                          to={ROUTES.CHILDREN}
                        >
                          <img
                            alt='Check'
                            src={ICONS.ADDITION}/>
                          <p>Add child</p>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              }
              <div className='bottom-aligned bg-mid-beige'>
                <div className='width-100 padding-horizontal-2 bottom-buttons'>
                  {updating && <div className='loader-wrapper'><div className='loader'/></div>}
                  {!updating &&
                    <div className='grid-x align-center'>
                      <div className='small-10 medium-8 large-6 cell'>
                        <button
                          className='button primary expanded'
                          type='submit'>Save changes
                        </button>
                        <button
                          className='button primary hollow expanded'
                          onClick={() => handleSignOut()}
                          type='button'
                        >Sign Out</button>
                      </div>
                    </div>
                  }
                </div>
              </div>
            </form>
          </div>
        }
      </div>
      <Footer/>
    </div>
  );
};

/* eslint-disable max-len */
import React, {useEffect} from 'react';
import ROUTES from '../../../../routes';
import {useNavigate} from 'react-router';
import {useDispatch, useSelector} from 'react-redux';
import {completeChildEnrollment} from '../slice/templetonSlice';
import {toast} from 'react-toastify';
import {QUALTRICS_LINKS} from '../../../constants';
import useErrorProvider from '../../../common/useErrorProvider';

const enrollmentSelector = state => state.templetonStudy.enrollment;
const currentChildSelector = state => state.study.currentChild;
const currentStudySelector = state => state.study.currentStudy;
const currentActivitySelector = state => state.templetonStudy.currentActivity;

export const Survey = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const child = useSelector(currentChildSelector);
  const study = useSelector(currentStudySelector);
  const enrollment = useSelector(enrollmentSelector);
  const currentActivity = useSelector(currentActivitySelector);
  const {apiErrorMsg, clearApiError, handleApiError} = useErrorProvider();

  useEffect(() => {
    if (apiErrorMsg) {
      toast.error(apiErrorMsg);
      clearApiError();
    }
  }, [apiErrorMsg]);

  useEffect(() => {
    window.open(`${QUALTRICS_LINKS.TEMPLETON}?study_id=${study.id}&activity_id=${currentActivity.id}&participant_id=${child.id}`, '_blank');
  }, []);

  const onSurveyPressed = () => {
    if (currentActivity.slug === 'story_book_2') {
      dispatch(completeChildEnrollment({
        childId: child.id,
        enrollmentId: enrollment.id,
        studyCompletedAt: Date()
      })).then(action => {
        if (completeChildEnrollment.fulfilled.match(action)) {
          toast.success('Study successfully completed!');
        }
        else {
          handleApiError({action, message: 'Unable to complete the study.'});
        }
      }).catch(error => {
        handleApiError({error, message: 'Error when completing the Templeton study.'});
      });
    }

    navigate(ROUTES.STUDIES);
  };

  return (
    <div className='grid-x align-center'>
      <div className='small-12 medium-10 large-6 align-center'>
        <div className='padding-vertical-1 padding-horizontal-2 text-center'>
          <h1 className='padding-2'>Thanks!</h1>
          <button
            className='button primary large margin-vertical-2'
            onClick={() => onSurveyPressed()}
            type='button'
          >
            {currentActivity.slug === 'story_book_2'
              ? 'Complete Study'
              : 'Complete Activity'
            }
          </button>
        </div>
      </div>
    </div>
  );
};

export const config = {
  api: {
    baseUrl: process.env.REACT_APP_API_BASE_URL || 'http://localhost:3000/api'
  },
  app: {
    environment: process.env.REACT_APP_ENVIRONMENT || 'development',
    logger: {
      level: process.env.REACT_APP_LOGGER_LEVEL || 'debug'
    },
    version: {
      date: process.env.REACT_APP_VERSION_DATE || new Date().toISOString()
    }
  },
  google: {
    analytics: {
      measurementID: process.env.REACT_APP_GA_MEASUREMENT_ID
    },
    recaptcha: {
      siteKey: process.env.REACT_APP_RECAPTCHA_SITEKEY
    }
  },
  rollbar: {
    accessToken: process.env.REACT_APP_ROLLBAR_ACCESS_TOKEN
  }
};

import React from 'react';
import {useLocation} from 'react-router-dom';
import ROUTES from '../../../../routes';
import {useNavigate} from 'react-router';

export const ActivityResult = () => {
  const {state} = useLocation();
  const navigate = useNavigate();

  const onBack = () => {
    navigate(ROUTES.TEMPLETON_STUDY_ACTIVITY_INFO);
  };

  return (
    <div className='grid-x align-center'>
      <div className='small-12 medium-10 large-6'>
        <div className='padding-vertical-1 padding-horizontal-2'>
          <button
            className='button primary small hollow'
            onClick={() => onBack()}
            type='button'
          >
            Back
          </button>
          <h1>Activity Results</h1>
          <p>Recorded Video</p>
          <video
            autoPlay={false}
            className='bordered'
            controls
            src={state.url}
            style={{width: '100%', height: 'auto'}}
          />
        </div>
        <div className='grid-x align-center'>
          <button
            className='button primary'
            onClick={() => navigate(ROUTES.TEMPLETON_STUDY_ACTIVITY_CONGRATULATIONS)}
            type='button'
          >
            Continue
          </button>
        </div>
      </div>
    </div>
  );
};

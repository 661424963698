import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {axiosCall, responseFormat} from '../../../../config/axios';
import * as ENDPOINTS from '../../../../config/endpoints';
import {isEmpty} from 'lodash';

const initialState = {
  activities: {},
  childId: '',
  enrolledToStudyAt: null,
  currentActivity: {
    id: '',
    sequence: null,
    title: '',
    slug: ''
  },
  currentPageId: null,
  currentActivityProgress: {},
  enrollment: {}
};

export const getChildSelectedActivityStatus =
  createAsyncThunk('child/activity_status', (details, {rejectWithValue}) => {
    try {
      return axiosCall('get', `${ENDPOINTS.ACTIVITY_PROGRESS.GET}?status=1`, {
        child_id: details.childId,
        activity_id: details.activityId
      })
        .then(response => responseFormat(response, {details: response.data}))
        .catch(error => rejectWithValue({error}));
    }
    catch (error) {
      return rejectWithValue({error});
    }
  });

export const getChildSshrcEnrollment =
  createAsyncThunk('child/sshrc_enrollment', (details, {rejectWithValue}) => {
    try {
      return axiosCall('get', `${ENDPOINTS.ENROLLMENT.GET}?templeton=0`, {
        child_id: details.childId
      })
        .then(response => responseFormat(response, {details: response.data}))
        .catch(error => rejectWithValue({error}));
    }
    catch (error) {
      return rejectWithValue({error});
    }
  });

export const createChildEnrollment = createAsyncThunk('child/enrollments', (details, {rejectWithValue}) => {
  try {
    return axiosCall('post', ENDPOINTS.ENROLLMENT.CREATE, {
      child_id: details.childId,
      study_id: details.studyId,
      enrolled_to_study_at: details.enrolledToStudyAt
    })
      .then(response => responseFormat(response, {details: response.data}))
      .catch(error => rejectWithValue({error}));
  }
  catch (error) {
    return rejectWithValue({error});
  }
});

export const withdrawChildEnrollment = createAsyncThunk('child/withdraw_enrollment', (details, {rejectWithValue}) => {
  try {
    return axiosCall('patch',
      `${ENDPOINTS.ENROLLMENT.UPDATE}/${details.enrollmentId}/withdraw_from_study`,
      {
        child_id: details.childId,
        withdraw_from_study_at: details.withdrawFromStudyAt
      })
      .then(response => responseFormat(response, {details: response.data}))
      .catch(error => rejectWithValue({error}));
  }
  catch (error) {
    return rejectWithValue({error});
  }
});

export const completeChildEnrollment = createAsyncThunk('child/complete_enrollment', (details, {rejectWithValue}) => {
  try {
    return axiosCall('patch',
      `${ENDPOINTS.ENROLLMENT.UPDATE}/${details.enrollmentId}/complete_study`,
      {
        child_id: details.childId,
        study_completed_at: details.studyCompletedAt
      })
      .then(response => responseFormat(response, {details: response.data}))
      .catch(error => rejectWithValue({error}));
  }
  catch (error) {
    return rejectWithValue({error});
  }
});

export const getChildActivities =
  createAsyncThunk('child/activities', (details, {rejectWithValue}) => {
    try {
      return axiosCall('get', `${ENDPOINTS.ACTIVITY.GET}?templeton=0`, {
        child_id: details.childId
      })
        .then(response => responseFormat(response, {details: response.data}))
        .catch(error => rejectWithValue({error}));
    }
    catch (error) {
      return rejectWithValue({error});
    }
  });

export const createActivityProgress =
  createAsyncThunk('child/create_activity_progress', (details, {rejectWithValue}) => {
    try {
      return axiosCall('post', ENDPOINTS.ACTIVITY_PROGRESS.CREATE, {
        child_id: details.childId,
        activity_id: details.activityId,
        started_at: details.startedAt,
        progress: []
      })
        .then(response => responseFormat(response, {details: response.data}))
        .catch(error => rejectWithValue({error}));
    }
    catch (error) {
      return rejectWithValue({error});
    }
  });

export const updateChildSelectedActivityStatus =
  createAsyncThunk('child/update_activity_status', (details, {rejectWithValue}) => {
    try {
      return axiosCall('patch', `${ENDPOINTS.ACTIVITY_PROGRESS.UPDATE}/${details.activityProgressId}`, {
        child_id: details.childId,
        progress: details.progress
      })
        .then(response => responseFormat(response, {details: response.data}))
        .catch(error => rejectWithValue({error}));
    }
    catch (error) {
      return rejectWithValue({error});
    }
  });

export const completeChildSelectedActivity =
  createAsyncThunk('child/complete_activity', (details, {rejectWithValue}) => {
    try {
      return axiosCall('patch',
        `${ENDPOINTS.ACTIVITY_PROGRESS.UPDATE}/${details.activityProgressId}/complete_activity`,
        {
          child_id: details.childId,
          completed_at: details.completedAt
        })
        .then(response => responseFormat(response, {details: response.data}))
        .catch(error => rejectWithValue({error}));
    }
    catch (error) {
      return rejectWithValue({error});
    }
  });

export const sshrcSlice = createSlice({
  name: 'sshrcStudy',
  initialState,
  reducers: {
    setCurrentActivity: (state, payload) => {
      state.currentActivity = {
        id: payload.payload.id,
        sequence: payload.payload.sequence,
        title: payload.payload.title,
        slug: payload.payload.slug
      };
      state.currentPageId = null;
    },
    saveCurrPageID: (state, payload) => {
      state.currentPageId = payload.payload;
    }
  },
  extraReducers: builder => {
    builder.addCase(completeChildSelectedActivity.fulfilled, (state, action) => {
      state.currentActivityProgress = action.payload.details;
    });
    builder.addCase(updateChildSelectedActivityStatus.pending, (state, action) => {
      state.currentActivityProgress.progress = action.meta.arg.progress;
    });
    builder.addCase(updateChildSelectedActivityStatus.fulfilled, (state, action) => {
      state.currentActivityProgress = action.payload.details;
    });
    builder.addCase(getChildSelectedActivityStatus.fulfilled, (state, action) => {
      state.currentActivityProgress = action.payload.details[0];
    });
    builder.addCase(createActivityProgress.fulfilled, (state, action) => {
      state.currentActivityProgress = action.payload.details;
    });
    builder.addCase(createChildEnrollment.fulfilled, (state, action) => {
      state.enrollment = action.payload.details;
    });
    builder.addCase(completeChildEnrollment.fulfilled, (state, action) => {
      state.enrollment = action.payload.details;
    });
    builder.addCase(getChildSshrcEnrollment.fulfilled, (state, action) => {
      if (isEmpty(action.payload.details)) {
        state.enrolledToStudyAt = null;
        state.enrollment = {};
      }
      else {
        state.enrolledToStudyAt = action.payload.details[0].enrolledToStudyAt;
        state.enrollment = action.payload.details[0];
      }
    });
  }
});

export const {setCurrentActivity, saveCurrPageID} = sshrcSlice.actions;

export default sshrcSlice.reducer;

import React, {useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {useDispatch} from 'react-redux';
import {Link, useSearchParams} from 'react-router-dom';
import {resetPassword, setResetPasswordHeaders} from './slice/authSlice';
import ROUTES from '../../../routes';
import {toast} from 'react-toastify';
import {useNavigate} from 'react-router';
import useErrorProvider from '../../common/useErrorProvider';

export const ResetPassword = () => {
  const {
    register,
    handleSubmit,
    getValues,
    reset,
    formState: {errors}
  } = useForm();
  const dispatch = useDispatch();
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const {apiErrorMsg, clearApiError, handleApiError} = useErrorProvider();

  useEffect(() => {
    dispatch(setResetPasswordHeaders({
      uid: params.get('uid'),
      client: params.get('client'),
      accessToken: params.get('access-token'),
      token: params.get('token'),
      expiry: params.get('expiry')
    }));
  }, []);

  const onSubmit = data => {
    setLoading(true);
    clearApiError();
    dispatch(resetPassword(data)).then(action => {
      if (resetPassword.fulfilled.match(action)) {
        toast.success('Password successfully updated. Please use your new credentials to sign in.');
        navigate(ROUTES.SIGN_IN, {replace: true});
        reset();
      }
      else {
        handleApiError({action});
      }
    }).catch(error => {
      handleApiError({error, message: 'Error when resetting password.'});
    }).finally(() => {
      setLoading(false);
    });
  };

  return (
    <div className='grid-x align-center'>
      <div className='small-10 medium-6 large-4 cell padding-bottom-2'>
        <section>
          <div className='padding-vertical-2'>
            <h1>Reset Password</h1>
            <p>Complete the below form to reset your password</p>
          </div>
          {loading && <div className='loader-wrapper'><div className='loader'/></div>}
          {!loading &&
            <div className='form-card-wrapper'>
              <form onSubmit={handleSubmit(onSubmit)}>
                {apiErrorMsg && <div className='alert callout'>{apiErrorMsg}</div>}
                <label>New Password
                  <input
                    className='form-card'
                    name='password'
                    type='password'
                    {...register('password', {required: true})}
                  />
                  {errors.password && <p className='error'>This field is required.</p>}
                </label>
                <label>Confirm New Password
                  <input
                    className='form-card'
                    name='confirm_password'
                    type='password'
                    {...register('confirm_password',
                      {required: true, validate: value => value === getValues().password})}
                  />
                  {errors.confirm_password &&
                <p className='error'>Confirm Password is required and should be same as password.</p>}
                </label>
                <button
                  className='button primary expanded'
                  type='submit'
                >Submit
                </button>
                <Link
                  className='button primary hollow expanded '
                  to={ROUTES.SIGN_IN}
                >Back</Link>
              </form>
            </div>
          }
        </section>
      </div>
    </div>
  );
};

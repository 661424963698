/* eslint camelcase: 0 */
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import * as ENDPOINTS from '../../../../config/endpoints';
import {axiosCall, responseFormat} from '../../../../config/axios';

export const initialState = {
  details: {}
};

export const userDetails = createAsyncThunk('user/details', (_, {rejectWithValue}) => {
  try {
    return axiosCall('get', ENDPOINTS.USER.DETAILS, {})
      .then(response => responseFormat(response, {details: response.data}))
      .catch(error => rejectWithValue({error}));
  }
  catch (error) {
    return rejectWithValue({error});
  }
});

export const addChildren = createAsyncThunk('user/addChildren', (children, {rejectWithValue}) => {
  try {
    return axiosCall('post', ENDPOINTS.USER.ADD_CHILD, {children})
      .then(response => responseFormat(response, {children: response.data.data}))
      .catch(error => rejectWithValue({error}));
  }
  catch (error) {
    return rejectWithValue({error});
  }
});

export const updateUserProfile = createAsyncThunk('user/update', (parent, {rejectWithValue}) => {
  try {
    return axiosCall('patch', ENDPOINTS.USER.UPDATE, {
      first_name: parent.firstName,
      last_name: parent.lastName,
      postal_code: parent.postalCode,
      notifications_enabled: parent.notificationsEnabled
    })
      .then(response => responseFormat(response, {details: response.data}))
      .catch(error => rejectWithValue({error}));
  }
  catch (error) {
    return rejectWithValue({error});
  }
});

export const updateUserChildren = createAsyncThunk('user/children/update', (child, {rejectWithValue}) => {
  try {
    return axiosCall('put', `${ENDPOINTS.USER.CHILDREN_UPDATE}/${child.id}`, {
      first_name: child.firstName, // eslint-disable-line camelcase
      last_name: child.lastName, // eslint-disable-line camelcase
      participant_id: child.participantId, // eslint-disable-line camelcase
      birth_date: child.birthDate // eslint-disable-line camelcase
    })
      .then(response => responseFormat(response, {details: response.data}))
      .catch(error => rejectWithValue({error}));
  }
  catch (error) {
    return rejectWithValue({error});
  }
});

export const removeUserChild = createAsyncThunk('user/children/remove', (child, {rejectWithValue}) => {
  try {
    return axiosCall('put', `${ENDPOINTS.USER.CHILDREN_UPDATE}/${child.id}`, {
      active: false
    })
      .then(response => responseFormat(response, {details: response.data}))
      .catch(error => rejectWithValue({error}));
  }
  catch (error) {
    return rejectWithValue({error});
  }
});

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(userDetails.fulfilled, (state, action) => {
      state.details = action.payload.details;
    });
    builder.addCase(updateUserProfile.fulfilled, (state, action) => {
      state.details = action.payload.details;
    });
  }
});

export default profileSlice.reducer;

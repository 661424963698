import home from './img_files/footer_icons/home.svg';
import homeActive from './img_files/footer_icons/home_active.svg';
import profile from './img_files/footer_icons/profile.svg';
import profileActive from './img_files/footer_icons/profile_active.svg';
import studies from './img_files/footer_icons/study.svg';
import studiesActive from './img_files/footer_icons/study_active.svg';
import resources from './img_files/footer_icons/resources.svg';
import resourcesActive from './img_files/footer_icons/resources_active.svg';
import squirrel from './img_files/home_page/squirrel.png';
import butterfly from './img_files/home_page/butterfly.png';
import greenCheck from './img_files/home_page/green_check.png';
import toadstools from './img_files/home_page/toadstools.png';
import bee from './img_files/profile/bee.png';
import addition from './img_files/icons/addition.png';
import subtraction from './img_files/icons/subtraction.png';
import arrow from './img_files/icons/arrow.png';
import alert from './img_files/icons/alert.png';
import pen from './img_files/icons/pen.svg';
import arrowCircle from './img_files/icons/arrow_circle.png';
import checkMark from './img_files/icons/checkmark.svg';
import growth from './img_files/studies/growth.png';
import trophy from './img_files/studies/trophy.png';
import rabbit from './img_files/studies/rabbit.png';
import trophyDotted from './img_files/studies/trophy_dotted.png';
import hedgehog from './img_files/studies/hedgehog.png';
import lock from './img_files/studies/lock.png';
import congratulationsTrophy from './img_files/studies/congratulations_trophy.png';
import siteLogo from './img_files/site_logo.svg';
import plant from './img_files/child/Plants.png';
import bird from './img_files/sign_in/Bird.png';
import birdTracks from './img_files/sign_in/Bird tracks.png';
import foxAndABin from './img_files/page_not_found/Illustration.png';
import leaves from './img_files/sshrc/Leaves.png';
import boat from './img_files/sshrc/dccsBoatButton.png';
import bunny from './img_files/sshrc/dccsBunnyButton.png';
import diverse_beliefs_garage from './img_files/sshrc/diverse_beliefs/garageButton.png';
import diverse_beliefs_tree from './img_files/sshrc/diverse_beliefs/treeButton.png';
import diverse_desires_apple from './img_files/sshrc/diverse_desires/appleButton.png';
import diverse_desires_cookie from './img_files/sshrc/diverse_desires/cookieButton.png';
import false_belief_box from './img_files/sshrc/false_belief/boxButton.png';
import false_belief_basket from './img_files/sshrc/false_belief/basketButton.png';
import false_belief_lady_bug from './img_files/sshrc/false_belief/ladyBugButton.png';
import hidden_emotions_happy_face from './img_files/sshrc/hidden_emotions/happyButton.png';
import hidden_emotions_okay_face from './img_files/sshrc/hidden_emotions/okayButton.png';
import hidden_emotions_sad_face from './img_files/sshrc/hidden_emotions/sadButton.png';
import activity_fox from './img_files/activity_images/fox.png';
import activity_hedgehog from './img_files/activity_images/hedgehog.png';
import activity_squirrel from './img_files/activity_images/squirrel.png';
import activity_treebird from './img_files/activity_images/treebird.png';
import activity_owls from './img_files/activity_images/owls.png';
import avatar from './img_files/avatars/Avatar - OrangeOwl.png';
import rosieAvatar from './img_files/avatars/Avatar.png';
import avatarOwl from './img_files/avatars/Avatar - owl.png';
import avatarBear from './img_files/avatars/Avatar - Bear.png';
import avatarBunny from './img_files/avatars/Avatar - Bunny.png';
import avatarCaterpillar from './img_files/avatars/Avatar - Caterpillar.png';
import avatarDeer from './img_files/avatars/Avatar - Deer.png';
import avatarFox from './img_files/avatars/Avatar - Fox.png';
import avatarEyesClosedOwl from './img_files/avatars/Avatar - EyesClosedOwl.png';

export const SITE_LOGO = siteLogo;

export const AVATARS = {
  ORANGE_OWL: avatar,
  FOX: avatarFox,
  DEER: avatarDeer,
  CATERPILLAR: avatarCaterpillar,
  CLOSED_EYES_OWL: avatarEyesClosedOwl,
  BUNNY: avatarBunny,
  BEAR: avatarBear,
  OWL: avatarOwl
};

export const FOOTER_ICONS = {
  HOME: home,
  HOME_ACTIVE: homeActive,
  PROFILE: profile,
  PROFILE_ACTIVE: profileActive,
  STUDIES: studies,
  STUDIES_ACTIVE: studiesActive,
  RESOURCES: resources,
  RESOURCES_ACTIVE: resourcesActive
};

export const HOME_IMAGES = {
  SQUIRREL: squirrel,
  BUTTERFLY: butterfly,
  GREEN_CHECK: greenCheck,
  TOADSTOOLS: toadstools
};

export const PROFILE_IMAGES = {
  BEE: bee
};

export const SIGN_IN = {
  BIRD: bird,
  BIRD_TRACKS: birdTracks
};

export const CHILD_IMAGES = {
  PLANT: plant,
  AVATAR: rosieAvatar
};

export const STUDIES_IMAGES = {
  GROWTH: growth,
  TROPHY: trophy,
  RABBIT: rabbit,
  TROPHY_DOTTED: trophyDotted,
  HEDGEHOG: hedgehog,
  LOCK: lock,
  TROPHY_2: congratulationsTrophy
};

export const ICONS = {
  ADDITION: addition,
  SUBTRACTION: subtraction,
  ARROW: arrow,
  POINTING_ARROW: arrowCircle,
  ALERT: alert,
  PEN: pen,
  CHECK_MARK: checkMark
};

export const PAGE_NOT_FOUND_IMAGES = {
  FOX_AND_A_BIN: foxAndABin
};

export const SSHRC_IMAGES = {
  LEAVES: leaves,
  BOAT: boat,
  BUNNY: bunny,
  TREE: diverse_beliefs_tree,
  GARAGE: diverse_beliefs_garage,
  APPLE: diverse_desires_apple,
  COOKIE: diverse_desires_cookie,
  BOX: false_belief_box,
  BASKET: false_belief_basket,
  LADY_BUG: false_belief_lady_bug,
  HAPPY_FACE: hidden_emotions_happy_face,
  OKAY_FACE: hidden_emotions_okay_face,
  SAD_FACE: hidden_emotions_sad_face
};

export const ACTIVITY_IMAGES = {
  OWLS: activity_owls,
  FOX: activity_fox,
  HEDGEHOG: activity_hedgehog,
  SQUIRREL: activity_squirrel,
  TREEBIRD: activity_treebird
};

import React from 'react';
import {createRoot} from 'react-dom/client';
import './assets/stylesheets/application.scss';
import App from './App';
import configureStore from './app/store';
import {Provider} from 'react-redux';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import ScrollTop from './app/common/ScrollTop';
import EnvironmentRibbon from './app/common/EnvironmentRibbon';
import {Provider as RollbarProvider, ErrorBoundary} from '@rollbar/react';
import {PersistGate} from 'redux-persist/integration/react';
import {HelmetProvider} from 'react-helmet-async';
import {BrowserRouter} from 'react-router-dom';
import {injectStore} from './config/axios';
import logger from 'loglevel';
import {config} from './config';

require('./version-checker');

const container = document.getElementById('root');
const root = createRoot(container);
logger.setDefaultLevel(config.app.logger.level);

const {store, persistor} = configureStore();

// Allows the axios interceptor to access the store
injectStore(store);

const rollbarConfig = {
  enabled: config.app.environment !== 'development',
  accessToken: config.rollbar.accessToken,
  captureUncaught: true,
  captureIp: false,
  captureUnhandledRejections: true,
  payload: {
    environment: config.app.environment,
    client: {
      javascript: {
        source_map_enabled: true,
        code_version: config.app.version.date,
        guess_uncaught_frames: true
      }
    }
  },
  scrubFields: ['uid', 'email', 'password', 'firstName', 'lastName', 'phone', 'tokens', 'birthDate']
};

root.render(<React.StrictMode>
  <RollbarProvider config={rollbarConfig}>
    <ErrorBoundary>
      <Provider store={store}>
        <PersistGate
          loading={null}
          persistor={persistor}
        >
          <BrowserRouter>
            <HelmetProvider>
              <ScrollTop/>
              <App/>
              {config.app.environment !== 'production' && <EnvironmentRibbon/>}
            </HelmetProvider>
          </BrowserRouter>
        </PersistGate>
      </Provider>
    </ErrorBoundary>
  </RollbarProvider>
</React.StrictMode>);

/*
 * If you want your app to work offline and load faster, you can change
 * unregister() to register() below. Note this comes with some pitfalls.
 * Learn more about service workers: https://cra.link/PWA
 */
if (config.app.environment === 'development') {
  serviceWorkerRegistration.unregister();
}
else {
  serviceWorkerRegistration.register();
}

/*
 * If you want to start measuring performance in your app, pass a function
 * to log results (for example: reportWebVitals(console.log))
 * or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
 */
reportWebVitals();

import React, {useState} from 'react';
import {useForm} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';
import {toast} from 'react-toastify';
import {useNavigate} from 'react-router';
import {isEmpty} from 'lodash';
import {checkVerificationToken, getVerificationToken, updateUserPhoneNumber} from './slice/phoneVerificationSlice';
import ROUTES from '../../../routes';
import useErrorProvider from '../../common/useErrorProvider';

const userSelector = state => state.auth.user;
const profileSelector = state => state.profile.details;

export const PhoneVerification = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector(userSelector);
  const profile = useSelector(profileSelector);
  const {
    register,
    handleSubmit,
    formState: {errors}
  } = useForm({defaultValues: isEmpty(profile) ? {phone: user.phone} : {phone: profile.phone}});
  const [loading, setLoading] = useState(false);
  const [numberSaved, setNumberSaved] = useState(false);
  const [numberVerified, setNumberVerified] = useState(false);
  const {apiErrorMsg, clearApiError, handleApiError} = useErrorProvider();

  const getToken = () => {
    setLoading(true);
    clearApiError();
    dispatch(getVerificationToken())
      .then(action => {
        if (getVerificationToken.fulfilled.match(action)) {
          toast.success('Verification code sent to your phone number.');
          setNumberSaved(true);
        }
        else {
          handleApiError({action});
        }
      }).catch(error => {
        handleApiError({error, message: 'Error when fetching phone verification token.'});
      }).finally(() => {
        setLoading(false);
      });
  };

  const onPhoneNumberSubmit = data => {
    setLoading(true);
    clearApiError();
    dispatch(updateUserPhoneNumber(data))
      .then(action => {
        if (updateUserPhoneNumber.fulfilled.match(action)) {
          getToken();
        }
        else {
          handleApiError({action});
        }
      }).catch(error => {
        setLoading(false);
        handleApiError({error, message: 'Error when saving user phone number.'});
      });
  };

  const onVerificationTokenSubmit = data => {
    setLoading(true);
    clearApiError();
    dispatch(checkVerificationToken(data))
      .then(action => {
        if (checkVerificationToken.fulfilled.match(action)) {
          toast.success('Phone number successfully verified');
          setNumberVerified(true);
          navigate(ROUTES.ROOT);
        }
        else {
          handleApiError({action});
        }
      }).catch(error => {
        handleApiError({error, message: 'Error when verifying phone token.'});
      }).finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className='grid-x align-center'>
      <div className='small-10 medium-6 large-4 cell padding-bottom-2'>
        <section>
          <div className='padding-vertical-2'>
            <h1>Please Verify your phone number</h1>
            <p>Rosie requires your phone number for SMS notifications. Please provide your number below.</p>
          </div>
          {loading && <div className='loader-wrapper'><div className='loader'/></div>}
          {!loading &&
            <>
              {!numberSaved && !numberVerified &&
                <div className='form-card-wrapper'>
                  <form onSubmit={handleSubmit(onPhoneNumberSubmit)}>
                    {apiErrorMsg && <div className='alert callout'>{apiErrorMsg}</div>}
                    <label>Phone number
                      <input
                        className='form-card'
                        type='text'
                        {...register('phone', {
                          required: true,
                          pattern: {value: /^(0|[1-9]\d*)(\.\d+)?$/},
                          minLength: 10,
                          maxLength: 10
                        })}
                      />
                      {errors.phone && errors.phone.type === 'required' &&
                        <p className='error'>Phone number is required and only excepts numbers.</p>}
                      {errors.phone && errors.phone.type === 'pattern' &&
                        <p className='error'>Phone can only include numbers.</p>}
                      {errors.phone && (errors.phone.type === 'maxLength' || errors.phone.type === 'minLength') &&
                        <p className='error'>Phone number needs to be 10 digits long.</p>}
                    </label>
                    <button
                      className='button primary expanded'
                      type='submit'
                    >Submit
                    </button>
                    {!isEmpty(profile) && profile.phoneVerified &&
                      <button
                        className='button primary hollow expanded'
                        onClick={() => navigate(ROUTES.PROFILE)}
                        type='button'
                      >Cancel
                      </button>
                    }
                  </form>
                </div>
              }
              {numberSaved && !numberVerified &&
                <div className='form-card-wrapper'>
                  <form onSubmit={handleSubmit(onVerificationTokenSubmit)}>
                    {apiErrorMsg && <div className='alert callout'>{apiErrorMsg}</div>}
                    <label>Please enter 6 digit code
                      <input
                        className='form-card'
                        type='text'
                        {...register('verification_code', {
                          required: true,
                          pattern: {value: /^(0|[0-9]\d*)(\.\d+)?$/},
                          minLength: 6,
                          maxLength: 6
                        })}
                      />
                      {errors.verification_code && errors.verification_code.type === 'required' &&
                        <p className='error'>Verification code is required and only excepts numbers.</p>}
                      {errors.verification_code && errors.verification_code.type === 'pattern' &&
                        <p className='error'>Verification code can only include numbers.</p>}
                      {errors.verification_code &&
                        (errors.verification_code.type === 'maxLength' ||
                          errors.verification_code.type === 'minLength') &&
                          <p className='error'>Verification code needs to be 6 digit long.</p>}
                    </label>
                    <button
                      className='button primary expanded'
                      type='submit'
                    >Verify Code
                    </button>
                    <button
                      className='button primary hollow expanded'
                      onClick={() => getToken()}
                      type='button'
                    >Resend Code
                    </button>
                    <button
                      className='button primary hollow expanded'
                      onClick={() => {
                        setNumberSaved(false);
                        clearApiError();
                      }}
                      type='button'
                    >Edit Phone Number
                    </button>
                  </form>
                </div>
              }
            </>
          }
        </section>
      </div>
    </div>
  );
};

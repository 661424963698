import React, {useState} from 'react';
import {CHILD_IMAGES, ICONS} from '../../../assets/images';
import {addChildren} from './slice/profileSlice';
import {useDispatch, useSelector} from 'react-redux';
import {useForm} from 'react-hook-form';
import {Link} from 'react-router-dom';
import ROUTES from '../../../routes';
import {v4 as uuidv4} from 'uuid';
import {toast} from 'react-toastify';
import {useNavigate} from 'react-router';
import useErrorProvider from '../../common/useErrorProvider';
const userDetailsSelector = state => state.profile.details;

const AddChildren = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [idx, setIdx] = useState([0]);
  const {
    register,
    handleSubmit,
    formState: {errors}
  } = useForm();
  const userData = useSelector(userDetailsSelector);
  const {apiErrorMsg, clearApiError, handleApiError} = useErrorProvider();

  const getChildStructuredData = data => {
    const avatarList = ['ORANGE_OWL', 'FOX', 'DEER', 'CLOSED_EYES_OWL', 'CATERPILLAR', 'BUNNY', 'BEAR', 'OWL'];
    let childNumber = userData.children.length;

    const childList = [];

    for (const i of idx) {
      const child = {
        // avatar: avatarList[childNumber],
        participant_id: uuidv4(),
        active: true,
        first_name: data[`childFirstName_${i}`],
        last_name: data[`childLastName_${i}`],
        birth_date: data[`childBirthDate_${i}`],
        study_code: data[`studyCode_${i}`],
        eligible_for_templeton: data[`templeton_ques_1_${i}`] === 'Yes' && data[`templeton_ques_2_${i}`] === 'Yes',
        eligible_for_sshrc: data[`sshrc_ques_1_${i}`] === 'Yes' && data[`sshrc_ques_2_${i}`] === 'Yes'
      };

      childList.push(child);

      if (childNumber === avatarList.length - 1) {
        childNumber = 0;
      }
      else {
        childNumber++;
      }
    }

    return childList;
  };

  const onRemoveChild = id => {
    setIdx(idx.filter(i => i !== id));
  };

  const handleAddForm = () => {
    setIdx([...idx, idx[idx.length - 1] + 1]);
  };

  const onSubmit = data => {
    const children = getChildStructuredData(data);

    if (children.length > 0) {
      clearApiError();
      dispatch(addChildren(children)).then(action => {
        if (addChildren.fulfilled.match(action)) {
          toast.success(`${children.length === 1 ? 'Child' : 'Children'} added successfully!`);
          navigate(ROUTES.PROFILE);
        }
        else {
          handleApiError({action});
        }
      }).catch(error => {
        handleApiError({error, message: 'Error when adding children.'});
      });
    }
    else {
      navigate(ROUTES.PROFILE);
    }
  };

  return (
    <div>
      <div className='grid-container padding-0'>
        <div className='grid-x align-center'>
          <div
            className='small-10 medium-8 large-6 cell padding-vertical-1'
            /*
             * style={{
             *   backgroundImage: `url(${CHILD_IMAGES.PLANT})`,
             *   backgroundRepeat: 'no-repeat',
             *   backgroundPosition: 'right top'
             * }}
             */
          >
            <h1>Add Child</h1>
          </div>
        </div>

        <div className='profile-update-form'>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className='grid-x align-center'>
              <div className='small-10 medium-8 large-6 cell padding-1'>
                {apiErrorMsg && <div className='alert callout'>{apiErrorMsg}</div>}
                <div>
                  {idx && idx.map(i =>

                    <div
                      className='margin-vertical-2'
                      key={i}
                    >
                      <p className='sub-title-1'>
                        New Child Participant
                      </p>
                      <div className={`padding-2 child-form-card ${i === 0 ? 'position-relative' : ''}`}>
                        {i === 0 && <div
                          // className='position-absolute'
                          style={{
                            zIndex: -1,
                            position: 'absolute',
                            top: '-150px',
                            right: '0px'}}>
                          <img
                            alt='Plants'
                            src={CHILD_IMAGES.PLANT}
                          />
                        </div>}
                        <label>Child&apos;s First Name
                          <input
                            className='form-card'
                            type='text'
                            {...register(`childFirstName_${i}`, {required: true})}
                          />
                          {errors[`childFirstName_${i}`] &&
                              <p className='error'>Child&apos;s First Name is required.</p>}
                        </label>
                        <label>Child&apos;s Last Name
                          <input
                            className='form-card'
                            type='text'
                            {...register(`childLastName_${i}`, {required: true})}
                          />
                          {errors[`childLastName_${i}`] &&
                          <p className='error'>Child&apos;s Last Name is required.</p>}
                        </label>
                        <label>Child&apos;s Birthday
                          <input
                            className='form-card'
                            type='date'
                            {...register(`childBirthDate_${i}`,
                              {required: true, validate: value => new Date() - new Date(value) > 0})}
                          />
                          {errors[`childBirthDate_${i}`] &&
                          <p className='error'>Child&apos;s Birthday is required. Please choose a date in the past.</p>}
                        </label>
                        <label>
                          Study Code
                          <input
                            className='form-card'
                            type='text'
                            {...register(`studyCode_${i}`)}
                          />
                        </label>
                        <label>
                          Is your child under the age of 7?
                          <div>
                            <input
                              className='form-card'
                              id={`yes_templeton_ques_1_${i}`}
                              name={`templeton_ques_1_${i}`}
                              type='radio'
                              value='Yes'
                              {...register(`templeton_ques_1_${i}`,
                                {required: true})}
                            />
                            <label
                              className='padding-bottom-1'
                              htmlFor={`yes_templeton_ques_1_${i}`}>
                              Yes
                            </label>
                            <input
                              className='form-card'
                              id={`no_templeton_ques_1_${i}`}
                              name={`templeton_ques_1_${i}`}
                              type='radio'
                              value='No'
                              {...register(`templeton_ques_1_${i}`,
                                {required: true})}
                            />
                            <label
                              className='padding-bottom-1'
                              htmlFor={`no_templeton_ques_1_${i}`}>
                              No
                            </label>
                          </div>
                          {errors[`templeton_ques_1_${i}`] &&
                            <p className='error'>This field is required.</p>}
                        </label>
                        <label>
                          Have you visited the lab?
                          <div>
                            <input
                              className='form-card'
                              id={`yes_templeton_ques_2_${i}`}
                              name={`templeton_ques_2_${i}`}
                              type='radio'
                              value='Yes'
                              {...register(`templeton_ques_2_${i}`,
                                {required: true})}
                            />
                            <label
                              className='padding-bottom-1'
                              htmlFor={`yes_templeton_ques_2_${i}`}>
                              Yes
                            </label>
                            <input
                              className='form-card'
                              id={`no_templeton_ques_2_${i}`}
                              name={`templeton_ques_2_${i}`}
                              type='radio'
                              value='No'
                              {...register(`templeton_ques_2_${i}`,
                                {required: true})}
                            />
                            <label
                              className='padding-bottom-1'
                              htmlFor={`no_templeton_ques_2_${i}`}>
                              No
                            </label>
                          </div>
                          {errors[`templeton_ques_2_${i}`] &&
                            <p className='error'>This field is required.</p>}
                        </label>

                        <label>
                          Does your child speak and understand English?
                          <div>
                            <input
                              className='form-card'
                              id={`yes_sshrc_ques_1_${i}`}
                              name={`sshrc_ques_1_${i}`}
                              type='radio'
                              value='Yes'
                              {...register(`sshrc_ques_1_${i}`,
                                {required: true})}
                            />
                            <label
                              className='padding-bottom-1'
                              htmlFor={`yes_sshrc_ques_1_${i}`}>
                              Yes
                            </label>

                            <input
                              className='form-card'
                              id={`no_sshrc_ques_1_${i}`}
                              name={`sshrc_ques_1_${i}`}
                              type='radio'
                              value='No'
                              {...register(`sshrc_ques_1_${i}`,
                                {required: true})}
                            />
                            <label
                              className='padding-bottom-1'
                              htmlFor={`no_sshrc_ques_1_${i}`}>
                              No
                            </label>
                          </div>
                          {errors[`sshrc_ques_1_${i}`] &&
                            <p className='error'>This field is required.</p>}
                        </label>

                        <label>
                          Is your child typically developing?
                          <div>
                            <input
                              className='form-card'
                              id={`yes_sshrc_ques_2_${i}`}
                              name={`sshrc_ques_2_${i}`}
                              type='radio'
                              value='Yes'
                              {...register(`sshrc_ques_2_${i}`,
                                {required: true})}
                            />
                            <label
                              className='padding-bottom-1'
                              htmlFor={`yes_sshrc_ques_2_${i}`}>
                              Yes
                            </label>
                            <input
                              className='form-card'
                              id={`no_sshrc_ques_2_${i}`}
                              name={`sshrc_ques_2_${i}`}
                              type='radio'
                              value='No'
                              {...register(`sshrc_ques_2_${i}`,
                                {required: true})}
                            />
                            <label
                              className='padding-bottom-1'
                              htmlFor={`no_sshrc_ques_2_${i}`}>
                              No
                            </label>
                          </div>
                          {errors[`sshrc_ques_2_${i}`] &&
                            <p className='error'>This field is required.</p>}
                        </label>

                        <button
                          className='button primary hollow expanded margin-top-2 img-content-center-aligned'
                          onClick={() => {
                            // eslint-disable-next-line no-alert
                            if (window.confirm('Are you sure you wish to remove this Child?')) {
                              onRemoveChild(i);
                            }
                          }}
                          type='button'
                        >
                          <img
                            alt='Check'
                            src={ICONS.SUBTRACTION}/>
                          <p>Remove child</p>
                        </button>
                      </div>
                    </div>)}
                </div>
              </div>
            </div>
            <div className='grid-x align-center bg-mid-beige'>
              <div className='small-10 medium-8 large-6 cell padding-1'>
                <div
                  className='margin-vertical-2'
                >
                  <p className='sub-title-1'>
                    Add another child?
                  </p>
                  <div className='padding-1 child-form-card'>
                    <Link
                      className='button primary hollow expanded margin-vertical-1 img-content-center-aligned'
                      onClick={handleAddForm}
                      to={ROUTES.CHILDREN}
                    >
                      <img
                        alt='Check'
                        src={ICONS.ADDITION}/>
                      <p>Add child</p>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className='add-child-callout bg-mid-beige'>
              <div className='width-100 padding-horizontal-2 bottom-buttons'>
                <div className='grid-x align-center'>
                  <div className='small-10 medium-8 large-6 cell'>
                    <button
                      className='button primary expanded'
                      type='submit'>Save changes
                    </button>
                    <button
                      className='button primary hollow expanded'
                      onClick={() => navigate(ROUTES.PROFILE)}
                      type='button'>Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddChildren;

import React, {useEffect, useState} from 'react';
import ROUTES from '../../../routes';
import {useNavigate} from 'react-router';
import {useDispatch, useSelector} from 'react-redux';
import {ICONS} from '../../../assets/images';
import {toast} from 'react-toastify';
import {getChildTempletonEnrollment} from './slice/templetonSlice';
import {STUDIES_TITLE} from '../../constants';
import useErrorProvider from '../../common/useErrorProvider';

const templetonSelector = state => state.templetonStudy;
const currentChildSelector = state => state.study.currentChild;

export const Info = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const templetonStudy = useSelector(templetonSelector);
  const currentChild = useSelector(currentChildSelector);
  const [loading, setLoading] = useState(false);
  const {apiErrorMsg, clearApiError, handleApiError} = useErrorProvider();

  useEffect(() => {
    if (apiErrorMsg) {
      toast.error(apiErrorMsg);
      clearApiError();
    }
  }, [apiErrorMsg]);

  useEffect(() => {
    setLoading(true);
    dispatch(getChildTempletonEnrollment({childId: currentChild.id}))
      .then(action => {
        if (!getChildTempletonEnrollment.fulfilled.match(action)) {
          handleApiError({action, message: 'Unable to get Templeton enrollment status.'});
        }
      }).catch(error => {
        handleApiError({error, message: 'Error when fetching Templeton enrollment status.'});
      }).finally(() => {
        setLoading(false);
      });
  }, []);

  const onBack = () => {
    navigate(ROUTES.STUDIES);
  };

  return (
    <div className='grid-x align-center'>
      <div className='small-12 medium-10 large-6'>
        <div className='padding-vertical-1 padding-horizontal-2'>
          <button
            className='button primary small hollow'
            onClick={() => onBack()}
            type='button'
          >
            Back
          </button>
          <h1>{STUDIES_TITLE.TEMPLETON}</h1>
          <p>
          In order to participate in this study a parental consent  is required. Please review the
          consent content by pressing the &quot;Consent Form&quot; button below and agree by checking the
          appropriate consent box and submitting.
          </p>
        </div>
        <div className='consent-callout-wrapper padding-2'>
          <p className='sub-title-1'>Sign the Study Consent Form</p>
          <div className='consent-callout padding-3'>
            <p className='overline'>The Helping Study</p>
            <h1>Parental Consent</h1>
            <p>Please read and sign to continue.</p>
            {loading && <div className='loader-wrapper'><div className='loader'/></div>}
            {!loading &&
              <>
                <button
                  className='button primary img-content-center-aligned'
                  disabled={templetonStudy.enrolledToStudyAt}
                  onClick={() => navigate(ROUTES.TEMPLETON_STUDY_CONSENT)}
                  type='button'
                >
                  <img
                    alt='Pen'
                    src={templetonStudy.enrolledToStudyAt ? ICONS.CHECK_MARK : ICONS.PEN}/>
                  Consent Form
                </button><br/>
                <button
                  className='primary button'
                  disabled={!templetonStudy.enrolledToStudyAt}
                  onClick={() => navigate(ROUTES.TEMPLETON_STUDY_ACTIVITIES)}
                  type='button'
                >Begin Study</button>
              </>
            }
          </div>
        </div>
      </div>
    </div>
  );
};

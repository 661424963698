import axios from 'axios';
import {config} from '../config';

let store;

// Allows the axios interceptor to access the store
export const injectStore = _store => {
  store = _store;
};

export const responseFormat = (response, payload) => ({
  status: response?.status,
  headers: response?.headers,
  ...payload
});

// Before each request, attach the auth headers from the store
axios.interceptors.request.use(axiosConfig => {
  const headers = store.getState().auth.headers;
  axiosConfig.headers['access-token'] = headers['access-token'];
  axiosConfig.headers.expiry = headers.expiry;
  axiosConfig.headers.client = headers.client;
  axiosConfig.headers.uid = headers.uid;
  return axiosConfig;
});

export const axiosCall = (method, url, data) => {
  let response = {};

  if (method === 'post') {
    response = axios.post(`${config.api.baseUrl}${url}`, data);
  }

  if (method === 'get') {
    response = axios.get(`${config.api.baseUrl}${url}`, {params: data});
  }

  if (method === 'put') {
    response = axios.put(`${config.api.baseUrl}${url}`, data);
  }

  if (method === 'delete') {
    response = axios.delete(`${config.api.baseUrl}${url}`, data);
  }

  if (method === 'patch') {
    response = axios.patch(`${config.api.baseUrl}${url}`, data);
  }

  return response;
};
